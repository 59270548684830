import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineClose} from 'react-icons/ai';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {BasicElement} from '../../../../helpers/BasicElement';
import Button from '../../../form/button/Button';
import Input from '../../../form/input/Input';
import {BlockAdditionalData} from '../../types/EditorBlock';

interface BlockMarginPopupProps extends BasicElement {
  blockAdditionalData: BlockAdditionalData;
  OnAdditionalBlockDataSet: (data: BlockAdditionalData) => void;
}

const BlockMarginPopup: React.FC<BlockMarginPopupProps> = ({
  blockAdditionalData,
  OnAdditionalBlockDataSet,
  children,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const onClosePopup = () => {
    setOpenPopup(false);
  };

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<BlockAdditionalData>({
    mode: 'onSubmit',
    defaultValues: {
      ...blockAdditionalData,
    },
  });

  const onSubmit = (data: BlockAdditionalData) => {
    OnAdditionalBlockDataSet(data);
    onClosePopup();
  };

  return (
    <Popup
      trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{padding: '15px', borderRadius: '0.375rem', width: '40%'}}
      modal
      nested
      onClose={() => onClosePopup()}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
      closeOnDocumentClick={false}
    >
      <div className="absolute right-1 top-1 cursor-pointer">
        <AiOutlineClose onClick={() => onClosePopup()} />
      </div>

      <div className="grid gap-3 mt-3">
        <span>Margin:</span>
        <div className="flex gap-2">
          <Input
            name="margin.top"
            label="Top"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="margin.right"
            label="Right"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="margin.bottom"
            label="Bottom"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="margin.left"
            label="Left"
            registration={{register, errors}}
            className="flex-1"
          />
        </div>
        <span>Padding:</span>
        <div className="flex gap-2">
          <Input
            name="padding.top"
            label="Top"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="padding.right"
            label="Right"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="padding.bottom"
            label="Bottom"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="padding.left"
            label="Left"
            registration={{register, errors}}
            className="flex-1"
          />
        </div>
        <span>Size:</span>
        <div className="flex gap-2">
          <Input
            name="size.width"
            label="Width"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="size.height"
            label="Height"
            registration={{register, errors}}
            className="flex-1"
          />
        </div>
        <div className="flex gap-2">
          <Input
            name="size.width"
            label="Max Width"
            registration={{register, errors}}
            className="flex-1"
          />
          <Input
            name="size.height"
            label="Max Height"
            registration={{register, errors}}
            className="flex-1"
          />
        </div>
        <span>Class:</span>
        <div className="flex gap-2">
          <Input
            name="customClassName"
            label="Class name"
            registration={{register, errors}}
            className="flex-1"
          />
        </div>
        <Button
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        >
          Ok
        </Button>
      </div>
    </Popup>
  );
};

export default BlockMarginPopup;
