/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactElement} from 'react';
import EditorHeaderBlock from './elements/header/HeaderViewer';
import HeaderEditor from './elements/header/HeaderEditor';
import GridViewer from './elements/grid/GridViewer';
import GridEditor from './elements/grid/GridEditor';
import {EditorHeaderData} from './elements/header/HeaderTypes';
import {EditorGridViewerData} from './elements/grid/GridType';
import {EditorBlock} from './types/EditorBlock';
import {EditorParagraphData} from './elements/paragraph/ParagraphType';
import EditorParagraphBlock from './elements/paragraph/ParagraphViewer';
import ParagrapEditor from './elements/paragraph/ParagraphEditor';
import ImageEditorBlock from './elements/image/ImageEditor';
import {EditorImageData} from './elements/image/ImageTypes';
import ImageViewerBlock from './elements/image/ImageViewer';
import {EditorCarouselData} from './elements/carousel/CarouselTypes';
import CarouselViewerBlock from './elements/carousel/CarouselViewer';
import CarouselEditorBlock from './elements/carousel/CarouselEditor';
import {EditorVideoData} from './elements/video/VideoTypes';
import VideoViewerBlock from './elements/video/VideoViewer';
import VideoEditorBlock from './elements/video/VideoEditor';
import {EditorButtonData} from './elements/button/ButtonTypes';
import ButtonViewerBlock from './elements/button/ButtonViwer';
import ButtonEditorBlock from './elements/button/ButtonEditor';
import MultiAnswersEditorBlock from './elements/questions/multiAnswers/MultiAnswersEditor';
import MultiAnswerViewer from './elements/questions/multiAnswers/MultiAnswersViewer';
import {EditorMultiAnswersData} from './elements/questions/multiAnswers/MultiAnswersTypes';
import {EditorSketchfabData} from './elements/sketchfab/SketchfabType';
import SketchfabViewerEditor from './elements/sketchfab/SketchfabViewer';
import SketchfabEditor from './elements/sketchfab/SketchfabEditor';
import CorrectOrderAnswerViewer from './elements/questions/correctOrder/CorrectOrderAnswerViewer';
import CorrectOrderAnswerEditorBlock from './elements/questions/correctOrder/CorrectOrderAnswerEditor';
import {EditorCorrectOrderAnswerData} from './elements/questions/correctOrder/CorrectOrderAnswerTypes';
import MatchingAnswerEditorBlock from './elements/questions/matchingAnswer/MatchingAnswerEditor';
import {EditorMatchingAnswerData} from './elements/questions/matchingAnswer/MatchingAnswerTypes';
import MatchingAnswerViewer from './elements/questions/matchingAnswer/MatchingAnswerViewer';
import {EditorTableAnswersData} from './elements/questions/tableAnswer/TableAnswerTypes';
import TableAnswerViewer from './elements/questions/tableAnswer/TableAnswerViewer';
import TextAnswerEditorBlock from './elements/questions/textAnswer/TextAnswerEditor';
import {EditorTextAnswersData} from './elements/questions/textAnswer/TextAnswerTypes';
import TextAnswerViewer from './elements/questions/textAnswer/TextAnswerViewer';
import TableAnswerEditorBlock from './elements/questions/tableAnswer/TableAnswerEditor';
import {AbstractFieldAnswerData} from './elements/questions/abstractFieldAnswer/AbstractFieldAnswerTypes';
import AbstractFieldAnswerViewer from './elements/questions/abstractFieldAnswer/AbstractFieldAnswerViewer';
import AbstractFieldAnswerEditorBlock from './elements/questions/abstractFieldAnswer/AbstractFieldAnswerEditor';
import {EditorGameData} from './elements/game/GameTypes';
import GameViewerBlock from './elements/game/GameViewer';
import GameEditorBlock from './elements/game/GameEditor';
import {EditorSplitData} from './elements/hr/SplitTypes';
import SplitViewerBlock from './elements/hr/SplitViewer';
import SplitEditorBlock from './elements/hr/SplitEditor';

interface RendererType {
  [key: string]: {
    viewer: (
      props: EditorBlock<any>,
      isUseLocalVersion: boolean,
      isUseLiveVersion?: boolean,
    ) => ReactElement;
    editor: (props: EditorBlock<any>) => ReactElement;
  };
}

export const EditorBlocks: RendererType = {
  header: {
    viewer: (props: EditorBlock<EditorHeaderData>, isUseLocalVersion) => (
      <EditorHeaderBlock
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorHeaderData>) => (
      <HeaderEditor {...{...props, data: props.data}} />
    ),
  },
  paragraph: {
    viewer: (props: EditorBlock<EditorParagraphData>, isUseLocalVersion) => (
      <EditorParagraphBlock
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorParagraphData>) => (
      <ParagrapEditor {...{...props, data: props.data}} />
    ),
  },
  grid: {
    viewer: (
      props: EditorBlock<EditorGridViewerData>,
      isUseLocalVersion,
      isUseMutableAutoblock,
    ) => (
      <GridViewer
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
        isUseMutableAutoblock={isUseMutableAutoblock}
      />
    ),
    editor: (props: EditorBlock<EditorGridViewerData>) => (
      <GridEditor {...{...props, data: props.data}} />
    ),
  },
  image: {
    viewer: (props: EditorBlock<EditorImageData>, isUseLocalVersion) => (
      <ImageViewerBlock {...props} isUseSharedLiveVersion={isUseLocalVersion} />
    ),
    editor: (props: EditorBlock<EditorImageData>) => (
      <ImageEditorBlock {...{...props, data: props.data}} />
    ),
  },
  carousel: {
    viewer: (props: EditorBlock<EditorCarouselData>, isUseLocalVersion) => (
      <CarouselViewerBlock
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorCarouselData>) => (
      <CarouselEditorBlock {...{...props, data: props.data}} />
    ),
  },
  video: {
    viewer: (props: EditorBlock<EditorVideoData>, isUseLocalVersion) => (
      <VideoViewerBlock {...props} isUseSharedLiveVersion={isUseLocalVersion} />
    ),
    editor: (props: EditorBlock<EditorVideoData>) => (
      <VideoEditorBlock {...{...props, data: props.data}} />
    ),
  },
  button: {
    viewer: (props: EditorBlock<EditorButtonData>, isUseLocalVersion) => (
      <ButtonViewerBlock
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorButtonData>) => (
      <ButtonEditorBlock {...{...props, data: props.data}} />
    ),
  },
  multiAnswers: {
    viewer: (props: EditorBlock<EditorMultiAnswersData>, isUseLocalVersion) => (
      <MultiAnswerViewer
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorMultiAnswersData>) => (
      <MultiAnswersEditorBlock
        {...{...props, data: props.data as EditorMultiAnswersData}}
      />
    ),
  },
  textAnswerFieldAnswer: {
    viewer: (props: EditorBlock<EditorTextAnswersData>, isUseLocalVersion) => (
      <TextAnswerViewer {...props} isUseSharedLiveVersion={isUseLocalVersion} />
    ),
    editor: (props: EditorBlock<EditorTextAnswersData>) => (
      <TextAnswerEditorBlock
        {...{...props, data: props.data as EditorTextAnswersData}}
      />
    ),
  },
  tableAnswerFieldAnswer: {
    viewer: (props: EditorBlock<EditorTableAnswersData>, isUseLocalVersion) => (
      <TableAnswerViewer
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorTableAnswersData>) => (
      <TableAnswerEditorBlock
        {...{...props, data: props.data as EditorTableAnswersData}}
      />
    ),
  },
  matchingAnswer: {
    viewer: (
      props: EditorBlock<EditorMatchingAnswerData>,
      isUseLocalVersion,
    ) => (
      <MatchingAnswerViewer
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorMatchingAnswerData>) => (
      <MatchingAnswerEditorBlock
        {...{...props, data: props.data as EditorMatchingAnswerData}}
      />
    ),
  },
  sketchfab: {
    viewer: (props: EditorBlock<EditorSketchfabData>, isUseLocalVersion) => (
      <SketchfabViewerEditor
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorSketchfabData>) => (
      <SketchfabEditor
        {...{...props, data: props.data as EditorSketchfabData}}
      />
    ),
  },
  correctOrderAnswer: {
    viewer: (
      props: EditorBlock<EditorCorrectOrderAnswerData>,
      isUseLocalVersion,
    ) => (
      <CorrectOrderAnswerViewer
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<EditorCorrectOrderAnswerData>) => (
      <CorrectOrderAnswerEditorBlock
        {...{...props, data: props.data as EditorCorrectOrderAnswerData}}
      />
    ),
  },
  abstractFieldAnswer: {
    viewer: (
      props: EditorBlock<AbstractFieldAnswerData>,
      isUseLocalVersion,
    ) => (
      <AbstractFieldAnswerViewer
        {...props}
        isUseSharedLiveVersion={isUseLocalVersion}
      />
    ),
    editor: (props: EditorBlock<AbstractFieldAnswerData>) => (
      <AbstractFieldAnswerEditorBlock
        {...{...props, data: props.data as AbstractFieldAnswerData}}
      />
    ),
  },
  game: {
    viewer: (props: EditorBlock<EditorGameData>, isUseLocalVersion) => (
      <GameViewerBlock {...props} isUseSharedLiveVersion={isUseLocalVersion} />
    ),
    editor: (props: EditorBlock<EditorGameData>) => (
      <GameEditorBlock {...{...props, data: props.data as EditorGameData}} />
    ),
  },
  split: {
    viewer: (props: EditorBlock<EditorSplitData>, isUseLocalVersion) => (
      <SplitViewerBlock {...props} isUseSharedLiveVersion={isUseLocalVersion} />
    ),
    editor: (props: EditorBlock<EditorSplitData>) => (
      <SplitEditorBlock {...{...props, data: props.data as EditorSplitData}} />
    ),
  },
};
