import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {BasicElement} from '../../../helpers/BasicElement';
interface LocationChangeHandlerProps extends BasicElement {
  onPathChanged?: (path: string) => void;
}

const LocationChangeHandler: React.FC<LocationChangeHandlerProps> = ({
  onPathChanged,
  children,
}) => {
  const {pathname} = useLocation();
  useEffect(() => {
    if (onPathChanged) onPathChanged(pathname);
  }, [onPathChanged, pathname]);

  return <>{children}</>;
};

export default LocationChangeHandler;
