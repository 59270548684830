import {useTranslation} from 'react-i18next';
import {useGetDashboard} from '../../api/library/Library';
import LoadingEditorBlock from '../../components/editor/elements/common/LoadingBlock';

const Dashboard: React.FC = () => {
  //useGetDashboard
  const {i18n} = useTranslation();
  const dashboardData = useGetDashboard(i18n.language);

  if (!dashboardData.isSuccess) return <LoadingEditorBlock />;
  return (
    <div className="flex flex-col gap-10">
      {dashboardData.data.sections.map((element) => (
        <div>
          <p className="text-2xl">{element.title}</p>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 pt-2">
            {element.pages.map(({section, subsection, pageName, title}) => (
              <a
                href={`/p/${section && section + '/'}${
                  subsection && subsection + '/'
                }${pageName && pageName}?back`}
                className="py-2 bg-gray-100 px-3 hover:bg-gray-200 cursor-pointer"
              >
                {title}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
