import classNames from 'classnames';
import React from 'react';
import {API_ROOT} from '../../api/Api';
import {ImageQuality} from '../editor/elements/image/ImageTypes';

interface ServerImageProps extends React.HTMLAttributes<HTMLImageElement> {
  imageId: string;
  alt: string;
  quality?: ImageQuality;
  imageFit?: ImageFit;
}

export enum ImageFit {
  Contain = 'Contain',
  Cover = 'Cover',
  Fill = 'Fill',
  ScaleDown = 'Scale down',
  None = 'None',
}

const ServerImage: React.FC<ServerImageProps> = ({
  imageId,
  className,
  alt,
  quality = ImageQuality.MID,
  imageFit = ImageFit.None,
  ...rest
}) => (
  <img
    src={`${API_ROOT}/media/get?id=${imageId}&quality=${quality}`}
    key={`${API_ROOT}/media/get?id=${imageId}&quality=${quality}`}
    className={classNames(
      className,
      imageFit === ImageFit.Contain && 'object-contain',
      imageFit === ImageFit.Cover && 'object-cover',
      imageFit === ImageFit.Fill && 'object-fill',
      imageFit === ImageFit.ScaleDown && 'object-scale-down',
    )}
    alt={alt}
    {...rest}
  />
);

export default ServerImage;
