import {UseQueryResult, useQuery, useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {queryClient} from '../../app/App';
import {
  UseShortMutationOptions,
  UseShortQueryOptions,
} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {CreatedDTO} from '../basicTypes/Created';
import {AddImageDAO} from './dao/AddImageDAO';
import {DeleteImageDAO} from './dao/DeleteImageDAO';
import {PublicImageDTO} from './dto/PublicImageDTO';

const GetAllPublicImagesKey = 'publicImages/getall';
const UploadNewImage = 'publicImages/add';

export function useGetAllPublicImages(
  errorHandler?: (error: AxiosError<ApiError>) => void,
  options?: UseShortQueryOptions<PublicImageDTO[], AxiosError<ApiError>>,
): UseQueryResult<PublicImageDTO[], AxiosError<ApiError>> {
  return useQuery(
    [GetAllPublicImagesKey],
    async (): Promise<PublicImageDTO[]> => {
      const {data} = await APIRequest.get<PublicImageDTO[]>(
        `/Media/GetAllPublicImages`,
      );
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

// mutations

export const useAddNewImage = (
  errorHandler?: (error: AxiosError<ApiError>) => void,
  options?: UseShortMutationOptions<
    CreatedDTO<PublicImageDTO>,
    AxiosError<ApiError>,
    AddImageDAO
  >,
) => {
  return useMutation<
    CreatedDTO<PublicImageDTO>,
    AxiosError<ApiError>,
    AddImageDAO
  >(
    [UploadNewImage],
    async (newImage: AddImageDAO): Promise<CreatedDTO<PublicImageDTO>> => {
      const bodyFormData = new FormData();
      bodyFormData.append('file', newImage.file);
      bodyFormData.append('filename', newImage.filename);

      const {data} = await APIRequest.post<CreatedDTO<PublicImageDTO>>(
        `/Media/Create`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept:
              'application/json, application/xml, text/plain, text/html, *.*',
          },
          timeout: 10000,
        },
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GetAllPublicImagesKey]);
      },
      onError: errorHandler || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useDeleteImage = (
  errorHandler?: (error: AxiosError<ApiError>) => void,
  options?: UseShortMutationOptions<
    CreatedDTO,
    AxiosError<ApiError>,
    DeleteImageDAO
  >,
) => {
  return useMutation<CreatedDTO, AxiosError<ApiError>, DeleteImageDAO>(
    [UploadNewImage],
    async (deleteRequest: DeleteImageDAO): Promise<CreatedDTO> => {
      const {data} = await APIRequest.get(`/Media/DeletePublic`, {
        params: {
          id: deleteRequest.id,
        },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GetAllPublicImagesKey]);
      },
      onError: errorHandler || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
