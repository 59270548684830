import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetCourse} from '../../api/courses/Courses';
import CoursePageHeader from '../../components/coursePage/coursePageHeader/CoursePageHeader';
import ViewerAutoblock from '../../components/editor/service/autoBlocks/ViewerAutoblock';
import Button from '../../components/form/button/Button';
import {useLocalization} from '../../services/flagProvider/FlagProvider';

import './CoursePage.css';
import {useRef} from 'react';
import useScript from '../../hooks/useScript';

const CoursePage: React.FC = () => {
  const translation = useLocalization();
  const {courseId} = useParams<{courseId: string}>();

  const commonTranslation = useTranslation('common');
  const navigation = useNavigate();

  const simulation = useGetCourse(
    courseId || '0',
    translation.getCurrentLocale(),
    {
      onError: () => {
        navigation('/simulations');
      },
    },
  );

  const container = useRef<HTMLDivElement>(null);
  useScript(
    'https://cdn-ru.bitrix24.ru/b28330568/crm/form/loader_26.js' +
      '?' +
      ((Date.now() / 180000) | 0),
    container,
    {
      'data-b24-form': 'click/26/1djear',
      'data-skip-moving': 'true',
    },
  );

  return (
    <div className="mb-5">
      {!simulation.isSuccess ? (
        <CoursePageHeader
          subject="Loading"
          device=""
          duration={0}
          title="Loading"
          background=""
        />
      ) : (
        <>
          <CoursePageHeader
            subject={commonTranslation.t(
              `CourseSubject.${simulation.data.subject}`,
            )}
            device={commonTranslation.t(
              `CourseSection.${simulation.data.section}`,
            )}
            duration={10}
            title={simulation.data.translation.name}
            background={simulation.data.backgroundImageId}
          />
          <div className="max-w-screen-xl block px-8 xl:px-0 mx-auto mt-6">
            <ViewerAutoblock
              blocks={[JSON.parse(simulation.data.translation.body)]}
            />
          </div>

          <div className="my-12">
            {/* <Link to="/request-demo"> */}

            {/* </Link> */}
            <div ref={container}>
              <Button className="mx-auto block mt-8">
                {commonTranslation.t('start')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CoursePage;
