import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {SketchfabModelDTO} from './dto/SketchfabModelDTO';

const GetAllSketchfabModelKey = 'sketchfabModel/getall';
const GetSketchfabModelKey = 'sketchfabModel/get';

export function useGetAllSketchfabModels(
  languageCode: string,
  options?: UseShortQueryOptions<SketchfabModelDTO[], AxiosError<ApiError>>,
): UseQueryResult<SketchfabModelDTO[], AxiosError<ApiError>> {
  return useQuery(
    [GetAllSketchfabModelKey, {languageCode}],
    async (): Promise<SketchfabModelDTO[]> => {
      const {data} = await APIRequest.get<SketchfabModelDTO[]>(
        `/SketchfabModel/GetAll`,
        {
          params: {
            languageCode,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetSketchfabModel(
  id: string,
  languageCode: string,
  options?: UseShortQueryOptions<SketchfabModelDTO, AxiosError<ApiError>>,
): UseQueryResult<SketchfabModelDTO, AxiosError<ApiError>> {
  return useQuery(
    [GetSketchfabModelKey, {id, languageCode}],
    async (): Promise<SketchfabModelDTO> => {
      const {data} = await APIRequest.get<SketchfabModelDTO>(
        `/SketchfabModel/Get`,
        {
          params: {
            modelId: id,
            languageCode,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
