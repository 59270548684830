import {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAuthGetToken} from '../../api/authorization/Authentification';
import AuthorizationPopup from '../../components/authorizationPopup/AuthorizationPopup';
import Button from '../../components/form/button/Button';
import {useAuth} from '../../services/auth/AuthService';

const LoginPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const redirected = useState(searchParams.get('redirected'));

  const navigation = useNavigate();
  const authContext = useAuth();
  const getToken = useAuthGetToken({
    onSuccess: (data) => {
      authContext.login(data);

      if (redirected) navigation(-1);
      else navigation('/admin');
    },
  });

  return !authContext.isAuthenticated ? (
    <AuthorizationPopup onAuth={getToken.mutate} />
  ) : (
    <div className="grid h-full">
      <Button
        onClick={authContext.logout}
        className="w-64 mx-auto translate-y-1/2"
      >
        Logout
      </Button>
    </div>
  );
};

export default LoginPage;
