/* eslint-disable @typescript-eslint/no-explicit-any */
import {queryClient} from '../../../../app/App';
import {EditorBlocks} from '../../AllBlocks';
import {EditorGridViewerData, GridColumn} from '../../elements/grid/GridType';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';

export const SaveBlocks = (
  coreblockId: string,
): EditorBlock<any> | undefined => {
  const allBlocks = queryClient.getQueriesData<EditorBlock<any>>(EditorBlocks);
  const sourceBlock = allBlocks.find((x) => x[1].id === coreblockId);

  const findElement = (id: string): EditorBlock<any> => {
    const block = allBlocks.find((x) => x[1].id === id);
    if (!block)
      throw new Error(`Save error! Block with id '${id}' is not found!`);

    return block[1];
  };

  const getObject = (block: EditorBlock<any>): EditorBlock<any> => {
    const baseObject: EditorBlock<any> = block;
    if (block.type === 'grid') {
      const typedBase = baseObject as EditorBlock<EditorGridViewerData>;
      const updatedColumns: GridColumn[] = [];
      for (let i = 0; i < typedBase.data.columns.length; i += 1) {
        const columnObjects: EditorBlock<EditorBlockData>[] = [];
        for (let j = 0; j < typedBase.data.columns[i].inner.length; j += 1) {
          const cachedBlock = findElement(
            typedBase.data.columns[i].inner[j].id,
          );
          const columnElemnt = getObject(cachedBlock);
          columnObjects.push(columnElemnt);
        }
        updatedColumns.push({
          ...typedBase.data.columns[i],
          inner: columnObjects,
        });
      }

      return {
        ...typedBase,
        data: {
          ...typedBase.data,
          columns: updatedColumns,
        },
      };
    }

    return baseObject;
  };

  if (sourceBlock) {
    const t = getObject(sourceBlock[1]);
    return t;
  }

  return undefined;
};
