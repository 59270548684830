/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, {Fragment, useEffect, useState} from 'react';
import _uniqueId from 'lodash/uniqueId';
import {Listbox, Transition} from '@headlessui/react';
import {HiCheck, HiSelector} from 'react-icons/hi';
import classNames from 'classnames';
import './Listbox.scoped.css';
import {
  FieldValues,
  FieldPath,
  UseFormSetValue,
  FieldPathValue,
  UseFormGetValues,
} from 'react-hook-form';

export type ListBoxOption<T> = {
  label: string;
  value: T;
};

type ListBoxParameters<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  options: ListBoxOption<FieldPathValue<TFieldValues, TFieldName>>[];
  name: TFieldName;
  label?: string;
  isListUp?: boolean;
  setValue: UseFormSetValue<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  lateUpdate?: boolean; // Для компонент эдитора когда обновляется постфактум
  onChange?: (value: FieldPathValue<TFieldValues, TFieldName>) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const ListBox: <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  p: ListBoxParameters<T, TName>,
) => React.ReactElement<ListBoxParameters<T, TName>> = ({
  options,
  name,
  label,
  setValue,
  getValues,
  onChange,
  className,
  lateUpdate = false,
  isListUp = false,
  disabled = false,
  ...rest
}) => {
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    const value = getValues(name);
    const option =
      options.find((x) => JSON.stringify(x.value) === JSON.stringify(value)) ||
      options[0];

    setSelected(option);
  }, [getValues, name, options]);

  const [id] = useState(_uniqueId('select-'));

  return (
    <div
      className={classNames('relative input-component', className)}
      {...rest}
    >
      <Listbox
        value={selected}
        onChange={(selectedVal) => {
          if (!lateUpdate) setSelected(selectedVal);
          setValue(name, selectedVal.value);
          if (onChange) onChange(selectedVal.value);
        }}
        disabled={disabled}
      >
        {({open}) => (
          <div className="relative selectbutton">
            <Listbox.Button
              style={{height: '40px'}}
              className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-sm border border-gray-300 cursor-default focus:outline-none hover:bg-ffocusbackground text-sm"
            >
              <span className="block truncate">{selected.label}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <HiSelector
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className={classNames(
                  'absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-sm border border-gray-300 max-h-60 focus:outline-none sm:text-sm',
                  isListUp && 'bottom-10',
                )}
              >
                {options.map((option, optionIdx) => (
                  <Listbox.Option
                    key={optionIdx}
                    className={({active}) =>
                      classNames(
                        'hover:bg-ffocusbackground cursor-default select-none relative py-2 pl-10 pr-4',
                        active
                          ? 'text-amber-900 bg-amber-100'
                          : 'text-gray-900',
                      )
                    }
                    value={option}
                  >
                    {({selected: itemSelected, active}) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            itemSelected ? 'font-medium' : 'font-normal',
                          )}
                        >
                          {option.label}
                        </span>
                        {itemSelected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 left-0 flex items-center pl-3',
                              active ? 'text-amber-600' : 'text-amber-600',
                            )}
                          >
                            <HiCheck className="w-5 h-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {label && (
              <label
                htmlFor={id}
                className="absolute left-2 transition-all bg-white px-1"
              >
                {label}
              </label>
            )}
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default ListBox;
