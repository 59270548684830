/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import React from 'react';
import './Button.scoped.css';

type FormButtonProps = {
  icon?: React.ReactNode;
  disableBorders?: boolean;
  autoHeight?: boolean;
  highlight?: boolean;
  selected?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const FormButton: React.FC<FormButtonProps> = ({
  className,
  type = 'button',
  children,
  icon,
  disabled = false,
  disableBorders = false,
  autoHeight = false,
  highlight = false,
  selected = false,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classNames(
        'text-center no-underline rounded-sm px-4 ',
        autoHeight ? 'h-full' : 'py-2',
        highlight && 'bg-pink-600 hover:bg-pink-400 text-white',
        selected && 'bg-gray-300',
        !disableBorders && 'border border-gray-300',
        !disabled
          ? ' text-base hover:bg-gray-100'
          : 'bg-gray-300 text-gray-600',
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      <div className="flex">
        {children && <div className="flex-grow">{children}</div>}
        {icon && <div className="mx-auto icon-container">{icon}</div>}
      </div>
    </button>
  );
};
export default FormButton;
