import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useGetFullCustomPageById} from '../../../../api/customPage/CustomPage';
import {queryClient} from '../../../../app/App';
import CreateCustomPageEditor from '../../../../components/admin/adminCustomPage/adminCustomPageEditor/CreateCustomPageEditor';
import LoadingEditorBlock from '../../../../components/editor/elements/common/LoadingBlock';
import {editorBlockKey} from '../../../../components/editor/types/UseEditor';

const EditCustomPage: React.FC = () => {
  const {customPageId} = useParams<{customPageId: string}>();
  const fullBlock = useGetFullCustomPageById(customPageId || '');

  useEffect(() => {
    queryClient.removeQueries([editorBlockKey]);
  }, []);

  if (!fullBlock.isSuccess || !customPageId) return <LoadingEditorBlock />;
  return (
    <CreateCustomPageEditor
      customPage={fullBlock.data}
      customPageId={customPageId}
    />
  );
};

export default EditCustomPage;
