import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {CreateCustomPageDAO} from './dao/CustomPageDAO';
import {CustomPageDTO, CustomPageFullDTO} from './dto/CustomPageDTO';

export const GetAllCustomPagesKey = 'customPage/getAll';
export const GetCustomPageKey = 'customPage/get';
export const GetFullCustomPageKey = 'customPage/getFullById';

export function useGetAllCustomPages(
  options?: UseShortQueryOptions<CustomPageFullDTO[], AxiosError<ApiError>>,
): UseQueryResult<CustomPageFullDTO[], AxiosError<ApiError>> {
  return useQuery(
    [GetAllCustomPagesKey],
    async (): Promise<CustomPageFullDTO[]> => {
      const {data} = await APIRequest.get<CustomPageFullDTO[]>(
        `/CustomPage/GetAll`,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetCustomPage(
  page: string,
  languageCode: string,
  section?: string,
  subsection?: string,
  options?: UseShortQueryOptions<CustomPageDTO, AxiosError<ApiError>>,
): UseQueryResult<CustomPageDTO, AxiosError<ApiError>> {
  return useQuery(
    [GetCustomPageKey, {page, section, subsection}],
    async (): Promise<CustomPageDTO> => {
      const {data} = await APIRequest.get<CustomPageDTO>(`/CustomPage/Get`, {
        params: {
          page,
          section,
          subsection,
          languageCode,
        },
      });
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetFullCustomPageById(
  id: string,
  options?: UseShortQueryOptions<CreateCustomPageDAO, AxiosError<ApiError>>,
): UseQueryResult<CreateCustomPageDAO, AxiosError<ApiError>> {
  return useQuery(
    [GetFullCustomPageKey, {id}],
    async (): Promise<CreateCustomPageDAO> => {
      const {data} = await APIRequest.get<CreateCustomPageDAO>(
        `/CustomPage/GetFull`,
        {
          params: {
            id,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
