import React from 'react';
import {Link} from 'react-router-dom';
import Button from '../../components/form/button/Button';
import CoursesBlock from '../../components/schoolCourses/CoursesBlock';
import CoursesParagraph from '../policies/dolcevita/CoursesParagraph';

const SchoolCoursesPageRu: React.FC = () => {
  return (
    <section className="mb-5 mt-2 mx-auto p-3 max-w-screen-xl">
      <h2 className="text-center mx-auto mb-4 text-4xl font-medium">
        Создавай VR игры вместе с нами
      </h2>
      <div className="w-full mb-3 mx-auto">
        <img
          src="https://d33wubrfki0l68.cloudfront.net/0057d5d51a652c15f834882211a6494afad51236/0d109/courselogo.jpg"
          className="w-full"
          alt="VR games"
        />
        <small className="block">
          https://covers.alphacoders.com/index.php/cover/view/57663
        </small>
      </div>
      <CoursesParagraph>
        <b>Привет! </b> Наверняка, ты уже много раз слышал про виртуальную
        реальность, Half-Life Alyx, американские горки в VR, путешествия,
        концерты, а еще, смотрел фильм “Первому игроку приготовиться?”
        Задумывался ли ты когда-нибудь, что можешь сам научиться создавать свои
        миры, со своими законами и персонажами? Самые востребованные и редкие
        разработчики в данный момент работают в VR игровой индустрии. Разработка
        игр - это не только навыки программирования, но и огромные сценарные,
        художественные возможности.
        <br />
        <br />
        Приглашаем тебя на курс по созданию VR приложений под руководством
        профессиональных разработчиков и создателей игр.
      </CoursesParagraph>
      <CoursesParagraph>
        <b>
          Если вы хотите организовать VR разработку в своей школе, тут можно{' '}
          <Link
            to="/contacts"
            className="text-blue-500 hover:underline hover:text-blue-800"
          >
            связаться с командой
          </Link>{' '}
          и запустить курсы с нами!
        </b>
      </CoursesParagraph>
      <CoursesParagraph>
        <b>Длительность: 3 месяца, 3 академических часа в неделю</b>
        <br />
        <b>Возраст: для школьников с 6 класса</b>
        <br />
      </CoursesParagraph>
      <CoursesBlock
        header="Что вы узнаете?"
        list={[
          'Основы геймдизайна',
          'Как работать с движком по разработке игр Unity',
          'Методы проектирования игры и подбора игровых механик',
          'Как проектируются VR приложения с учетом физиологических особенностей человека',
          'Основы языка C# в контексте разработки под движок Unity',
          'Принципы работы игровой физики, настройки света и звука в играх',
        ]}
        image="https://d33wubrfki0l68.cloudfront.net/0b328ec156e02bd34bb122994489f75eda39de1a/15e19/course_1.jpg"
      />
      <CoursesBlock
        header="Чему научитесь?"
        list={[
          'Разрабатывать простые игры под шлемы виртуальной реальности',
          'Писать код на языке C# в среде Visual Studio',
          'Работать с редактором Unity',
          'Работать с основными VR SDK - Oculus SDK и OpenVR SDK',
          'Создавать игровые анимации и работать с физикой',
        ]}
        image="https://d33wubrfki0l68.cloudfront.net/bdf20658c2eec17846360b6bd8ea6e4dfe61c6a6/2849a/course_2.jpg"
      />
      <div className="flex justify-between my-16 flex-col md:flex-row gap-4">
        <a
          href="https://securepayments.sberbank.ru/shortlink/ZXu5511I"
          className="block w-full px-0 md:px-4"
          rel="noreferrer"
        >
          <Button className="w-full" isShadow>
            Оплатить 1 месяц
          </Button>
        </a>

        <a
          href="https://securepayments.sberbank.ru/shortlink/D7eYAGhr"
          className="block w-full px-0 md:px-4"
          rel="noreferrer"
        >
          <Button className="w-full" isShadow>
            Оплатить полный курс
          </Button>
        </a>
      </div>
      <p>
        <Link to="/policies/policy" target="_blank" className="underline">
          Политика обработки персональных данных
        </Link>
      </p>
      <p>
        <Link to="/policies/paymentinfo" target="_blank" className="underline">
          Описание процесса оплаты
        </Link>
      </p>
      <p>
        <a
          className="underline"
          href="https://storage.yandexcloud.net/xready-lab/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B0%20XReadyLab.docx"
        >
          Договор оферты
        </a>
      </p>
    </section>
  );
};

export default SchoolCoursesPageRu;
