import React from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineClose} from 'react-icons/ai';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Button from '../../../form/button/Button';
import Input from '../../../form/input/Input';

interface FormData {
  header: string;
  description: string;
}

interface AddSketchfabAnnotationPopupProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (header: string, description: string) => void;
  children?: React.ReactNode;
}

const AddSketchfabAnnotationPopup: React.FC<
  AddSketchfabAnnotationPopupProps
> = ({isOpen = false, setIsOpen, onSave, children}) => {
  const {
    register,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<FormData>();

  return (
    <Popup
      trigger={<div>{children}</div>}
      contentStyle={{
        padding: '15px',
        borderRadius: '0.375rem',
        overflowY: 'auto',
        maxHeight: '95%',
        width: 'auto',
        minWidth: '30%',
        maxWidth: '50%',
      }}
      modal
      nested
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <>
        <div className="absolute right-1 top-1 cursor-pointer">
          <AiOutlineClose onClick={() => setIsOpen(false)} />
        </div>
        <div className="flex justify-center mt-2 flex-col">
          <Input<FormData>
            name="header"
            label="Header"
            className="flex-grow"
            registration={{register, errors}}
          />
          <Input<FormData>
            name="description"
            label="Description"
            className="flex-grow mt-2"
            registration={{register, errors}}
          />
        </div>
        <div className="flex justify-center mt-2">
          <Button
            className="rounded-md"
            size="base"
            onClick={() => {
              const values = getValues();
              onSave(values.header, values.description);
              setValue('header', '');
              setValue('description', '');
            }}
            noRadius
          >
            Add new annotaion
          </Button>
        </div>
      </>
    </Popup>
  );
};

export default AddSketchfabAnnotationPopup;
