import React, {useState} from 'react';
import _uniqueId from 'lodash/uniqueId';
import './Input.scoped.css';
import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import classNames from 'classnames';

type InputParameters<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label?: string;
  rows?: number;
  name: TFieldName;
  autoComplete?: boolean;

  options?: RegisterOptions<TFieldValues, TFieldName>;
  registration: {
    register: UseFormRegister<TFieldValues>;
    errors: DeepMap<DeepPartial<FieldValues>, FieldError>;
  };
} & React.HTMLAttributes<HTMLDivElement>;

const RichTextInput: <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  p: InputParameters<T, TName>,
) => React.ReactElement<InputParameters<T, TName>> = ({
  label,
  registration,
  name,
  options,
  autoComplete = false,
  rows = 5,
  ...rest
}) => {
  const [id] = useState(_uniqueId('input-'));

  return (
    <div {...rest}>
      <div className="relative h-full">
        <textarea
          id={id}
          rows={rows}
          className={classNames(
            'h-full w-full px-2 rounded-sm text-sm border outline-none',
            registration.errors[name] ? 'border-error-300' : 'border-gray-300',
            'focus:border-ffocusoutline',
          )}
          {...registration.register(name, options)}
          autoComplete={autoComplete ? undefined : 'off'}
        />
        {label && (
          <label
            htmlFor={id}
            className="absolute left-2 transition-all bg-white px-1"
          >
            {label}
            {options?.required && <span className="pl-1 text-pink">*</span>}
          </label>
        )}
        {registration.errors[name] && (
          <span className="text-error-600 text-xs">
            {(registration.errors[name] as FieldError | undefined)?.message}
          </span>
        )}
      </div>
    </div>
  );
};

export default RichTextInput;
