import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  AiFillPlusCircle,
  AiOutlineLeft,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineRight,
} from 'react-icons/ai';
import {
  deleteElementByIndex,
  swapElementsByIndex,
} from '../../../../helpers/ArrayUtils';
import Input from '../../../form/input/Input';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import HiddenSettings from '../../common/HiddenSettings';
import NewBlockPopup from '../../NewBlockPopup';
import EditorAutoblock from '../../service/autoBlocks/EditorAutoblock';
import {
  EditorBlock,
  EditorBlockData,
  EditorElementProps,
} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import {
  BreakOnSize,
  EditorGridViewerData,
  GridColumn,
  GridTheme,
} from './GridType';

const colorOptions: ListBoxOption<GridTheme>[] = [
  {
    label: 'white',
    value: {
      background: '#ffffff',
      color: '#000000',
    },
  },
  {
    label: 'blue',
    value: {
      background: '#007aff',
      color: '#ffffff',
    },
  },
];

const breakOnSizeOptions: ListBoxOption<BreakOnSize>[] = [
  {
    label: 'Never',
    value: BreakOnSize.NEVER,
  },
  {
    label: 'Tablet',
    value: BreakOnSize.TABLET,
  },
  {
    label: 'Mobile',
    value: BreakOnSize.MOBILE,
  },
];

const GridEditor: React.FC<EditorElementProps<EditorGridViewerData>> = (
  originBlock,
) => {
  const {
    register,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<EditorGridViewerData>({
    mode: 'onSubmit',
  });

  const [getPositions, setPositions] = useState(
    // Array.from({length: originBlock.data.columns.length}, (_, i) => i),
    originBlock.data.columns.map((x) => x.order),
  );

  const createNewBlockMutation = useUpdateEditorBlock<EditorBlockData>();
  const setBlockMutation = useUpdateEditorBlock<EditorGridViewerData>();

  useEffect(() => {
    setValue(
      'theme',
      colorOptions.find(
        (x) =>
          x.value.background === originBlock.data.theme.background &&
          x.value.color === originBlock.data.theme.color,
      )?.value || colorOptions[0].value,
    );
    setValue('padding', originBlock.data.padding);
  }, [originBlock, setValue]);

  const addColumnTest = useCallback(() => {
    setPositions((old) => [...[...old, old.length]]);

    const emptyColumn: GridColumn = {
      order: originBlock.data.columns.length,
      inner: [],
    };

    const updData: EditorGridViewerData = {
      ...originBlock.data,
      columns: [...originBlock.data.columns, emptyColumn],
    };
    setBlockMutation.mutate({
      ...originBlock,
      data: updData,
    });
  }, [originBlock, setBlockMutation]);

  const deleteColumn = useCallback(
    (index: number) => {
      setPositions((old) => [...[...old, old.length]]);
      setPositions((old) => {
        const deletedOrderValue = old[index];
        return [
          ...deleteElementByIndex(
            old.map((x) => (x > deletedOrderValue ? x - 1 : x)),
            index,
          ),
        ];
      });

      const updData: EditorGridViewerData = {
        ...originBlock.data,
        columns: [...deleteElementByIndex(originBlock.data.columns, index)],
      };
      setBlockMutation.mutate({
        ...originBlock,
        data: updData,
      });
    },
    [originBlock, setBlockMutation],
  );

  const updateColumnOrder = useCallback(
    (index: number, order: number) => {
      const updData: EditorGridViewerData = originBlock.data;
      updData.columns[index].order = order;

      setBlockMutation.mutate({
        ...originBlock,
        data: updData,
      });
    },
    [originBlock, setBlockMutation],
  );

  const moveColumn = useCallback(
    (currentOrder: number, direction: number) => {
      if (currentOrder === 0 && direction === -1) return;
      if (currentOrder === getPositions.length - 1 && direction === 1) return;

      const currentElementIndex = getPositions.indexOf(currentOrder);
      const otherElementIndex = getPositions.indexOf(currentOrder + direction);

      updateColumnOrder(otherElementIndex, currentOrder);
      updateColumnOrder(currentElementIndex, currentOrder + direction);

      const updated = swapElementsByIndex(
        getPositions,
        currentElementIndex,
        otherElementIndex,
      );

      setPositions([...updated]);
    },
    [getPositions, updateColumnOrder],
  );

  const addBlock = useCallback(
    (columnIndex: number, newBlockTemplate: EditorBlock<EditorBlockData>) => {
      const updColumn = originBlock.data.columns;

      const newBlock = {
        ...newBlockTemplate,
        order: updColumn[columnIndex].inner.length,
      };

      createNewBlockMutation.mutate(newBlock);
      updColumn[columnIndex].inner.push(newBlock);

      const updData: EditorGridViewerData = {
        ...originBlock.data,
        columns: updColumn,
      };
      setBlockMutation.mutate({
        ...originBlock,
        data: updData,
      });
    },
    [originBlock, setBlockMutation, createNewBlockMutation],
  );

  const OnElementDeleted = useCallback(
    (delId: string) => {
      const columns: GridColumn[] = [];

      for (let i = 0; i < originBlock.data.columns.length; i += 1) {
        const colElement = originBlock.data.columns[i];
        if (colElement.inner.find((x) => x.id === delId)) {
          columns.push({
            ...colElement,
            inner: colElement.inner.filter((x) => x.id !== delId),
          });
        } else {
          columns.push(colElement);
        }
      }
      const updData: EditorGridViewerData = {
        ...originBlock.data,
        columns,
      };
      setBlockMutation.mutate({
        ...originBlock,
        data: updData,
      });
    },
    [originBlock, setBlockMutation],
  );

  const onChange = useCallback(() => {
    const updatedData = getValues();

    setBlockMutation.mutate({
      ...originBlock,
      data: {
        ...originBlock.data,
        ...updatedData,
      },
    });
  }, [getValues, originBlock, setBlockMutation]);

  return (
    <div className="p-1 sm:p-5">
      <HiddenSettings>
        <div className="flex gap-2">
          <ListBox
            name="theme"
            className="flex-1"
            options={colorOptions}
            label="Theme"
            setValue={setValue}
            getValues={getValues}
            onChange={() => onChange()}
          />
          <ListBox
            name="breakOnSize"
            className="flex-1"
            options={breakOnSizeOptions}
            label="Break on size"
            setValue={setValue}
            getValues={getValues}
            onChange={() => onChange()}
          />
          <Input
            name="gap"
            label="Horizontal Gap"
            registration={{register, errors}}
            className="flex-1"
            onChange={() => onChange()}
          />
          <Input
            name="verticalGap"
            label="Vertical Gap"
            registration={{register, errors}}
            className="flex-1"
            onChange={() => onChange()}
          />
          <Input
            name="padding"
            label="Padding"
            registration={{register, errors}}
            className="flex-1"
            onChange={() => onChange()}
          />
        </div>
      </HiddenSettings>
      <div className={classNames('grid grid-flow-col auto-cols-fr gap-2 m-2')}>
        {originBlock.data.columns.map((element, index) => (
          <div style={{order: getPositions[index]}} key={index}>
            <div className="flex">
              <AiOutlineLeft
                className="w-5 h-5 p-0.5 cursor-pointer hover:bg-gray-200"
                onClick={() => moveColumn(getPositions[index], -1)}
              />
              <AiOutlineRight
                className="w-5 h-5 p-0.5 cursor-pointer hover:bg-gray-200"
                onClick={() => moveColumn(getPositions[index], 1)}
              />
              <AiOutlinePlus
                className="w-5 h-5 p-0.5 cursor-pointer hover:bg-gray-200"
                onClick={addColumnTest}
              />
              <AiOutlineMinus
                className="w-5 h-5 p-0.5 cursor-pointer hover:bg-gray-200"
                onClick={() => deleteColumn(index)}
              />
            </div>
            <EditorAutoblock
              blocks={element.inner}
              onElementDeleted={OnElementDeleted}
            />
            <NewBlockPopup
              onSelected={(blckTemplate) => addBlock(index, blckTemplate)}
            >
              <div className="relative text-gray-400 hover:text-gray-700 cursor-pointer transition ease-in-out duration-200">
                <AiFillPlusCircle
                  className="bg-current rounded-full block mx-auto"
                  fill="white"
                />
                <div className="w-5/12 border-t border-current absolute inset-y-2/4 left-2" />
                <div className="w-5/12 border-t border-current absolute inset-y-2/4 right-2" />
              </div>
            </NewBlockPopup>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridEditor;
