import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineClose} from 'react-icons/ai';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Button from '../../../form/button/Button';
import Input from '../../../form/input/Input';

interface FormData {
  header: string;
  description: string;
}

interface EditSketchfabAnnotationPopupProps {
  header: string;
  description: string;
  setData: (header: string, description: string, index: number) => void;
  index: number;
  children?: React.ReactNode;
}

const EditSketchfabAnnotationPopup: React.FC<
  EditSketchfabAnnotationPopupProps
> = ({
  header = 'Header',
  description = 'Description',
  children,
  setData,
  index,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const {
    register,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<FormData>();

  useEffect(() => {
    setValue('header', header);
    setValue('description', description);
  }, [description, header, setValue]);

  const onClosePopup = useCallback(() => {
    setOpenPopup(false);
  }, []);

  const onSave = useCallback(() => {
    const values = getValues();
    setData(values.header, values.description, index);
    setOpenPopup(false);
  }, [getValues, index, setData]);

  return (
    <Popup
      trigger={<div>{children}</div>}
      contentStyle={{
        padding: '15px',
        borderRadius: '0.375rem',
        overflowY: 'auto',
        maxHeight: '95%',
        width: 'auto',
        minWidth: '30%',
        maxWidth: '50%',
      }}
      modal
      nested
      onClose={() => onClosePopup()}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
    >
      <>
        <div className="absolute right-1 top-1 cursor-pointer">
          <AiOutlineClose onClick={() => onClosePopup()} />
        </div>
        <div className="flex justify-center mt-2 flex-col">
          <Input<FormData>
            name="header"
            label="Header"
            className="flex-grow"
            registration={{register, errors}}
          />
          <Input<FormData>
            name="description"
            label="Description"
            className="flex-grow mt-2"
            registration={{register, errors}}
          />
        </div>
        <div className="flex justify-center mt-2">
          <Button className="rounded-md" size="base" onClick={onSave} noRadius>
            Save
          </Button>
        </div>
      </>
    </Popup>
  );
};

export default EditSketchfabAnnotationPopup;
