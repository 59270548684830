import classNames from 'classnames';
import {useState} from 'react';
import {DashboardSectionTranslationDTO} from '../../api/library/dto/DashboardDTO';
import Button from '../../components/form/button/Button';

const SampleDashboard: React.FC = () => {
  //useGetDashboard
  // const dashboardData = useGetDashboard(i18n.language);
  const [element] = useState<DashboardSectionTranslationDTO>({
    sectionId: 'B1317FDE-AFDB-4C78-A372-7E27AAFFFACA',
    title: '3D tests',
    pages: [
      {
        id: '0337f5d4-b99a-43cc-b488-fb542e6f74aa',
        title: 'Skeleton',
        pageName: 'skeleton',
        section: 'biology',
        subsection: 'test',
      },
      {
        id: '09704835-70ea-499f-b26e-bec44937550a',
        title: 'Brain',
        pageName: 'brain',
        section: 'biology',
        subsection: 'test',
      },
      {
        id: '6dc7051c-6f63-4e25-bc05-e23a9a7d30a4',
        title: 'Respiratory system',
        pageName: 'respiratory-system',
        section: 'biology',
        subsection: 'test',
      },
      {
        id: '729d333d-154f-4f82-bc28-2acf21d0ca16',
        title: 'Eye',
        pageName: 'eye-test',
        section: 'biology',
        subsection: 'test',
        isLocked: true,
      },
      {
        id: '9a291d73-b896-4ef8-9870-d838dc06a723',
        title: 'Digestive system',
        pageName: 'digestive-system',
        section: 'biology',
        subsection: 'test',
        isLocked: true,
      },
      {
        id: 'a607130c-735c-4d73-8c18-21eee084ffec',
        title: 'Teeth',
        pageName: 'teeth',
        section: 'biology',
        subsection: 'test',
        isLocked: true,
      },
      {
        id: 'c9be7fa1-b524-4fcb-a375-58003631a5da',
        title: 'Mitochondrion',
        pageName: 'mitochondrion',
        section: 'biology',
        subsection: 'test',
        isLocked: true,
      },
      {
        id: 'e22f960a-b956-4d9f-88b9-1bce5a0d543a',
        title: 'Chloroplast',
        pageName: 'chloroplast',
        section: 'biology',
        subsection: 'test',
        isLocked: true,
      },
      {
        id: 'fe1534f7-16c6-43cc-b653-143e649c8c78',
        title: 'Heart',
        pageName: 'heart',
        section: 'biology',
        subsection: 'test',
        isLocked: true,
      },
    ],
  });

  return (
    <div className="flex flex-col gap-10">
      <div>
        <p className="text-2xl">{element.title}</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 pt-2">
          {element.pages.map(
            ({section, subsection, pageName, title, isLocked}) => (
              <a
                href={
                  isLocked
                    ? ''
                    : `/p/${section && section + '/'}${
                        subsection && subsection + '/'
                      }${pageName && pageName}`
                }
                className={classNames(
                  'py-2 bg-gray-100 px-3  cursor-pointer',
                  !isLocked && 'hover:bg-gray-200',
                  isLocked && 'text-gray-300',
                )}
              >
                {title}
              </a>
            ),
          )}
        </div>
        <a href="https://calendly.com/drstemmy/30min?month=2023-02">
          <Button className="mt-3 w-full text-md" size="lg">
            Book a call with team
          </Button>
        </a>
      </div>
    </div>
  );
};

export default SampleDashboard;
