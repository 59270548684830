import classNames from 'classnames';
import React from 'react';
import {BiTime} from 'react-icons/bi';

interface ReviewSimulationProps extends React.HTMLAttributes<HTMLDivElement> {
  video?: string;
  header: string;
  subheader: React.ReactNode;
  text?: string;
  headerClass?: string;
  subject: string;
  minutes: string;
}

const ReviewSimulation: React.FC<ReviewSimulationProps> = ({
  video,
  header,
  subheader,
  text,
  className,
  children,
  subject,
  minutes,
  headerClass = 'uppercase text-indigo-900',
  ...others
}) => {
  return (
    <div
      className={classNames(className, 'grid md:grid-cols-2 gap-2')}
      {...others}
    >
      <div className="">
        <iframe
          src={video}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className="aspect-video w-full rounded-lg"
        />
      </div>

      <div
        className={classNames('pl-0 lg:pl-4 flex flex-col', !video && 'w-full')}
      >
        <div
          className={classNames(
            'mt-3 font-bold text-lg lg:text-3xl ',
            headerClass,
          )}
        >
          {header}
        </div>
        <div className="my-2">{subheader}</div>
        {text && (
          <div className="mt-3 lg:mt-6 text-xs md:text-sm lg:text-xl font-light lg:font-normal text-black">
            <div className="mt-4 text-lg">{text}</div>
          </div>
        )}
        <div className="w-full pr-2 bottom-0 ">
          <div className="flex gap-3 border-t border-solid border-white text-white py-2">
            <span className="font-semibold text-lg">{subject}</span>|
            <span className="font-normal text-lg grow">VR</span>|
            <div className="flex items-center float-right">
              <BiTime />
              <span className="text-right ml-1.5">{minutes} minutes</span>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ReviewSimulation;
