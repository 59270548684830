import React from 'react';
import {
  FieldValues,
  UseFormRegister,
  DeepMap,
  DeepPartial,
  FieldError,
  UseFormSetValue,
  UseFormGetValues,
  Path,
  PathValue,
} from 'react-hook-form';
import Input from '../../form/input/Input';
import ListBox, {ListBoxOption} from '../../form/listbox/Listbox';
import {
  DefaultStyleAdjustments,
  ElementAlignment,
} from '../elements/common/CommonTypes';

const ElementAlignmentOptions: ListBoxOption<ElementAlignment>[] = [
  {label: 'Left', value: ElementAlignment.LEFT},
  {label: 'Right', value: ElementAlignment.RIGHT},
  {label: 'Center', value: ElementAlignment.CENTER},
];

type BasicAdjustmentsParameters<T extends DefaultStyleAdjustments> = {
  registration: {
    register: UseFormRegister<T>;
    errors: DeepMap<DeepPartial<FieldValues>, FieldError>;
  };
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
} & React.HTMLAttributes<HTMLElement>;
const BasicAdjustments = <T extends DefaultStyleAdjustments>({
  registration,
  getValues,
  setValue,
  ...rest
}: BasicAdjustmentsParameters<T>) => {
  return (
    <div {...rest}>
      <div className="flex gap-2">
        <ListBox
          className="flex-1"
          name={'elementAlignment' as Path<T>}
          options={
            ElementAlignmentOptions as ListBoxOption<PathValue<T, Path<T>>>[]
          }
          label="Element alignment"
          setValue={setValue}
          getValues={getValues}
        />
        <Input
          label="Max width"
          name={'maxWidth' as Path<T>}
          registration={registration}
          className="flex-1"
        />
      </div>
      <div className="flex gap-2 mt-2">
        <Input
          label="Margin top"
          name={'marginTop' as Path<T>}
          registration={registration}
          className="flex-1"
        />
        <Input
          label="Margin right"
          name={'marginRight' as Path<T>}
          registration={registration}
          className="flex-1"
        />
        <Input
          label="Margin bottom"
          name={'marginBottom' as Path<T>}
          registration={registration}
          className="flex-1"
        />
        <Input
          label="Margin left"
          name={'marginLeft' as Path<T>}
          registration={registration}
          className="flex-1"
        />
      </div>
    </div>
  );
};

export default BasicAdjustments;
