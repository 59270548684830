import {Store} from 'react-notifications-component';

export enum NotificationType {
  success = 'success',
  danger = 'danger',
  info = 'info',
  default = 'default',
  warning = 'warning',
}

export interface NotificationHandlerProps {
  title?: string;
  message?: string;
  duraction?: number;
  type?: NotificationType;
}

export const UniversalNotificationHandler = (
  title = 'Done',
  message = 'Undefined',
  type = NotificationType.default,
  duraction = 5000,
) => {
  Store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'bottom-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duraction,
      onScreen: true,
    },
  });
};
