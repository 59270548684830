import React from 'react';
import {ViewerElementProps} from '../../types/EditorBlock';
import {EditorVideoData} from './VideoTypes';

const VideoViewerBlock: React.FC<ViewerElementProps<EditorVideoData>> = (
  originBlock,
) => (
  <div className="aspect-video relative w-full lg:w-4/6 mx-auto">
    <iframe
      src={originBlock.data.url}
      title="Video"
      className="w-full h-full absolute"
    />
  </div>
);

export default VideoViewerBlock;
