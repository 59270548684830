import React from 'react';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';
import LiveViewerBlockWrapper from '../viewerBlockWrappers/LiveViewerBlockWrapper';
import ViewerBlockWrapper from '../viewerBlockWrappers/ViewerBlockWrapper';

interface ContentEditorViewerProps {
  blocks: EditorBlock<EditorBlockData>[];
  isUseLocalVersion?: boolean;
  isUseLiveVersion?: boolean;
}
const ViewerAutoblock: React.FC<ContentEditorViewerProps> = ({
  blocks,
  isUseLocalVersion = false,
  isUseLiveVersion = false,
}) => {
  if (isUseLiveVersion)
    return (
      <>
        {blocks.map((element, index) => (
          <LiveViewerBlockWrapper
            key={index}
            originBlock={element}
            isUseLocalVersion={isUseLocalVersion}
          />
        ))}
      </>
    );

  return (
    <>
      {blocks.map((element, index) => (
        <ViewerBlockWrapper
          key={index}
          originBlock={element}
          isUseLocalVersion={isUseLocalVersion}
        />
      ))}
    </>
  );
};

export default ViewerAutoblock;
