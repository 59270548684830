import {EditorBlockData} from '../../types/EditorBlock';
import {ImageFit, ImageQuality} from '../image/ImageTypes';

export enum CarouselImageType {
  Format169 = '16:9',
  Square = '1:1',
  OriginalSize = 'Original size',
}

export interface EditorCarouselData extends EditorBlockData {
  images: string[];
  quality: ImageQuality;
  maxWidth?: string;
  sizeType?: CarouselImageType;
  imageFit?: ImageFit;
}
