import React from 'react';
import {ViewerElementProps} from '../../types/EditorBlock';
import {EditorHeaderData, HeaderType} from './HeaderTypes';
import classNames from 'classnames';

const HeaderViewer: React.FC<ViewerElementProps<EditorHeaderData>> = (
  originBlock,
) => (
  <div
    className={classNames(
      originBlock.data.style === HeaderType.NORMAL && 'font-bold text-2xl',
      originBlock.data.style === HeaderType.COURSEHEADER &&
        'font-bold text-3xl md:text-6xl text-indigo-900 text-center mt-4 mb-2 md:mt-12 md:mb-10',
    )}
  >
    {originBlock.data.text}
  </div>
);

export default HeaderViewer;
