import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as VRLogo} from '../../assets/logo/VRLessons-logo3.svg';
import {useTranslation} from 'react-i18next';

import './Footer.css';

const FooterRU: React.FC = () => {
  const {t} = useTranslation('footer');

  return (
    <div className="w-full bg-gray-700 font-montserrat pt-10 lg:pt-20 pb-16 px-5 z-0 relative">
      <div className="max-w-screen-1120 w-full mx-auto px-2 lg:px-0">
        <VRLogo className="text-white h-8" />
        <hr className="mt-8 border border-solid border-white w-full" />
        <div className="flex justify-between mt-8 text-white flex-col md:flex-row">
          <div className="w-full md:w-4/6 flex flex-col justify-between">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2">
                <p className="font-semibold">
                  <Link to="/contacts">{t('feedback')}</Link>
                </p>
                <p className="mt-4">
                  <a
                    href="mailto:support@vrlessons.ru"
                    className="hover:underline text-lg"
                  >
                    support@vrlessons.ru
                  </a>
                </p>
              </div>
              <div className="mt-4 md:mt-0 w-full md:w-1/2">
                <p className="font-semibold">
                  <Link to="/">{t('main')}</Link>
                </p>
                <p className="mt-4">
                  <Link to="/simulations">{t('simulations')}</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 md:mt-0 w-full md:w-2/6 hidden-on-mobile">
            <p className="mt-4 flex flex-col">
              <Link to="/policies/policy">{t('confidentiality')}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterRU;
