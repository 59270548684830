import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {allENAlphabet} from '../../../../../services/utils/alphabet';
import Button from '../../../../form/button/Button';
import {ViewerElementProps} from '../../../types/EditorBlock';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {EditorMatchingAnswerData} from './MatchingAnswerTypes';

const MatchingAnswerViewer: React.FC<
  ViewerElementProps<EditorMatchingAnswerData>
> = (originBlock) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [isAnswerDone, setIsAnswerDone] = useState(false);

  useEffect(() => {
    setSelectedAnswers([
      ...originBlock.data.correctAnswersId.map(() => 'NotAnswer'),
    ]);
  }, [originBlock.data.correctAnswersId]);

  useEffect(() => {
    setIsAnswerDone(false);
  }, [originBlock.data]);

  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);

  // ф-ия срабатывает при отправке ответа
  const onSendAnswer = useCallback(() => {
    if (selectedAnswers.filter((answer) => answer === 'NotAnswer').length > 0)
      return;

    setIsAnswerDone(true);
    setIsAnswerCorrect(() => {
      for (let i = 0; i < originBlock.data.correctAnswersId.length; ++i) {
        if (selectedAnswers[i] != originBlock.data.correctAnswersId[i])
          return false;
      }
      return true;
    });
  }, [originBlock.data.correctAnswersId, selectedAnswers]);

  const changeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
      const newData = [...selectedAnswers];
      newData[index] = e.target.value;
      setSelectedAnswers(newData);
    },
    [selectedAnswers],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <>
      <div className=" flex gap-4">
        {[originBlock.data.staticOptions, originBlock.data.answerOptions].map(
          (column, indexColumn) => {
            return (
              <div key={indexColumn}>
                <h1 className="mb-3 text-xl">{column.title}</h1>
                {originBlock.data.staticOptions.answers.map((answer, index) => {
                  return (
                    <div
                      key={answer.id}
                      className="border border-solid border-blue-400 p-2 text-lg mb-1 hover:bg-blue-50 flex justify-between items-center h-14"
                    >
                      <div>
                        {allENAlphabet[index]}){' '}
                        <span className="py-1 px-2">
                          {indexColumn === 0 ? (
                            answer.answer
                          ) : (
                            <select
                              name="select"
                              id="select"
                              disabled={isAnswerDone}
                              className={classNames(
                                'py-1 px-2 text-center border hover:bg-blue-100',
                                selectedAnswers[index] === 'NotAnswer' &&
                                  'bg-red-200',
                                isAnswerDone &&
                                  selectedAnswers[index] ===
                                    originBlock.data.correctAnswersId[index] &&
                                  'bg-green-200',
                                isAnswerDone &&
                                  selectedAnswers[index] !==
                                    originBlock.data.correctAnswersId[index] &&
                                  'bg-red-200',
                              )}
                              onChange={(e) => changeSelect(e, index)}
                              value={selectedAnswers[index]}
                            >
                              {[
                                {
                                  value: 'NotAnswer',
                                  label: 'Choose your answer',
                                },
                                ...originBlock.data.answerOptions.answers.map(
                                  (listanswer) => {
                                    return {
                                      label: listanswer.answer,
                                      value: listanswer.id,
                                    };
                                  },
                                ),
                              ].map((list) => (
                                <option value={list.value} key={list.value}>
                                  {list.label}
                                </option>
                              ))}
                            </select>
                          )}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
        )}
      </div>
      <div className="text-center mt-2">
        {!isAnswerDone && (
          <Button size="base" className="block" onClick={onSendAnswer}>
            Answer
          </Button>
        )}
        {isAnswerDone && (
          <div className="flex items-center gap-3">
            <span
              className={classNames(
                'shrink p-2 text-white rounded-md',
                isAnswerCorrect ? 'bg-green-500  ' : 'bg-red-600',
              )}
            >
              {isAnswerCorrect ? 'Correct' : 'Wrong'}
            </span>
            {!isAnswerCorrect && (
              <div className="flex">
                <span className="mr-2">Correct answers are:</span>
                <div className="flex gap-1">
                  {`${originBlock.data.correctAnswersId.map(
                    (correctAnswerId, index) =>
                      `${allENAlphabet[index]}-${
                        originBlock.data.answerOptions.answers.filter(
                          (answer) => {
                            return answer.id === correctAnswerId;
                          },
                        )[0].answer
                      } `,
                  )}`}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MatchingAnswerViewer;
