import {useEffect, useState} from 'react';
import {AiOutlineRollback} from 'react-icons/ai';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {useGetCustomPage} from '../../api/customPage/CustomPage';
import LoadingEditorBlock from '../../components/editor/elements/common/LoadingBlock';
import ViewerAutoblock from '../../components/editor/service/autoBlocks/ViewerAutoblock';
import FormButton from '../../components/form/button/FormButton';
import {useLocalization} from '../../services/flagProvider/FlagProvider';

const CustomPage: React.FC = () => {
  const {section, subsection, page} = useParams<{
    section?: string;
    subsection?: string;
    page: string;
  }>();
  const localization = useLocalization();

  const customPage = useGetCustomPage(
    page || '',
    localization.getCurrentLocale(),
    section,
    subsection,
  );

  const [isUseBackButton, setIsUseBackButton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('back')) {
      searchParams.delete('back');
      setSearchParams(searchParams);
      setIsUseBackButton(true);
    }
  }, [searchParams, setSearchParams]);

  if (customPage.isLoading) return <LoadingEditorBlock />;
  if (!customPage.isSuccess) return <div>Cant find this page</div>;

  return (
    <div>
      {isUseBackButton && (
        <Link to="/library/dashboard" className="bg-white w-fit mb-2 block">
          <FormButton>
            <div className="flex justify-between items-center gap-2">
              <AiOutlineRollback /> Back to library
            </div>
          </FormButton>
        </Link>
      )}
      {customPage.data.body && (
        <ViewerAutoblock blocks={[JSON.parse(customPage.data.body)]} />
      )}
      {isUseBackButton && (
        <Link
          to="/library/dashboard"
          className="bg-white w-fit mt-4 block sticky right-32 bottom-12 ml-auto"
        >
          <FormButton>
            <div className="flex justify-between items-center gap-2">
              <AiOutlineRollback /> Back to library
            </div>
          </FormButton>
        </Link>
      )}
    </div>
  );
};

export default CustomPage;
