import {Link} from 'react-router-dom';
import FullBlock from '../../components/common/fullBlock/FullBlock';
import Button from '../../components/form/button/Button';
import QuestionLine from '../../components/mainPage/faq/faq';
import HowItWorksElement from '../../components/mainPage/howItWorks/HowItWorks';
import './homePage.css';

import avatar03 from '../../assets/homePage/avatar03.jpg';
import avatar04 from '../../assets/homePage/avatar04.jpg';
import ReviewElement from '../../components/mainPage/review/ReviewElement';
import ReviewBlock from '../../components/mainPage/review/ReviewBlock';
import ReviewSimulation from '../../components/mainPage/review/ReviewSimulation';

const HomePageEducators: React.FC = () => (
  <>
    <section className="w-full hero flex flex-col items-center">
      <div className="flex relative w-full">
        <div className="relative flex flex-col w-full h-full z-10 overvideo-bg">
          <div className="px-8 lg:px-2 lg:ml-40 mb-4 md:mb-10 lg:mb-44">
            <h1 className="font-bold text-4xl lg:text-6xl text-left text-white mt-10 lg:mt-56">
              Empower your students to love STEM
            </h1>
            <hr className="mt-8 border border-solid border-white w-9/12" />
            <h3 className="mt-8 font-normal text-xl text-white">
              STEM VR labs for K12 students
            </h3>
            <div className="flex flex-col items-center lg:flex-row mt-12 lg:mt-24">
              <a
                href="https://calendly.com/drstemmy/vr-apps-tutorial"
                className="mr-0 lg:mr-6 w-full lg:w-auto"
              >
                <Button className="w-full">Try Demo</Button>
              </a>
            </div>
          </div>
        </div>
        <div className="absolute top-0 items-center w-full h-full overflow-hidden justify-center z-0">
          <video
            autoPlay
            loop
            muted
            className="block w-full h-full object-cover object-center"
          >
            <source src="/bgvideo2.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </section>

    <FullBlock header="How it works?" className="bg-gray-200">
      <div className="justify-between grid gap-y-4 lg:grid-cols-3 grid-cols-1 w-full">
        <HowItWorksElement number="1." title="Subscribe to XReadyLab">
          <p className="text-sm lg:text-base">
            Choose simulations and 3D models for your lesson. Request new topics
            by completing the
            <Link to="/contacts" className="underline ml-1">
              form
            </Link>
          </p>
        </HowItWorksElement>
        <HowItWorksElement number="2." title="Book a date for VR class set-up">
          <p className="text-sm lg:text-base">
            We train your employees and install a VR class
          </p>
        </HowItWorksElement>
        <HowItWorksElement number="3." title="Technical support">
          <p className="text-sm lg:text-base">
            Teachers receive technical support and will be informed of new
            applications
          </p>
        </HowItWorksElement>
        {/* <HowItWorksElement image={howItWork04} title="4. Все готово" /> */}
      </div>
    </FullBlock>

    <FullBlock header="Still have questions?">
      <div className="flex flex-col w-full">
        <QuestionLine
          question="Is the material of the laboratories synchronized with the school curriculum?"
          number="01"
        >
          <p>
            Our laboratories are suitable for children of all countries. We
            cover the fundamental topics presented in most school programs.
          </p>
        </QuestionLine>
        <QuestionLine
          question="We are a school; How can we subscribe?"
          number="02"
        >
          <p>
            Please contact our customer support service at support@xreadylab.com
            or book a call with the team using the link to find out the
            conditions and book the VR class set up at your school.
            <Link to="/contacts" className="text-blue-500">
              Book a call
            </Link>
          </p>
        </QuestionLine>
        <QuestionLine
          question="What does the subscription consist of?"
          number="03"
        >
          <p>
            Subscription to XReadylab interactive VR labs. If you are a school,
            then you are also given access to the VR classroom system. VR class
            system helps you easily launch VR lessons for a large number of
            students, follow the experience of each student, as well as
            customise the content without developers.
          </p>
        </QuestionLine>
        <QuestionLine
          question="What is included in the VR class system?"
          number="04"
        >
          <ul>
            <li>
              Broadcast system to track all students of your class on a single
              screen
            </li>
            <li>
              The simultaneous launch of laboratories via the web interface
            </li>
            <li>
              Ability to edit scripts and insert your own methodological content
            </li>
            <li>
              You can launch the content from other companies in a
              teacher-friendly format
            </li>
          </ul>
        </QuestionLine>
        <QuestionLine
          question="At what age can we use VR headsets?"
          number="06"
        >
          <p>
            We adhere to the world's generally accepted recommendations and
            research. Our products are suitable for children from 12 years old
          </p>
        </QuestionLine>
      </div>
    </FullBlock>

    <FullBlock header="Catalog of our simulations" isDark>
      {/* <div className="grid gap-8 grid-cols-1 lg:grid-cols-3 mb-12">
          {rndCourses.isSuccess &&
            rndCourses.data.map((el, index) => (
              <CourseCard data={el} key={index} whiteTheme />
            ))}
        </div> */}

      <ReviewBlock
        hideNavigationOnMobile
        className="text-white my-4 mb-8"
        slides={[
          <ReviewSimulation
            header="Mitosis"
            headerClass="text-white"
            minutes="20"
            subject="Biology"
            subheader={
              <div className="mb-4 text-white">
                <p>
                  Explore the stages of living cells' mitotic division by
                  performing the key events of mitosis by yourself!
                </p>
              </div>
            }
            video="https://www.youtube.com/embed/mwMXJP3teyo"
          />,
          <ReviewSimulation
            header="Nucleotides Factory"
            headerClass="text-white"
            minutes="20"
            subject="Chemistry"
            subheader={
              <div className="mb-4 text-white">
                <p>
                  The structure of living cells resembles the work of a huge
                  modern factory. Immerse yourself in this world in the VR lab
                  "How to build DNA?". The instructions written in the DNA
                  molecule orchestrate the processes in the "cell factory".
                </p>
              </div>
            }
            video="https://www.youtube.com/embed/SWvJSybDk1A"
          />,
          <ReviewSimulation
            header="Physics"
            headerClass="text-white"
            minutes="25"
            subject="Physics"
            subheader={
              <div className="mb-4 text-white">
                <p>
                  The app provides a realistic representation of the solar
                  system and outer space. The main objective is to explore the
                  solar system through a set of interactive quests and
                  understand the requirements to survive on different planets.
                </p>
              </div>
            }
            video="https://www.youtube.com/embed/j_Ckq3waWW4"
          />,
        ]}
      />

      <Link to="/simulations" className="w-auto mx-auto mt-8">
        <Button className="mx-auto block">Explore all Simulations</Button>
      </Link>
    </FullBlock>

    <FullBlock header="Feedback">
      <ReviewBlock
        hideNavigationOnMobile
        slides={[
          <ReviewElement
            header="Shamil Sunyaev"
            subheader="Professor of Biomedical Informatics, Harvard Medical School, Professor of Medicine"
            text="Many images are not available to everyone, but they are often the starting point in the successful solution of scientific problems. Laboratories amaze not only with their visuals but also with scientific precision. I am sure that this approach will help interest a vast number of student and better understand abstract material."
            image={avatar03}
          />,
          <ReviewElement
            header="Andrzej Cichocki"
            subheader="Polish computer scientist and professor. TOP 200 scientists in G2R World Ranking (h-index 104)"
            text="Virtual reality (VR) allows to engage and inspire students in a unique and powerful way, increase their visual and auditory attentions and enhance their motivation to learn and discover. VR Lessons has ambitious to improve education by providing students with memorable and immersive experiences."
            image={avatar04}
          />,
        ]}
      />
    </FullBlock>
  </>
);

export default HomePageEducators;
