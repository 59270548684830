/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import {
  deleteElementByIndex,
  swapElementsByIndex,
} from '../../../../helpers/ArrayUtils';
import NavigationAutoblock from '../editorBlockWrappers/NavigationAutoblock';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';
import {
  useUpdateEditorBlock,
  useUpdateEditorBlockPosition,
} from '../../types/UseEditor';

interface ContentEditorViewerProps {
  blocks: EditorBlock<EditorBlockData>[];
  onElementDeleted?: (id: string) => void;
  isMasterBlock?: boolean;
  coreBlockId?: string;
}
const EditorAutoblock: React.FC<ContentEditorViewerProps> = ({
  blocks,
  onElementDeleted,
  coreBlockId,
  isMasterBlock = false,
}) => {
  const [getPositions, setPositions] = useState(
    // Array.from({length: blocks.length}, (_, i) => i),
    blocks.map((x) => x.order),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setBlockMutation = useUpdateEditorBlock<any>();
  const setBlockPosition = useUpdateEditorBlockPosition();

  useEffect(() => {
    for (let i = 0; i < blocks.length; i += 1) {
      setBlockMutation.mutate(blocks[i]);
    }
  }, [coreBlockId]);

  const updateBlockOrder = useCallback(
    (index: number, order: number) => {
      setBlockPosition.mutate({
        id: blocks[index].id,
        order,
      });
    },
    [blocks, setBlockPosition],
  );

  const onPositionUpdated = useCallback(
    (currentPosition: number, direction: number) => {
      const currentElementIndex = getPositions.indexOf(currentPosition);
      const otherElementIndex = getPositions.indexOf(
        currentPosition + direction,
      );

      updateBlockOrder(currentElementIndex, currentPosition + direction);
      updateBlockOrder(otherElementIndex, currentPosition);

      setPositions((elements) => [
        ...swapElementsByIndex(
          elements,
          currentElementIndex,
          otherElementIndex,
        ),
      ]);
    },
    [getPositions, updateBlockOrder],
  );

  const onLocalElementDeleted = useCallback(
    (index: number) => {
      if (!onElementDeleted) return;
      for (let i = 0; i < blocks.length; i += 1) {
        if (i < index) updateBlockOrder(i, getPositions[index]);
        if (i > index) updateBlockOrder(i, getPositions[index] - 1);
      }
      onElementDeleted(blocks[index].id);

      setPositions((old) => {
        const updated = [
          ...deleteElementByIndex(
            old.map((x) => (x > old[index] ? x - 1 : x)),
            index,
          ),
        ];
        return updated;
      });
    },
    [blocks, onElementDeleted, setPositions],
  );

  useEffect(() => {
    if (getPositions.length < blocks.length) {
      setPositions((old) => [...old, old.length]);
    }
  }, [blocks.length]);

  return (
    <div className="flex flex-col">
      {blocks.map((element, index) => (
        <div style={{order: getPositions[index]}} key={index}>
          <NavigationAutoblock
            originBlock={element}
            index={index}
            currentOrder={getPositions[index]}
            maxIndex={blocks.length - 1}
            onPositionChanged={onPositionUpdated}
            onDeleteAtIndex={onLocalElementDeleted}
            isMasterBlock={isMasterBlock}
          />
        </div>
      ))}
    </div>
  );
};

export default EditorAutoblock;
