export interface CoursesBlockProps {
  className?: string;
  header?: string;
  list?: string[];
  image?: string;
}

const CoursesBlock: React.FC<CoursesBlockProps> = ({
  className,
  header,
  list = [''],
  image,
}) => {
  return (
    <div className={`flex mb-4 flex-col md:flex-row ${className}`}>
      <div className="w-full md:w-3/5">
        <h2 className="mt-4 md:mt-12 text-4xl font-medium mb-5">{header}</h2>
        <ul className="pl-4 mb-4 list-disc text-base">
          {list.map((li, index) => (
            <li key={index}>{li}</li>
          ))}
        </ul>
      </div>
      <div className="w-full md:w-2/5 px-2">
        <img src={image} className="w-full mt-4 md:mt-12" alt="VR-girl" />
      </div>
    </div>
  );
};

export default CoursesBlock;
