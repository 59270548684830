export function youtubeParser(url: string): string | undefined {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  const id = match && match[7].length == 11 ? match[7] : undefined;

  if (!id) return undefined;

  return `https://www.youtube.com/embed/${id}`;
}

export function vimeoParser(url: string): string | undefined {
  const regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const match = url.match(regExp);

  if (!match) return undefined;

  return `https://player.vimeo.com/video/${match[5]}`;
}
