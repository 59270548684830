import React, {useCallback} from 'react';
import {EditorBlocks} from '../../AllBlocks';
import LoadingEditorBlock from '../../elements/common/LoadingBlock';
import {EditorGridViewerData} from '../../elements/grid/GridType';
import {
  BlockAdditionalData,
  BlockContainer,
  EditorBlock,
  EditorBlockData,
} from '../../types/EditorBlock';
import {
  useEditorBlockForced,
  useUpdateEditorBlockMargin,
} from '../../types/UseEditor';
import ContentEditorContainer from './ContentEditorContainer';
import PresentationEditorContainer from './PresentationEditorContainer';

interface NavigationAutoblockProps {
  originBlock: EditorBlock<EditorBlockData>;

  index: number;
  currentOrder: number;
  maxIndex: number;
  onPositionChanged: (oldPosition: number, newPosition: number) => void;
  onDeleteAtIndex: (index: number) => void;
  isMasterBlock?: boolean;
}

const NavigationAutoblock: React.FC<NavigationAutoblockProps> = ({
  originBlock,
  index,
  currentOrder,
  maxIndex,
  onPositionChanged,
  onDeleteAtIndex,
  isMasterBlock = false,
}) => {
  const {data: block} = useEditorBlockForced<EditorGridViewerData>(
    originBlock as EditorBlock<EditorGridViewerData>,
  );
  const setBlockMargin = useUpdateEditorBlockMargin();

  const OnAdditionalBlockDataChanged = useCallback(
    (data: BlockAdditionalData) => {
      setBlockMargin.mutate({
        id: originBlock.id,
        additionalData: data,
      });
    },
    [originBlock, setBlockMargin],
  );

  if (!block) return <LoadingEditorBlock />;

  switch (block.container) {
    case BlockContainer.Presentation:
      return (
        <PresentationEditorContainer
          elementIndex={index}
          currentOrder={currentOrder}
          maxIndex={maxIndex}
          onPositionChanged={onPositionChanged}
          onDeleteAtIndex={onDeleteAtIndex}
        >
          <div>{EditorBlocks[block.type].editor(block)}</div>
        </PresentationEditorContainer>
      );

    default:
      return (
        <ContentEditorContainer
          elementIndex={index}
          currentOrder={currentOrder}
          blockAdditionalData={block.blockAdditionalData}
          maxIndex={maxIndex}
          onPositionChanged={onPositionChanged}
          onDeleteAtIndex={onDeleteAtIndex}
          OnAdditionalBlockDataChanged={OnAdditionalBlockDataChanged}
          isMasterBlock={isMasterBlock}
        >
          <div>{EditorBlocks[block.type].editor(block)}</div>
        </ContentEditorContainer>
      );
  }
};

export default NavigationAutoblock;
