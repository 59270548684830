import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {allFlags} from '../../services/flagProvider/AllFlags';
import {useLocalization} from '../../services/flagProvider/FlagProvider';

const FlagPage: React.FC = () => {
  const {setFlag} = useLocalization();
  const navigate = useNavigate();

  const onSetFlag = useCallback(
    (region: string, shortName: string) => {
      setFlag(region, shortName);
      navigate(-1);
    },
    [navigate, setFlag],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="my-10">
      <h3 className="text-5xl font-semibold mb-7 inline-block mr-4">
        Choose your region:
      </h3>
      <div className="mt-5 lg:mt-12 grid md:grid-cols-5 sm:grid-cols-2 grid-cols-1 gap-y-5">
        {allFlags.map((region) => {
          return (
            <div className="" key={region.region}>
              <h4 className="text-2xl font-bold">{region.region}</h4>
              <ul className="mt-3">
                {region.countries.map((country) => {
                  return (
                    <li
                      key={country.name}
                      className="cursor-pointer text-pink-500 mt-4"
                      onClick={() => {
                        onSetFlag(region.region, country.shortName);
                      }}
                      onKeyDown={() => {}}
                      role="button"
                    >
                      {country.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default FlagPage;
