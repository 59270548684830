import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {
  AiOutlineAppstore,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineStop,
} from 'react-icons/ai';
import {BasicElement} from '../../../../helpers/BasicElement';

interface PresentationEditorContainerProps extends BasicElement {
  elementIndex: number;
  currentOrder: number;
  maxIndex: number;
  isProhibitLastElementDelete?: boolean;
  onPositionChanged: (oldPosition: number, newPosition: number) => void;
  onDeleteAtIndex: (index: number) => void;
}
const PresentationEditorContainer: React.FC<
  PresentationEditorContainerProps
> = ({
  elementIndex,
  currentOrder,
  maxIndex,
  onPositionChanged,
  onDeleteAtIndex,
  children,
  isProhibitLastElementDelete = false,
}) => {
  const [isFirstElement, setFirstElement] = useState(currentOrder === 0);
  const [isLastElement, setLastElement] = useState(currentOrder === maxIndex);
  const [isProhibitDelete, setProhobitDelete] = useState(
    isProhibitLastElementDelete &&
      currentOrder === maxIndex &&
      currentOrder === 0,
  );
  useEffect(() => {
    setFirstElement(currentOrder === 0);
    setLastElement(currentOrder === maxIndex);
    setProhobitDelete(
      isProhibitLastElementDelete &&
        currentOrder === maxIndex &&
        currentOrder === 0,
    );
  }, [currentOrder, isProhibitLastElementDelete, maxIndex]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <div
      className="flex border border-gray-200 m-2 relative"
      // style={{order: element.order}}
    >
      PRES
      <div className="flex-grow">{children}</div>
      <AiOutlineAppstore
        className="w-5 h-5 p-0.5 cursor-pointer absolute right-0"
        onClick={() => setIsMenuOpened((old) => !old)}
      />
      <div
        className={classNames(
          !isMenuOpened && 'hidden',
          'absolute right-0 top-5 grid grid-cols-2 auto-rows-min',
        )}
      >
        <AiOutlineArrowDown
          className={classNames(
            'w-5 h-10 p-0.5',
            isLastElement
              ? 'bg-gray-100'
              : 'bg-gray-300 hover:bg-gray-400 cursor-pointer',
          )}
          onClick={() => {
            if (!isLastElement) {
              setIsMenuOpened(false);
              onPositionChanged(currentOrder, 1);
            }
          }}
        />
        <AiOutlineArrowUp
          className={classNames(
            'w-5 h-10 p-0.5',
            isFirstElement
              ? 'bg-gray-100'
              : 'bg-gray-300 hover:bg-gray-400 cursor-pointer',
          )}
          onClick={() => {
            if (!isFirstElement) {
              setIsMenuOpened(false);
              onPositionChanged(currentOrder, -1);
            }
          }}
        />
        <AiOutlineStop
          className={classNames(
            'col-span-2 w-10 h-5 p-0.5 block',
            isProhibitDelete
              ? 'bg-red-100 text-gray-300'
              : 'bg-red-300 cursor-pointer',
          )}
          onClick={() => {
            if (!isProhibitDelete) {
              onDeleteAtIndex(elementIndex);
            }
          }}
        />

        <div
          className={classNames('w-5 h-5 p-0.5', !isMenuOpened && 'hidden')}
        />
      </div>
    </div>
  );
};

export default PresentationEditorContainer;
