/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback} from 'react';
import {queryClient} from '../../../app/App';
import EditorAutoblock from './autoBlocks/EditorAutoblock';
import {EditorGridViewerData, GridColumn} from '../elements/grid/GridType';
import {EditorBlocks} from '../AllBlocks';
import {EditorBlock, EditorBlockData} from '../types/EditorBlock';
import FormButton from '../../form/button/FormButton';
import {SaveBlocks} from './ContentEditor/Helpers';
import {editorBlockKey, SubscribeToPersistStorage} from '../types/UseEditor';

interface ContentEditorProps {
  coreBlock: EditorBlock<EditorGridViewerData>;
  onSave?: (block: EditorBlock<any>) => void;
  isManualSave?: boolean;
}

const ContentEditor: React.FC<ContentEditorProps> = ({
  coreBlock,
  onSave = () => {},
  isManualSave = false,
}) => {
  const SaveHandler = useCallback(() => {
    const allBlocks =
      queryClient.getQueriesData<EditorBlock<any>>(EditorBlocks);
    const sourceBlock = allBlocks.find((x) => x[1].id === coreBlock.id);
    const findElement = (id: string): EditorBlock<any> => {
      const block = allBlocks.find((x) => x[1].id === id);
      if (!block)
        throw new Error(`Save error! Block with id '${id}' is not found!`);

      return block[1];
    };

    const getObject = (block: EditorBlock<any>): EditorBlock<any> => {
      const baseObject: EditorBlock<any> = block;
      if (block.type === 'grid') {
        const typedBase = baseObject as EditorBlock<EditorGridViewerData>;
        const updatedColumns: GridColumn[] = [];
        for (let i = 0; i < typedBase.data.columns.length; i += 1) {
          const columnObjects: EditorBlock<EditorBlockData>[] = [];
          for (let j = 0; j < typedBase.data.columns[i].inner.length; j += 1) {
            const cachedBlock = findElement(
              typedBase.data.columns[i].inner[j].id,
            );
            const columnElemnt = getObject(cachedBlock);
            columnObjects.push(columnElemnt);
          }
          updatedColumns.push({
            ...typedBase.data.columns[i],
            inner: columnObjects,
          });
        }

        return {
          ...typedBase,
          data: {
            ...typedBase.data,
            columns: updatedColumns,
          },
        };
      }

      return baseObject;
    };

    if (sourceBlock) {
      onSave(getObject(sourceBlock[1]).data.columns[0].inner);
      queryClient.removeQueries([editorBlockKey]);
      console.log(getObject(sourceBlock[1]));
    }
  }, [coreBlock, onSave]);

  return (
    <>
      <div
        className="grid auto-cols-auto divide-y border border-gray-300 divide-gray-300 rounded-sm"
        id={coreBlock.id}
      >
        <EditorAutoblock
          coreBlockId={coreBlock.id}
          blocks={[coreBlock]}
          isMasterBlock
        />
      </div>
      {isManualSave && (
        <div className="flex gap-2 mt-2">
          <FormButton
            onClick={() => {
              SaveHandler();
            }}
            className="grow bg-green-100 hover:bg-green-300"
          >
            Save
          </FormButton>
          <FormButton
            onClick={() => {
              const data = SaveBlocks(coreBlock.id);

              const url = window.URL.createObjectURL(
                new Blob([JSON.stringify(data)], {
                  type: 'application/octet-stream',
                }),
              );
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.href = url;
              a.download = `blocks-${coreBlock.id}.json`;
              a.click();
              window.URL.revokeObjectURL(url);

              console.log(data);
            }}
          >
            Alternative save
          </FormButton>
        </div>
      )}
      <div className="flex gap-2 mt-2">
        <FormButton
          className="grow"
          onClick={() => {
            SubscribeToPersistStorage();
            window.open(`/admin/live/${coreBlock.id}`, '_blank');
            setTimeout(() => {
              queryClient.refetchQueries(['editor/block']);
            }, 500);
          }}
        >
          Live view
        </FormButton>
        <FormButton
          onClick={() => {
            queryClient.refetchQueries(['editor/block']);
          }}
        >
          Refresh live
        </FormButton>
      </div>
    </>
  );
};

export default ContentEditor;
