import {API_REGION} from '../../services/flagProvider/FlagProvider';
import SchoolCoursesPageCom from './CoursesPageEn';
import SchoolCoursesPageRu from './CoursesPageRu';

const SchoolCoursePageWrapper: React.FC = () => {
  return API_REGION === 'RU' ? (
    <SchoolCoursesPageRu />
  ) : (
    <SchoolCoursesPageCom />
  );
};

export default SchoolCoursePageWrapper;
