import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../../../form/button/Button';
import {ViewerElementProps} from '../../../types/EditorBlock';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {DataMultiAnswers, EditorMultiAnswersData} from './MultiAnswersTypes';
import {shuffle} from '../../../../../helpers/ArrayUtils';
import {allENAlphabet} from '../../../../../services/utils/alphabet';
import CheckboxViewer from './CheckboxViewer';

interface LabeledDataMultiAnswers extends DataMultiAnswers {
  label: string;
}

const MultiAnswerViewer: React.FC<
  ViewerElementProps<EditorMultiAnswersData>
> = (originBlock) => {
  const [answers, setAnswers] = useState<LabeledDataMultiAnswers[]>([]);
  const [isSingleAnswerMode, setIsSingleAnswerMode] = useState(false);

  useEffect(() => {
    setIsSingleAnswerMode(
      originBlock.data.answers.filter((x) => x.correct).length === 1 &&
        originBlock.data.isForceSingleAnswerQuestion,
    );
    setAnswers(
      shuffle(originBlock.data.answers).map((x, index) => ({
        ...x,
        label: allENAlphabet[index],
      })),
    );
  }, [originBlock.data.answers, originBlock.data.isForceSingleAnswerQuestion]);

  const [providedAnswers, setProvidedAnswers] = useState<string[]>([]);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);

  useEffect(() => {
    setIsAnswered(false);
    setIsAnswerCorrect(false);
    setProvidedAnswers([]);
  }, [originBlock.data]);

  const onAnswerProvided = useCallback(
    (guid: string) => {
      if (isAnswered) return;
      setProvidedAnswers((old) => {
        const containIndex = old.findIndex((x) => x === guid);
        if (containIndex === -1) {
          if (isSingleAnswerMode && old.length != 0) return [guid];
          return [...old, guid];
        }

        old.splice(containIndex, 1);
        return [...old];
      });
    },
    [isAnswered, isSingleAnswerMode],
  );

  const checkAnswers = useCallback((): boolean => {
    for (const answerOption of answers) {
      if (providedAnswers.includes(answerOption.id)) {
        if (!answerOption.correct) return false; // Answer is incorrect but wac choosen
      } else {
        if (answerOption.correct) return false; // Answer is corrent but was not choosen
      }
    }

    return true;
  }, [answers, providedAnswers]);

  const onFinishAnswer = useCallback(() => {
    setIsAnswerCorrect(checkAnswers());
    setIsAnswered(true);
  }, [checkAnswers]);

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="flex flex-col gap-2 ">
      {answers.map((element) => (
        <div
          className="flex hover:bg-blue-50 py-1 cursor-pointer items-center"
          key={element.id}
          onClick={() => {
            onAnswerProvided(element.id);
          }}
        >
          {providedAnswers.includes(element.id) ? (
            <CheckboxViewer
              isRound={isSingleAnswerMode}
              isChecked={true}
              size={32}
              className={classNames(
                isAnswered && element.correct && 'text-green-500',
                isAnswered && !element.correct && 'text-red-500',
              )}
            />
          ) : (
            <CheckboxViewer
              isRound={isSingleAnswerMode}
              isChecked={false}
              size={32}
              className="text-red-500"
            />
          )}
          <div className="px-2 py-1">
            <span className="mr-2">{`(${element.label})`}</span>
            <span>{element.answer}</span>
          </div>
        </div>
      ))}
      {!isAnswered && (
        <Button
          disabled={providedAnswers.length === 0}
          size="base"
          className="w-fit"
          onClick={onFinishAnswer}
        >
          Answer
        </Button>
      )}
      {isAnswered && (
        <div className="flex items-center gap-3">
          <span
            className={classNames(
              'shrink p-2 text-white rounded-md',
              isAnswerCorrect ? 'bg-green-500  ' : 'bg-red-600',
            )}
          >
            {isAnswerCorrect ? 'Correct' : 'Wrong'}
          </span>
          {!isAnswerCorrect && (
            <div className="flex">
              <span className="mr-2">
                {`Correct ${isSingleAnswerMode ? 'answer is' : 'answers are'}:`}
              </span>
              <div className="flex gap-1">
                {answers.map((x) =>
                  x.correct ? <span>{`${x.label}`}</span> : undefined,
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiAnswerViewer;
