import classNames from 'classnames';
import React from 'react';
import {BsArrowsFullscreen} from 'react-icons/bs';
import {PublicImageDTO} from '../../../../../api/media/dto/PublicImageDTO';
import ServerImage from '../../../../serverImage/ServerImage';
import {ImageQuality} from '../ImageTypes';
import './imagePopup.scoped.css';

interface ImageGridElementProps extends React.HTMLAttributes<HTMLDivElement> {
  image: PublicImageDTO;
  isActive: boolean;
  onFullscreenClicked: () => void;
}

const ImageGridElement: React.FC<ImageGridElementProps> = ({
  image,
  className,
  isActive,
  onFullscreenClicked,
  onClick,
  ...rest
}) => (
  <div
    className={classNames(
      'h-20 w-full relative border-4 hover:border-blue-400 w-full relative pt-full',
      isActive && 'border-4 border-blue-500',
      className,
    )}
    role="button"
    {...rest}
  >
    <ServerImage
      imageId={image.id}
      quality={ImageQuality.LOW}
      className="w-full h-full object-cover absolute top-0 left-0"
      alt="Public image"
      onClick={onClick}
    />
    <div
      className="absolute bottom-1 right-1 rounded-sm bg-gray-200 hover:bg-gray-300 p-0.5
    transition duration-100 ease-in-out transform hover:scale-110"
      onClick={() => onFullscreenClicked()}
      aria-hidden="true"
    >
      <BsArrowsFullscreen size={18} />
    </div>
  </div>
);

export default ImageGridElement;
