import React from 'react';
import ReactQuill from 'react-quill';
import {ViewerElementProps} from '../../types/EditorBlock';
import {EditorParagraphData} from './ParagraphType';
import './ParagraphViewer.css';

const EditorParagraphBlock: React.FC<
  ViewerElementProps<EditorParagraphData>
> = (originBlock) => (
  <div className="paragraph-viewer">
    <ReactQuill
      theme="snow"
      readOnly
      modules={{
        formula: true,
        toolbar: false,
      }}
      value={originBlock.data.paragraph}
    />
  </div>
);

export default EditorParagraphBlock;
