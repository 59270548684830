import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {PublicImageDTO} from '../../../../api/media/dto/PublicImageDTO';
import FormButton from '../../../form/button/FormButton';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import ServerImage from '../../../serverImage/ServerImage';
import HiddenSettings from '../../common/HiddenSettings';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import PublicImagesPopup from './imagePopup/ImagesGridPopup';

import {EditorImageData, ImageFit, ImageQuality, ImageType} from './ImageTypes';

const imageSizeOptions: ListBoxOption<ImageQuality>[] = [
  {label: 'Mid', value: ImageQuality.MID},
  {label: 'High', value: ImageQuality.HIGH},
  {label: 'Low', value: ImageQuality.LOW},
];

const ImageTypeOption: ListBoxOption<ImageType>[] = [
  {label: 'Full size', value: ImageType.FormatFull},
  {label: 'Original size', value: ImageType.OriginalSize},
  {label: '16:9', value: ImageType.Format169},
  {label: '4:3', value: ImageType.Format43},
];

const ImageFitOption: ListBoxOption<ImageFit>[] = [
  {label: 'None', value: ImageFit.None},
  {label: 'Contain', value: ImageFit.Contain},
  {label: 'Cover', value: ImageFit.Cover},
  {label: 'Fill', value: ImageFit.Fill},
  {label: 'Scale Down', value: ImageFit.ScaleDown},
];

const ImageEditorBlock: React.FC<EditorElementProps<EditorImageData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorImageData>();
  const {getValues, setValue, watch} = useForm<EditorImageData>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  const getImageFormatClass = useCallback((): string => {
    switch (originBlock.data.sizeType) {
      case ImageType.Format169:
        return 'aspect-w-16 aspect-h-9';

      case ImageType.Format43:
        return 'aspect-w-4 aspect-h-3';

      case ImageType.FormatFull:
      default:
        return 'w-full';
    }
  }, [originBlock]);

  const OnImageUpdated = useCallback(
    (image: PublicImageDTO[]) => {
      setValue('imageId', image[0].id);
    },
    [setValue],
  );

  return (
    <div className="text-2xl p-2 sm:p-5">
      <HiddenSettings opened={!originBlock}>
        <div className="flex gap-2 mb-2">
          <PublicImagesPopup onImagesSelected={OnImageUpdated} maxImages={1}>
            <FormButton>Select image</FormButton>
          </PublicImagesPopup>
          <ListBox
            name="quality"
            label="Quality"
            className="flex-1"
            options={imageSizeOptions}
            setValue={setValue}
            getValues={getValues}
          />
          <ListBox
            name="sizeType"
            label="Format"
            className=" flex-1"
            options={ImageTypeOption}
            setValue={setValue}
            getValues={getValues}
          />
          <ListBox
            name="imageFit"
            label="Object Fit"
            className=" flex-1"
            options={ImageFitOption}
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      </HiddenSettings>
      {originBlock.data.imageId ? (
        <div className="mx-auto">
          <div className={getImageFormatClass()}>
            <ServerImage
              imageId={originBlock.data.imageId}
              alt={originBlock.data.imageId}
              imageFit={originBlock.data.imageFit}
            />
          </div>
        </div>
      ) : (
        <PublicImagesPopup onImagesSelected={OnImageUpdated} maxImages={1}>
          <FormButton className="w-full">Select image</FormButton>
        </PublicImagesPopup>
      )}
    </div>
  );
};

export default ImageEditorBlock;
