import React, {useCallback, useState} from 'react';
import Button from '../../../../form/button/Button';
import {ViewerElementProps} from '../../../types/EditorBlock';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {
  DataCorrectOrderAnswer,
  EditorCorrectOrderAnswerData,
} from './CorrectOrderAnswerTypes';
import {Reorder} from 'framer-motion';
import {shuffle} from '../../../../../helpers/ArrayUtils';
import classNames from 'classnames';

const CorrectOrderAnswerViewer: React.FC<
  ViewerElementProps<EditorCorrectOrderAnswerData>
> = (originBlock) => {
  const [localAnswers, setLocalAnswers] = useState<DataCorrectOrderAnswer[]>(
    shuffle(originBlock.data.answers).map((element, index) => ({
      ...element,
      number: index + 1,
    })),
  );

  const [isAnswered, setIsAnswered] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);

  const checkAnswers = useCallback(
    (answersInOrder: DataCorrectOrderAnswer[]): boolean => {
      console.log(answersInOrder);
      for (let i = 0; i < answersInOrder.length; ++i) {
        if (answersInOrder[i].order !== i + 1) return false;
      }
      return true;
    },
    [],
  );

  const onSendAnswer = useCallback(
    (answersInOrder: DataCorrectOrderAnswer[]) => {
      setIsAnswered(true);
      setIsAnswerCorrect(checkAnswers(answersInOrder));
    },
    [checkAnswers],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="text-center max-w-7xl mx-auto">
      <Reorder.Group
        as="ol"
        axis="y"
        className="mb-4 list-none text-base"
        values={localAnswers}
        onReorder={(value) => {
          console.log(value);
          setLocalAnswers([...value]);
        }}
      >
        {localAnswers.map((item) => (
          <Reorder.Item
            key={item.id}
            value={item}
            dragListener={!isAnswered}
            className="flex items-center gap-2 mb-1 border cursor-pointer w-fit px-2"
            whileHover={{
              scale: 1.1,
              borderColor: 'rgb(96 165 250)',
              backgroundColor: 'rgb(239 246 255)',
              transition: {duration: 0.1},
            }}
            whileTap={{
              boxShadow: '2px 2px 4px rgba(0, 0, 0, .8)',
            }}
          >
            <span>{`${item.number})`}</span>
            <span className="w-fit px-2 py-1 cursor-pointer">
              {item.answer}
            </span>
          </Reorder.Item>
        ))}
      </Reorder.Group>
      {!isAnswered && (
        <Button
          size="base"
          className="block"
          onClick={() => onSendAnswer(localAnswers)}
        >
          Answer
        </Button>
      )}
      {isAnswered && (
        <div className="flex items-center gap-3">
          <span
            className={classNames(
              'shrink p-2 text-white rounded-md',
              isAnswerCorrect ? 'bg-green-500  ' : 'bg-red-600',
            )}
          >
            {isAnswerCorrect ? 'Correct' : 'Wrong'}
          </span>
          {!isAnswerCorrect && (
            <div className="flex">
              <span className="mr-2">Correct answers are:</span>
              <div className="flex gap-1">
                {localAnswers
                  .sort((x, y) => (x.order > y.order ? 1 : -1))
                  .map((x) => (
                    <span>{`${x.number}`}</span>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CorrectOrderAnswerViewer;
