import classNames from 'classnames';
import React, {useCallback} from 'react';
import {AiOutlineFileImage, AiOutlineLoading} from 'react-icons/ai';
import ServerImage from '../../../serverImage/ServerImage';
import {ViewerElementProps} from '../../types/EditorBlock';
import {EditorImageData, ImageType} from './ImageTypes';

const ImageViewerBlock: React.FC<ViewerElementProps<EditorImageData>> = (
  originBlock,
) => {
  const getImageFormatClass = useCallback((): string => {
    switch (originBlock.data.sizeType) {
      case ImageType.Format169:
        return 'aspect-w-16 aspect-h-9';

      case ImageType.Format43:
        return 'aspect-w-4 aspect-h-3';

      case ImageType.FormatFull:
      default:
        return 'w-full';
    }
  }, [originBlock]);

  return (
    <div className="text-2xl p-2 sm:p-5">
      <div className={classNames(getImageFormatClass(), 'w-full h-full')}>
        {originBlock.data.imageId ? (
          <ServerImage
            className="w-full h-full"
            imageId={originBlock.data.imageId}
            alt={originBlock.data.imageId}
            imageFit={originBlock.data.imageFit}
          />
        ) : (
          <div className="flex gap-4 text-xl">
            <AiOutlineFileImage />
            <span>Image ID not found</span>
            <AiOutlineLoading className="animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageViewerBlock;
