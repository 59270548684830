import classNames from 'classnames';
import {IconBaseProps} from 'react-icons';
import {
  BiCheckbox,
  BiCheckboxChecked,
  BiCheckCircle,
  BiCircle,
} from 'react-icons/bi';

interface CheckboxViewerProps extends IconBaseProps {
  isRound: boolean;
  isChecked: boolean;
}

const CheckboxViewer: React.FC<CheckboxViewerProps> = ({
  isChecked,
  isRound,
  className,
  ...rest
}) => {
  if (isRound && isChecked)
    return <BiCheckCircle className={classNames(className, 'p-1')} {...rest} />;
  if (isRound && !isChecked)
    return <BiCircle className={classNames(className, 'p-1')} {...rest} />;
  if (!isRound && isChecked)
    return <BiCheckboxChecked className={classNames(className)} {...rest} />;
  //   if (!isRound && !isChecked)
  return <BiCheckbox className={classNames(className)} {...rest} />;
};

export default CheckboxViewer;
