export interface EditorBlockData {
  majorVersion: number;
}

export enum BlockContainer {
  Default,
  Presentation,
}

export interface BlockAdditionalData {
  margin?: BlockMargin;
  padding?: BlockPadding;
  size?: BlockSize;
  customClassName?: string;
}

export interface BlockMargin {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export interface BlockPadding {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export interface BlockSize {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
}

export interface EditorBlock<T extends EditorBlockData> {
  id: string;
  container: BlockContainer;
  type: string;
  order: number;
  blockAdditionalData: BlockAdditionalData;
  data: T;
}

export interface ViewerElementProps<T extends EditorBlockData>
  extends EditorBlock<T> {
  isUseSharedLiveVersion: boolean;
  isUseMutableAutoblock?: boolean;
}

export interface EditorElementProps<T extends EditorBlockData>
  extends EditorBlock<T> {
  onSaveData?: (serializedData: string) => void;
}

export interface EditorBlockRefHandler {
  saveBlock(): EditorBlock<EditorBlockData>;
}
