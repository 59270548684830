import {AxiosError} from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import APIRequest from '../../api/Api';
import {AuthDTO} from '../../api/authorization/dto/AuthDTO';
import {BasicElement} from '../../helpers/BasicElement';

type AuthContext = {
  login: (token: AuthDTO) => void;
  logout: () => void;
  getAuthToken: () => string;
  isAuthenticated: boolean;
};

export const AuthContextElement = createContext<AuthContext>({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  getAuthToken: () => '',
});

const IsAuthenticatedKey = 'isAuthenticated';
const AuthTokenKey = 'authToken';

const AuthProvider: React.FC<BasicElement> = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem(IsAuthenticatedKey) === 'true',
  );

  const [interceptorNumber, setInterceptorNumber] = useState<
    number | undefined
  >();
  const [responseInterceptorNumber, setResponseInterceptorNumber] = useState<
    number | undefined
  >();

  const [authToken, setAuthToken] = useState(
    localStorage.getItem(AuthTokenKey),
  );

  const getAuthToken = useCallback(() => {
    return isAuthenticated ? authToken || '' : '';
  }, [authToken, isAuthenticated]);

  useEffect(() => {
    localStorage.setItem(IsAuthenticatedKey, isAuthenticated.toString());
  }, [isAuthenticated]);

  const ejectAuthIntercepter = () => {
    if (interceptorNumber !== undefined) {
      console.log('Ejecting request');
      APIRequest.interceptors.request.eject(interceptorNumber);
      setInterceptorNumber(undefined);
    }
  };

  const setAuthInjector = useCallback(() => {
    if (!isAuthenticated) return;
    const updatedInterceptorNumber = APIRequest.interceptors.request.use(
      (config) => {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      },
    );
    setInterceptorNumber(updatedInterceptorNumber);
  }, [authToken, isAuthenticated]);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    localStorage.removeItem(AuthTokenKey);
  }, []);

  const ejectResponseIntercepter = useCallback(() => {
    if (responseInterceptorNumber !== undefined) {
      console.log('Ejecting response');
      APIRequest.interceptors.response.eject(responseInterceptorNumber);
      setResponseInterceptorNumber(undefined);
    }
  }, [responseInterceptorNumber]);

  const setAuthErrorInjector = useCallback(() => {
    const updatedInterceptorNumber = APIRequest.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (
          error.status == '401' ||
          error.code == '401' ||
          error.response?.status == 401
        ) {
          logout();
        }
        throw error;
      },
    );
    setResponseInterceptorNumber(updatedInterceptorNumber);
  }, [logout]);

  const login = (token: AuthDTO) => {
    if (token.access_token) {
      localStorage.setItem(AuthTokenKey, token.access_token);
      setAuthToken(token.access_token);
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setAuthInjector();
      setAuthErrorInjector();
    }
    return () => {
      ejectAuthIntercepter();
      ejectResponseIntercepter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <AuthContextElement.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        getAuthToken,
      }}
    >
      {children}
    </AuthContextElement.Provider>
  );
};

export const useAuth = (): AuthContext => {
  const ctx = useContext(AuthContextElement);
  return ctx;
};

export default AuthProvider;
