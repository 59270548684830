import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './Header.css';
import {ReactComponent as VRLogo} from '../../assets/logo/VRLessons-logo3.svg';
import {FiMenu} from 'react-icons/fi';
import {FlagComponent} from 'country-flag-icons/react/3x2';
import classNames from 'classnames';

export interface Flag {
  name: string;
  shortName: string;
  component: FlagComponent;
}
const HeaderRU: React.FC = () => {
  const [isOpenedBurgerMenu, setIsOpenedBurgerMenu] = useState(false);
  const {t} = useTranslation('header');
  const location = useLocation();

  return (
    <div className="w-screen fixed z-50 font-montserrat">
      <div className="w-full flex items-center justify-between bg-indigo-900 h-16 py-0 px-0 lg:px-40 z-30">
        <Link to="/" className="ml-6 lg:ml-0 flex h-full">
          <VRLogo className="logo-width my-auto" />
        </Link>
        <nav className="vision-tablet items-center">
          <Link
            to="/"
            className={classNames(
              'text-white text-base mr-16',
              location.pathname === '/' ? 'font-semibold' : 'font-light',
            )}
          >
            {t('main')}
          </Link>
          <Link
            to="/simulations"
            className={classNames(
              'text-white text-base mr-16',
              location.pathname === '/simulations'
                ? 'font-semibold'
                : 'font-light',
            )}
          >
            {t('simulation')}
          </Link>
          <Link
            to="/contacts"
            className={classNames(
              'text-white text-base mr-16',
              location.pathname === '/contacts'
                ? 'font-semibold'
                : 'font-light',
            )}
          >
            {t('contacts')}
          </Link>
        </nav>
        <FiMenu
          size={26}
          color="white"
          className="mr-4 xl:hidden cursor-pointer"
          onClick={() => setIsOpenedBurgerMenu(!isOpenedBurgerMenu)}
        />
      </div>
      {isOpenedBurgerMenu && (
        <div className="fixed z-50 w-full h-screen menu-opened">
          <nav className="flex flex-col p-4 pt-16 font-bold">
            <Link
              to="/"
              className="text-white mb-4 text-xl"
              onClick={() => setIsOpenedBurgerMenu(false)}
            >
              {t('main')}
            </Link>
            <Link
              to="/simulations"
              className="text-white mb-4 text-xl"
              onClick={() => setIsOpenedBurgerMenu(false)}
            >
              {t('simulation')}
            </Link>
            <Link
              to="/contacts"
              className="text-white mb-4 text-xl"
              onClick={() => setIsOpenedBurgerMenu(false)}
            >
              {t('contacts')}
            </Link>
            <Link
              to="/contacts"
              className="text-white mb-4 text-xl"
              onClick={() => setIsOpenedBurgerMenu(false)}
            >
              {t('course')}
            </Link>
          </nav>
        </div>
      )}
    </div>
  );
};

export default HeaderRU;
