import {useEffect} from 'react';
import {
  BreakOnSize,
  EditorGridViewerData,
} from '../../components/editor/elements/grid/GridType';
import {SubscribeToPersistStorage} from '../../components/editor/types/UseEditor';
import {
  BlockContainer,
  EditorBlock,
} from '../../components/editor/types/EditorBlock';
import {useParams} from 'react-router-dom';
import ViewerAutoblock from '../../components/editor/service/autoBlocks/ViewerAutoblock';

const LiveEditorViewer: React.FC = () => {
  const {blockId} = useParams<{blockId: string}>();

  useEffect(() => {
    SubscribeToPersistStorage();
  }, []);

  const AllBlocks: EditorBlock<EditorGridViewerData> = {
    id: blockId || 'AllBlock-base-orhwgbg4uiogb',
    type: 'grid',
    container: BlockContainer.Default,
    blockAdditionalData: {},
    order: 2,
    data: {
      majorVersion: 0,
      columns: [
        {
          order: 0,
          inner: [],
        },
      ],
      theme: {
        color: '#000000',
        background: '#ffffff',
      },
      gap: '10px',
      breakOnSize: BreakOnSize.NEVER,
      padding: '0',
    },
  };

  return (
    <div>
      <ViewerAutoblock
        blocks={[AllBlocks]}
        isUseLocalVersion
        isUseLiveVersion
      />
    </div>
  );
};

export default LiveEditorViewer;
