import {useForm} from 'react-hook-form';
import Popup from 'reactjs-popup';
import {AuthRequestDAO} from '../../api/authorization/dao/AuthRequestDAO';
import {BasicElement} from '../../helpers/BasicElement';
import Button from '../form/button/Button';
import Input from '../form/input/Input';

interface AuthorizationPopupProps extends BasicElement {
  onAuth: (data: AuthRequestDAO) => void;
}

const AuthorizationPopup: React.FC<AuthorizationPopupProps> = ({
  onAuth,
  children,
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<AuthRequestDAO>();

  return (
    <Popup
      trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{
        padding: '15px',
        borderRadius: '0.375rem',
        maxWidth: '500px',
      }}
      modal
      nested
      open
      closeOnDocumentClick={false}
    >
      <form onSubmit={handleSubmit(onAuth)} className="p-3 w-full grid gap-3">
        <Input
          label="Email"
          type="email"
          name="username"
          registration={{register, errors}}
          options={{required: 'Enter username'}}
        />
        <Input
          label="Password"
          type="password"
          name="password"
          registration={{register, errors}}
          options={{required: 'Enter username'}}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Popup>
  );
};

export default AuthorizationPopup;
