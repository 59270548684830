import {ViewerElementProps} from '../../types/EditorBlock';
import BasicAdjustmentsContainer from '../common/BasicAdjustmentsContainer';
import {EditorSplitData} from './SplitTypes';

const SplitViewerBlock: React.FC<ViewerElementProps<EditorSplitData>> = ({
  data,
}) => (
  <BasicAdjustmentsContainer data={data} disableAutoBlockControl>
    <hr className="w-full" />
  </BasicAdjustmentsContainer>
);

export default SplitViewerBlock;
