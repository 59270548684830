import {Link} from 'react-router-dom';
import {useGetRandomCourses} from '../../api/courses/Courses';
import FullBlock from '../../components/common/fullBlock/FullBlock';
import CourseCard from '../../components/courses/CourseCard';
import Button from '../../components/form/button/Button';
import QuestionLine from '../../components/mainPage/faq/faq';
import HowItWorksElement from '../../components/mainPage/howItWorks/HowItWorks';
import './homePage.css';

const HomePageCOM: React.FC = () => {
  const rndCourses = useGetRandomCourses('en', 3);

  return (
    <>
      <section className="w-full hero flex flex-col items-center">
        <div className="flex relative w-full">
          <div className="relative flex flex-col w-full h-full z-10 overvideo-bg">
            <div className="px-8 lg:px-2 lg:ml-40 mb-4 md:mb-10 lg:mb-44">
              <h1 className="font-bold text-4xl lg:text-6xl text-left text-white mt-10 lg:mt-56">
                Nurture the Next Generation <br /> of Scientists
              </h1>
              <hr className="mt-8 border border-solid border-white w-4/6" />
              <h3 className="mt-8 font-normal text-xl text-white">
                STEM VR labs for K12 students
              </h3>
              <div className="flex flex-col items-center lg:flex-row mt-12 lg:mt-24">
                <Link
                  to="/request-demo"
                  className="mr-0 lg:mr-6 w-full lg:w-auto"
                >
                  <Button className="w-full">Request a demo</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="absolute top-0 items-center w-full h-full overflow-hidden justify-center z-0">
            <video
              autoPlay
              loop
              muted
              className="block w-full h-full object-cover object-center"
            >
              <source src="/bgvideo2.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <FullBlock header="How it works?" className="bg-gray-200">
        <div className="justify-between grid gap-y-4 lg:grid-cols-3 grid-cols-1 w-full">
          <HowItWorksElement number="1." title="Subscribe to XReadyLab">
            <p className="text-sm lg:text-base">
              Choose simulations and 3D models for your lesson. Request new
              topics by completing the
              <Link to="/contacts" className="underline ml-1">
                form
              </Link>
            </p>
          </HowItWorksElement>
          <HowItWorksElement
            number="2."
            title="Book a date for VR class set-up"
          >
            <p className="text-sm lg:text-base">
              We train your employees and install a VR class
            </p>
          </HowItWorksElement>
          <HowItWorksElement number="3." title="Technical support">
            <p className="text-sm lg:text-base">
              Teachers receive technical support and will be informed of new
              applications
            </p>
          </HowItWorksElement>
          {/* <HowItWorksElement image={howItWork04} title="4. Все готово" /> */}
        </div>
      </FullBlock>

      <FullBlock header="Still have questions?">
        <div className="flex flex-col w-full">
          <QuestionLine
            question="Is the material of the laboratories synchronized with the school curriculum?"
            number="01"
          >
            <p>
              Our laboratories are suitable for children of all countries. We
              cover the fundamental topics presented in most school programs.
            </p>
          </QuestionLine>
          <QuestionLine
            question="We are a school; How can we subscribe?"
            number="02"
          >
            <p>
              Please contact our customer support service at
              support@xreadylab.com or book a call with the team using the link
              to find out the conditions and book the VR class set up at your
              school.
              <Link to="/contacts" className="text-blue-500">
                Book a call
              </Link>
            </p>
          </QuestionLine>
          <QuestionLine
            question="What does the subscription consist of?"
            number="03"
          >
            <p>
              Subscription to XReadylab interactive VR labs. If you are a
              school, then you are also given access to the VR classroom system.
              VR class system helps you easily launch VR lessons for a large
              number of students, follow the experience of each student, as well
              as customise the content without developers.
            </p>
          </QuestionLine>
          <QuestionLine
            question="What is included in the VR class system?"
            number="04"
          >
            <ul>
              <li>
                Broadcast system to track all students of your class on a single
                screen
              </li>
              <li>
                The simultaneous launch of laboratories via the web interface
              </li>
              <li>
                Ability to edit scripts and insert your own methodological
                content
              </li>
              <li>
                You can launch the content from other companies in a
                teacher-friendly format
              </li>
            </ul>
          </QuestionLine>
          <QuestionLine
            question="At what age can we use VR headsets?"
            number="06"
          >
            <p>
              We adhere to the world's generally accepted recommendations and
              research. Our products are suitable for children from 12 years old
            </p>
          </QuestionLine>
        </div>
      </FullBlock>

      <FullBlock header="Catalog of our simulations" isDark>
        <div className="grid gap-8 grid-cols-1 lg:grid-cols-3 mb-12">
          {rndCourses.isSuccess &&
            rndCourses.data.map((el, index) => (
              <CourseCard data={el} key={index} whiteTheme />
            ))}
        </div>

        <Link to="/simulations" className="w-auto mx-auto">
          <Button className="mx-auto block">View all simulations</Button>
        </Link>
      </FullBlock>

      {/* <FullBlock header="Schedule time with us:" className="xl:hidden">
        <div className="w-full">
          <Link className="mx-auto block w-fit" to="/contacts">
            <Button className="">Schedule time</Button>
          </Link>
        </div>
      </FullBlock>

      <Link
        className="hidden xl:block fixed bottom-10 right-10 ml-auto z-30 float-right"
        to="/contacts"
      >
        <Button>Schedule time with us</Button>
      </Link> */}
    </>
  );
};

export default HomePageCOM;
