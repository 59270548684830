import React, {useState, useEffect, useCallback} from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import {BiTrashAlt} from 'react-icons/bi';
import Popup from 'reactjs-popup';
import {API_ROOT} from '../../../../../api/Api';
import {PublicImageDTO} from '../../../../../api/media/dto/PublicImageDTO';
import {useDeleteImage} from '../../../../../api/media/Media';
import {BasicElement} from '../../../../../helpers/BasicElement';
import {
  UniversalNotificationHandler,
  NotificationType,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import FormButton from '../../../../form/button/FormButton';
import ServerImage from '../../../../serverImage/ServerImage';
import {ImageQuality} from '../ImageTypes';

interface AboutImageProps extends BasicElement {
  image?: PublicImageDTO;
  isInfoOpened: boolean;
  onClosed: () => void;
}

const AboutImagePopup: React.FC<AboutImageProps> = ({
  image,
  onClosed,
  isInfoOpened,
  children,
}) => {
  const [openPopup, setOpenPopup] = useState(isInfoOpened);
  const deleteImageMutation = useDeleteImage();

  const changePopup = useCallback(
    (isOpened: boolean) => {
      setOpenPopup(isOpened);

      if (!isOpened) {
        onClosed();
      }
    },
    [onClosed],
  );

  const OnDeleteImage = useCallback(() => {
    if (!image) return;

    deleteImageMutation.mutate({id: image.id});
    changePopup(false);
  }, [changePopup, deleteImageMutation, image]);

  useEffect(() => {
    if (isInfoOpened) {
      changePopup(isInfoOpened);
    }
  }, [changePopup, isInfoOpened]);

  return (
    <Popup
      trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{padding: '15px', borderRadius: '0.375rem', width: '40%'}}
      modal
      nested
      disabled={!image}
      onClose={() => changePopup(false)}
      onOpen={() => changePopup(true)}
      open={openPopup}
    >
      {image && (
        <>
          <ServerImage
            imageId={image.id}
            quality={ImageQuality.MID}
            className="w-full h-96 object-contain"
            alt="Public image"
          />
          <div className="text-sm relative w-full">
            <table className="table-auto w-full text-center my-2">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Weight</th>
                  <th>Resolution</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{image.originalName}</td>
                  <td>
                    {new Date(
                      image.creationDateTime * 1000,
                    ).toLocaleDateString()}
                  </td>
                  <td>{image.originalWeight}</td>
                  <td>{`${image.originalWidth}x${image.originalHeight}`}</td>
                </tr>
              </tbody>
            </table>

            <div className="flex gap-3">
              <CopyToClipboard
                text={`${API_ROOT}/media/get?id=${image.id}`}
                onCopy={() => {
                  UniversalNotificationHandler(
                    'Copied',
                    'Link has been successfully copied to clipboard',
                    NotificationType.success,
                  );
                }}
              >
                <FormButton className="w-full">Copy link</FormButton>
              </CopyToClipboard>
              <CopyToClipboard
                text={image.id}
                onCopy={() => {
                  UniversalNotificationHandler(
                    'Copied',
                    'Id has been successfully copied to clipboard',
                    NotificationType.success,
                  );
                }}
              >
                <FormButton className="w-full">Copy ID</FormButton>
              </CopyToClipboard>
              <FormButton
                className="bg-red-200 hover:bg-red-400"
                onClick={() => OnDeleteImage()}
              >
                <BiTrashAlt />
              </FormButton>
            </div>
          </div>
        </>
      )}
    </Popup>
  );
};

export default AboutImagePopup;
