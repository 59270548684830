import classNames from 'classnames';
import {useCallback} from 'react';
import {BasicElement} from '../../../../helpers/BasicElement';
import {DefaultStyleAdjustments, ElementAlignment} from './CommonTypes';

interface BasicAdjustmentsContainerProps
  extends BasicElement,
    React.HTMLAttributes<HTMLDivElement> {
  data: DefaultStyleAdjustments;
  disableAutoBlockControl?: boolean;
}

const BasicAdjustmentsContainer: React.FC<BasicAdjustmentsContainerProps> = (
  {children, data, className, disableAutoBlockControl = false},
  ...rest
) => {
  const getBlockAlignment = useCallback(() => {
    switch (data.elementAlignment) {
      case ElementAlignment.CENTER:
        return 'mx-auto';
      case ElementAlignment.RIGHT:
        return 'ml-auto';
      case ElementAlignment.LEFT:
        return 'mr-auto';

      default:
        return '';
    }
  }, [data.elementAlignment]);

  return (
    <div
      className={classNames(
        className,
        !disableAutoBlockControl && 'block max-w-fit',
        getBlockAlignment(),
      )}
      style={{
        marginTop: data.marginTop,
        marginRight: data.marginRight,
        marginBottom: data.marginBottom,
        marginLeft: data.marginLeft,
        maxWidth: data.maxWidth,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default BasicAdjustmentsContainer;
