import classNames from 'classnames';
import React from 'react';
import './BlockTemplate.scoped.css';

type BlockTemplateProps = {
  label: string;
  image: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const BlockTemplate: React.FC<BlockTemplateProps> = ({
  label,
  image,
  style,
  ...rest
}) => (
  <div
    className={classNames(
      'rounded-lg bg-gray-200 w-full h-32 p-6 relative cursor-pointer text-gray-500 hover:bg-gray-300 hover:text-gray-600',
      style,
    )}
    {...rest}
  >
    <div className="w-full h-1/2 fill-current block-logo">{image}</div>
    <div
      className="h-10 mx-auto block text-center absolute bottom-2 left-0 w-full
    text-lg leading-5 text-current"
    >
      <span className="my-auto">{label}</span>
    </div>
  </div>
);
export default BlockTemplate;
