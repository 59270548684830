import {RefObject, useEffect} from 'react';

const useScript = (
  url: string,
  container: RefObject<HTMLElement>,
  attributes: {[id: string]: string} = {},
) => {
  useEffect(() => {
    console.log('Starting adding script');
    console.log(container);
    if (!container || !container.current) return;

    console.log('Adding script into page!');

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    for (const key in attributes) {
      const value = attributes[key];
      script.setAttribute(key, value);
    }

    container.current.prepend(script);

    return () => {
      console.log('Removing script from page');
      container?.current?.removeChild(script);
    };
  }, [attributes, container, url]);
};

export default useScript;
