import classNames from 'classnames';
import React, {useCallback, useEffect} from 'react';
import {AiOutlineMinus} from 'react-icons/ai';
import {allENAlphabet} from '../../../../../services/utils/alphabet';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {
  DataMatchingAnswersObj,
  EditorMatchingAnswerData,
} from './MatchingAnswerTypes';
import {v4 as uuidv4} from 'uuid';
import {useForm} from 'react-hook-form';
import HiddenSettings from '../../../common/HiddenSettings';
import Input from '../../../../form/input/Input';
import FormButton from '../../../../form/button/FormButton';

const MatchingAnswerEditorBlock: React.FC<
  EditorElementProps<EditorMatchingAnswerData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<EditorMatchingAnswerData>();

  const {
    register,
    setValue,
    watch,
    formState: {errors},
  } = useForm<EditorMatchingAnswerData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as EditorMatchingAnswerData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  const onClickCorrectAnswers = useCallback(
    (answer: DataMatchingAnswersObj, indexColumn: number) => {
      const newCorrectAnswers = [...originBlock.data.correctAnswersId];
      newCorrectAnswers[indexColumn] = answer.id;
      setValue('correctAnswersId', newCorrectAnswers);
    },
    [setValue, originBlock.data.correctAnswersId],
  );

  const addNewAnswer = useCallback(
    (isStaticColumn: boolean) => {
      const data = isStaticColumn
        ? originBlock.data.staticOptions
        : originBlock.data.answerOptions;

      if (isStaticColumn) {
        setValue('staticOptions', {
          ...data,
          answers: [
            ...data.answers,
            {
              answer: 'Answer',
              id: uuidv4(),
            },
          ],
        });
        setValue('correctAnswersId', [
          ...originBlock.data.correctAnswersId,
          '',
        ]);
      } else {
        setValue('answerOptions', {
          ...data,
          answers: [
            ...data.answers,
            {
              answer: 'Answer',
              id: uuidv4(),
            },
          ],
        });
      }
    },
    [
      originBlock.data.answerOptions,
      originBlock.data.correctAnswersId,
      originBlock.data.staticOptions,
      setValue,
    ],
  );

  // ф-ия вызывается когда мы удаляем вопрос
  const deleteAnswer = useCallback(
    (isStaticColumn: boolean, index: number) => {
      const data = isStaticColumn
        ? originBlock.data.staticOptions
        : originBlock.data.answerOptions;
      data.answers.splice(index, 1);

      if (isStaticColumn) {
        setValue('staticOptions', {
          ...data,
          answers: [...data.answers],
        });

        const correctAnswers = [...originBlock.data.correctAnswersId];
        correctAnswers.splice(index, 1);
        setValue('correctAnswersId', correctAnswers);
      } else {
        setValue('answerOptions', {
          ...data,
          answers: [...data.answers],
        });

        const correctAnswers = data.answers.map(() => '');
        setValue('correctAnswersId', correctAnswers);
      }
    },
    [
      originBlock.data.answerOptions,
      originBlock.data.correctAnswersId,
      originBlock.data.staticOptions,
      setValue,
    ],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="text-2xl p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <FormButton
            onClick={() => {
              setBlockMutation.mutate({
                ...originBlock,
              });
            }}
          >
            Refresh live view
          </FormButton>
        </div>
      </HiddenSettings>
      <div className="text-2xl flex justify-around max-w-7xl mx-auto">
        {/* {[originBlock.data.staticOptions, originBlock.data.answerOptions].map(
          (column, indexColumn) => {
            return ( */}
        <div className="p-2 w-full flex flex-col gap-2">
          <Input
            name={`staticOptions.title`}
            label="Title"
            className="w-full"
            registration={{register, errors}}
            type="text"
          />
          {originBlock.data.staticOptions.answers.map((answer, index) => {
            return (
              <div
                key={answer.id}
                className="text-lg flex justify-between items-center gap-2"
              >
                <div className="w-full">
                  <Input
                    name={`staticOptions.answers.${index}.answer`}
                    label={`${allENAlphabet[index]}) `}
                    className="w-full"
                    registration={{register, errors}}
                    type="text"
                  />
                </div>
                <div>
                  <AiOutlineMinus
                    className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                    onClick={() => deleteAnswer(true, index)}
                  />
                </div>
              </div>
            );
          })}
          <FormButton className="w-full" onClick={() => addNewAnswer(true)}>
            Add option
          </FormButton>
        </div>

        <div className="p-2 w-full flex flex-col gap-2">
          <Input
            name={`answerOptions.title`}
            label="Title"
            className="w-full"
            registration={{register, errors}}
            type="text"
          />
          {originBlock.data.answerOptions.answers.map((answer, index) => {
            return (
              <div
                key={answer.id}
                className="text-lg flex justify-between items-center gap-2"
              >
                <div className="w-full">
                  <Input
                    name={`answerOptions.answers.${index}.answer`}
                    label={`${index + 1}) `}
                    className="w-full"
                    registration={{register, errors}}
                    type="text"
                  />
                </div>
                <div>
                  <AiOutlineMinus
                    className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                    onClick={() => deleteAnswer(false, index)}
                  />
                </div>
              </div>
            );
          })}
          <FormButton className="w-full" onClick={() => addNewAnswer(false)}>
            Add answer
          </FormButton>
        </div>
      </div>
      <div className="text-2xl">
        <h2 className="my-2">Correct answers:</h2>
        <div className="flex gap-2 flex-col">
          {originBlock.data.staticOptions.answers.map(
            (answerLeftColumn, index) => {
              return (
                <div className="flex gap-2 text-lg" key={answerLeftColumn.id}>
                  {allENAlphabet[index]}:
                  {originBlock.data.answerOptions.answers.map(
                    (answerRightColumn, indexAnswer) => {
                      return (
                        <div
                          className={classNames(
                            'w-fit px-2 py-1 border border-solid border-blue-400 cursor-pointer',
                            originBlock?.data?.correctAnswersId[
                              index
                            ]?.includes(answerRightColumn.id)
                              ? 'bg-green-200'
                              : 'bg-red-200',
                          )}
                          key={indexAnswer}
                          onClick={() =>
                            onClickCorrectAnswers(answerRightColumn, index)
                          }
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                        >
                          {indexAnswer + 1}
                        </div>
                      );
                    },
                  )}
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchingAnswerEditorBlock;
