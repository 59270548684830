import classNames from 'classnames';
import React from 'react';
import './Button.scoped.css';

type ButtonProps = {
  isShadow?: boolean;
  icon?: React.ReactNode;
  size?: 'base' | 'lg' | 'xl' | 'custom';
  noRadius?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button: React.FC<ButtonProps> = ({
  className,
  type = 'button',
  children,
  icon,
  size = 'lg',
  noRadius = false,
  isShadow = false,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classNames(
        className,
        size === 'base' && 'text-base py-2',
        size === 'lg' && 'text-xl py-4',
        size !== 'custom' && 'px-10',
        'font-medium text-center no-underline button-shadow',
        !disabled &&
          (isShadow
            ? 'border border-black text-base hover:bg-gray-200'
            : 'bg-pink-500 text-white'),
        disabled && 'bg-gray-300 text-gray-600',
        !noRadius && 'rounded-xl',
      )}
      disabled={disabled}
      {...rest}
    >
      <div className="flex">
        {children && <div className="flex-grow">{children}</div>}
        {icon && <div className="mx-auto icon-container">{icon}</div>}
      </div>
    </button>
  );
};
export default Button;
