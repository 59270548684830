import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Button from '../../../../form/button/Button';
import Input from '../../../../form/input/Input';
import {ViewerElementProps} from '../../../types/EditorBlock';
import {AbstractFieldAnswerData} from './AbstractFieldAnswerTypes';

type SelectedAnswer = {selectedAnswer: string | number};

const AbstractFieldAnswerViewer: React.FC<
  ViewerElementProps<AbstractFieldAnswerData>
> = (originBlock) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<SelectedAnswer>();

  const [isAnswered, setIsAnswered] = useState(false);
  const [isAnswerCorrect, setIsCorrectAnswer] = useState(false);

  useEffect(() => {
    setIsAnswered(false);
  }, [originBlock.data]);

  const onSubmit = useCallback(
    (submitData: SelectedAnswer) => {
      if (isAnswered) return;

      setIsAnswered(true);
      let isLocalCorrectAnswer = false;

      if (typeof submitData.selectedAnswer === 'number') {
        isLocalCorrectAnswer =
          submitData.selectedAnswer == originBlock.data.correctAnswer.answer;
      } else {
        isLocalCorrectAnswer =
          submitData.selectedAnswer.trim() ==
          originBlock.data.correctAnswer.answer;
      }
      setIsCorrectAnswer(isLocalCorrectAnswer);
    },
    [isAnswered, originBlock.data.correctAnswer.answer],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="selectedAnswer"
        label="Ответ"
        className={classNames(
          originBlock.data.isFullWidthAnswer ? 'w-full' : 'w-fit',
        )}
        registration={{register, errors}}
        type={originBlock.data.correctAnswer.type}
        options={{
          required: 'Required',
          disabled: isAnswered,
        }}
      />

      <div className="mt-2">
        {!isAnswered && (
          <Button size="base" className="w-fit" type="submit">
            Answer
          </Button>
        )}
        {isAnswered && (
          <div className="flex items-center gap-3">
            <span
              className={classNames(
                'shrink p-2 text-white rounded-md',
                isAnswerCorrect ? 'bg-green-500  ' : 'bg-red-600',
              )}
            >
              {isAnswerCorrect ? 'Correct' : 'Wrong'}
            </span>
            {!isAnswerCorrect && (
              <div className="flex">
                <span className="mr-2">{`Correct answer is: `}</span>
                <div className="flex gap-1">
                  {originBlock.data.correctAnswer.answer}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default AbstractFieldAnswerViewer;
