import classNames from 'classnames';

interface FullBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  header: string;
  isDark?: boolean;
}

const FullBlock: React.FC<FullBlockProps> = ({
  header,
  isDark = false,
  children,
  className,
  ...rest
}) => (
  <section
    className={classNames(
      'flex flex-col lg:mx-0 py-16 description px-4 relative',
      isDark && 'catalog-blue text-white',
      className,
    )}
    {...rest}
  >
    <div className="max-w-screen-1120 w-full mx-auto">
      <h2
        className={classNames(
          'font-montserrat font-bold text-5xl',
          isDark ? 'text-white' : 'text-black',
        )}
      >
        {header}
        {isDark}
      </h2>
      <div className={classNames('w-full mt-5 mt-12', isDark && 'text-white')}>
        {children}
      </div>
    </div>
  </section>
);

export default FullBlock;
