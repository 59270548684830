import React, {useState, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineClose, AiOutlineLoading} from 'react-icons/ai';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {useAddNewImage} from '../../../../../api/media/Media';
import {BasicElement} from '../../../../../helpers/BasicElement';
import Button from '../../../../form/button/Button';
import FileInput from '../../../../form/fileInput/Fileinput';

interface ImageData {
  filesToUpload: File[];
}

interface UploadImagesPopupProps extends BasicElement {
  maxFiles?: number;
}

const UploadImagesPopup: React.FC<UploadImagesPopupProps> = ({
  maxFiles = 8,
  children,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadButton, setIsUploadButton] = useState(true); // параметр для контроля disabled на кнопке upload
  const {
    register,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<ImageData>();

  const uploadNewImageMutation = useAddNewImage();

  const onChangeFileInput = (value: File[]) => {
    setValue('filesToUpload', value);
    if (value.length === 0) {
      setIsUploadButton(true);
    } else {
      setIsUploadButton(false);
    }
  };

  const onClosePopup = () => {
    setOpenPopup(false);
    setIsUploadButton(true);
    setIsLoading(false);
  };

  const onUploadImage = useCallback(() => {
    const files = getValues().filesToUpload;
    setIsLoading(true);

    files.forEach((element) => {
      uploadNewImageMutation.mutate(
        {
          file: element,
          filename: element.name,
          isPublicRaw: true,
        },
        {
          onSuccess: () => onClosePopup(),
        },
      );
    });
  }, [getValues, uploadNewImageMutation]);

  return (
    <Popup
      trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{padding: '15px', borderRadius: '0.375rem', width: '40%'}}
      modal
      nested
      onClose={() => onClosePopup()}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
    >
      {isLoading ? (
        <AiOutlineLoading
          className="animate-spin block mx-auto my-10"
          size={50}
        />
      ) : (
        <>
          <div className="absolute right-1 top-1 cursor-pointer">
            <AiOutlineClose onClick={() => onClosePopup()} />
          </div>

          <div className="mt-6">
            <FileInput<ImageData>
              name="filesToUpload"
              registration={{register, errors}}
              onChange={(name, value) => onChangeFileInput(value)}
              options={{required: 'Загрузите файл'}}
              maxFiles={maxFiles}
            />

            <div className="flex justify-center">
              <Button
                className="rounded-md"
                disabled={isUploadButton}
                onClick={() => onUploadImage()}
                noRadius
              >
                Upload
              </Button>
            </div>
          </div>
        </>
      )}
    </Popup>
  );
};

export default UploadImagesPopup;
