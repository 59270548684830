import {EditorBlockData} from '../../types/EditorBlock';

export enum HeaderType {
  NORMAL,
  COURSEHEADER,
}

export interface EditorHeaderData extends EditorBlockData {
  text: string;
  style: HeaderType;
  level: number;
}
