import React from 'react';
import {BasicElement} from '../../../helpers/BasicElement';

export interface CoursesParagraphProps extends BasicElement {
  className?: string;
}

const CoursesParagraph: React.FC<CoursesParagraphProps> = ({
  children,
  className,
}) => {
  return <p className={`text-lg mb-4 ${className}`}>{children}</p>;
};

export default CoursesParagraph;
