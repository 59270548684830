import React, {useState, useCallback, useRef, useEffect} from 'react';
import {
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEdit,
} from 'react-icons/ai';
import {MdVisibility, MdVisibilityOff} from 'react-icons/md';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import classNames from 'classnames';
import {Camera, ClickEvent} from '@sketchfab/viewer-api';
import EditSketchfabAnnotationPopup from './EditSketchfabAnnotationPopup';
import AddSketchfabAnnotationPopup from './AddSketchfabAnnotationPopup';
import Button from '../../../form/button/Button';
import {SketchfabCustomAnnotationDTO} from '../../../../api/sketchfab/dto/SketchfabModelDTO';
import SketchfabViewerEditorVersion, {
  SketchfabViewerRefProps,
} from '../../../sketchfabViewer/SketchfabViewerEditorVersion';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../services/globalNotification/universalNotificationHandler';
import './SketchfabPopup.scoped.css';
import {
  SketchfabModelAnimationData,
  SketchfabModelData,
} from '../../elements/sketchfab/SketchfabType';
import {BasicElement} from '../../../../helpers/BasicElement';
import _ from 'lodash';

interface SketchfabPopupProps extends BasicElement {
  fullData: SketchfabModelData;
  setData: (data: SketchfabModelData) => void;
}

const SketchfabPopupEditorVersion: React.FC<SketchfabPopupProps> = ({
  fullData,
  setData,
  children,
}) => {
  const sketchfabViewerRef = useRef<SketchfabViewerRefProps>(null);

  const [openPopup, setOpenPopup] = useState(false);

  const [modelData, setModelData] = useState<SketchfabModelData>(
    _.cloneDeep(fullData),
  );

  useEffect(() => {
    setModelData(_.cloneDeep(fullData));
  }, [fullData]);

  // номер текущей выбранной аннотации, когда мы кликаем в списке всех аннотаций то переключаем камеру на текущую, вот зачем этот параметр
  const [currentAnnotation, setCurrentAnnotation] = useState<number>(-1);

  // isAddCustomAnnotationMode - включен или выключен режим добавления новой кастомной аннотации, если выключен, то при клике на 3д-модель не появляется всплывашка с добавлением аннотации, включается по кнопке "add annotation". Когда жмем на 3д-модель и получаем координаты для аннотации (из параметра camera), далее откроется попап с возможностью настроить заголовок с описанием и сохранить новую аннотацию.
  const [isAddCustomAnnotationMode, setModeAddCustomAnnotation] =
    useState(false);

  // isOpenNewCustomAnnotation отвечает открыта ли всплывашка с добавлением новой кастомной аннотации. Переходит в положение "открыто" только если включен режим добавления (modeAddCustomAnnotation)
  const [isOpenNewCustomAnnotation, setIsOpenNewCustomAnnotation] =
    useState(false);

  // тут храним временные данные для добавления аннотации, обновляется при клике по 3д-модели, если включен режим добавления аннотаций
  const [tempNewAnnotationData, setTempNewAnnotationData] = useState<{
    cameraData: Camera;
    clickInfo: ClickEvent;
  }>();

  // действия при закрытии попапа
  const onClosePopup = useCallback(() => {
    setOpenPopup(false);
    setModeAddCustomAnnotation(false);
    setIsOpenNewCustomAnnotation(false);
  }, []);

  // меняем заголовки и описания у аннотаций
  const setAnnotationTitleDescription = useCallback(
    (header: string, description: string, index: number) => {
      setModelData((old) => {
        const data = {...old};
        // копируем аннотации сами в себя, чтобы изменилась ссылка на массив аннотаций и дальше во вьювере триггернулся useEffect, который обновляет описание аннотаций
        data.translation.annotations = [...old.translation.annotations];
        data.translation.annotations[index].label = header;
        data.translation.annotations[index].body = description;
        return data;
      });
    },
    [],
  );

  // меняем заголовки и описания у кастомных аннотаций
  const setCustomAnnotationTitleDescription = useCallback(
    (header: string, description: string, index: number) => {
      setModelData((old) => {
        const data = {...old};
        // копируем кастомные аннотации сами в себя, чтобы изменилась ссылка на массив кастомных аннотаций и дальше во вьювере триггернулся useEffect, который обновляет описание кастомных аннотаций
        data.translation.customAnnotations = [
          ...old.translation.customAnnotations,
        ];
        data.translation.customAnnotations[index].label = header;
        data.translation.customAnnotations[index].body = description;
        return data;
      });
    },
    [],
  );

  // каждая аннотация имеет параметр показывать или нет (isShown) на 3д-модели. Нажимаем в списке на глазик и выключаем/включаем отображение
  const changeAnnotationVision = useCallback((index: number) => {
    setModelData((old) => {
      const data = {...old};
      data.translation.annotations[index].isShown =
        !data.translation.annotations[index].isShown;
      return data;
    });
  }, []);

  // удаляем кастомную аннотацию
  const onDeleteCustomAnnotation = useCallback((index: number) => {
    setModelData((old) => {
      const customAnnotations = old.translation.customAnnotations;
      customAnnotations.splice(index, 1);

      for (let i = 0; i < customAnnotations.length; ++i) {
        if (i < index) continue;
        customAnnotations[i].index -= 1;
      }

      return {
        ...old,
        translation: {
          ...old.translation,
          customAnnotations,
        },
      };
    });
    // const newData: SketchfabModelData = {...fullData};
    // // сохраняем в deleted удаленную кастомную аннотацию
    // const deleted = newData.translation.customAnnotations.splice(index, 1);

    // // все кастомные аннотации, у которых index был больше удаленного, уменьшаем на 1
    // const newCustomAnnotation = newData.translation.customAnnotations.map(
    //   (cust) => {
    //     if (cust.index > deleted[0].index)
    //       return {...cust, index: cust.index - 1};
    //     return {...cust};
    //   },
    // );
    // // присваиваем новые кастомные аннотации (с уменьшенным индексом) вместо старых
    // newData.translation.customAnnotations = newCustomAnnotation;

    // setFullData(newData);
  }, []);

  // функция вызываемая при клике по 3д-модели, если режим modeAddCustomAnnotation включен, то при клике откроется всплывашка с добавлением новой кастомной аннотации
  const onModelClick = useCallback(
    (cameraData: Camera, clickInfo: ClickEvent) => {
      if (isAddCustomAnnotationMode) {
        setTempNewAnnotationData({cameraData, clickInfo});
        setIsOpenNewCustomAnnotation(true);
      }
    },
    [isAddCustomAnnotationMode],
  );

  const CreateCustomAnnotation = useCallback(
    (header: string, description: string) => {
      if (!tempNewAnnotationData || header === '') {
        UniversalNotificationHandler(
          'Empty fields',
          'Fill all fields',
          NotificationType.warning,
        );
        return;
      }

      setModelData((old) => {
        const newData = {...old};
        newData.translation.customAnnotations.push({
          index:
            old.translation.annotations.length +
            old.translation.customAnnotations.length,
          label: header,
          body: description || '',
          position3D: tempNewAnnotationData.clickInfo.position3D || [0, 0, 0],
          cameraPosition: tempNewAnnotationData.cameraData.position,
          cameraTarget: tempNewAnnotationData.cameraData.target,
        });

        return newData;
      });

      setIsOpenNewCustomAnnotation(false);
      setModeAddCustomAnnotation(false);

      // const newData: SketchfabModelData = {...fullData};

      // newData.translation.customAnnotations.push({
      //   index:
      //     fullData.translation.annotations.length +
      //     fullData.translation.customAnnotations.length,
      //   label: header,
      //   body: description,
      //   position3D: tempNewAnnotationData.clickInfo.position3D || [0, 0, 0],
      //   cameraPosition: tempNewAnnotationData.cameraData.position,
      //   cameraTarget: tempNewAnnotationData.cameraData.target,
      // });

      // setFullData(newData);
      // setIsOpenNewCustomAnnotation(false);
      // setModeAddCustomAnnotation(false);
    },
    [tempNewAnnotationData],
  );

  return (
    <div className="flex justify-center">
      <Popup
        trigger={<div className="flex justify-center">{children}</div>}
        contentStyle={{
          padding: '15px',
          borderRadius: '0.375rem',
          overflowY: 'auto',
          maxHeight: '95%',
          width: 'auto',
          minWidth: '80%',
          maxWidth: '80%',
        }}
        modal
        nested
        onClose={() => onClosePopup()}
        onOpen={() => setOpenPopup(true)}
        open={openPopup}
      >
        <div className="absolute right-1 top-1 cursor-pointer">
          <AiOutlineClose onClick={() => onClosePopup()} />
        </div>

        <div className="mt-6 flex gap-2 flex-col-reverse xl:flex-row">
          <div className="">
            <div
              className={classNames(
                'mt-4 relative flex items-center cursor-pointer hover:bg-gray-100 w-fit rounded-lg px-2',
                !modelData.autoplay ? 'text-red-500' : 'text-green-500',
              )}
              // onClick={() => changeAutoPlay()}
              onClick={() => {
                setModelData((val) => ({
                  ...val,
                  autoplay: !val.autoplay,
                }));
              }}
              role="button"
              tabIndex={0}
            >
              <AiOutlineCheckCircle className={classNames('absolute')} />
              <span className="ml-5">
                Autoplay {modelData.autoplay ? 'enabled' : 'disabled'}
              </span>
            </div>
            <div
              className={classNames(
                'mt-4 relative flex items-center cursor-pointer hover:bg-gray-100 w-fit rounded-lg px-2',
                !modelData.isShowBackground ? 'text-red-500' : 'text-green-500',
              )}
              onClick={() => {
                setModelData((val) => ({
                  ...val,
                  isShowBackground: !val.isShowBackground,
                }));
              }}
              role="button"
              tabIndex={0}
            >
              <AiOutlineCheckCircle className={classNames('absolute')} />
              <span className="ml-5">
                Background:{' '}
                {modelData.isShowBackground ? 'enabled' : 'disabled'}
              </span>
            </div>
            <h3 className="text-xl mt-2 whitespace-nowrap">Annotations:</h3>
            <ul className="mt-2">
              {modelData.translation.annotations.map((annotation, index) => (
                <li
                  key={annotation.index}
                  className={classNames(
                    'cursor-pointer hover:bg-gray-100 px-1 py-1 rounded-lg flex justify-between items-center',
                    currentAnnotation === annotation.index && 'bg-red-200',
                  )}
                  onClick={() =>
                    setCurrentAnnotation((old) => {
                      if (annotation.index === old) {
                        setModelData((oldData) => ({
                          ...oldData,
                          slectedAnnotation: undefined,
                        }));
                        return -1;
                      }
                      setModelData((oldData) => ({
                        ...oldData,
                        slectedAnnotation: annotation.index,
                      }));
                      return annotation.index;
                    })
                  }
                  aria-hidden="true"
                >
                  {annotation.label}
                  <div className="flex justify-between gap-1">
                    <EditSketchfabAnnotationPopup
                      header={annotation.label}
                      description={annotation.body || ''}
                      setData={setAnnotationTitleDescription}
                      index={index}
                    >
                      <AiOutlineEdit className="cursor-pointer hover:text-blue-500" />
                    </EditSketchfabAnnotationPopup>
                    {annotation.isShown ? (
                      <MdVisibility
                        className="cursor-pointer text-green-500 hover:text-blue-500"
                        onClick={() => changeAnnotationVision(annotation.index)}
                      />
                    ) : (
                      <MdVisibilityOff
                        className="cursor-pointer text-red-500 hover:text-blue-500"
                        onClick={() => changeAnnotationVision(annotation.index)}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <h3 className="text-xl mt-2 whitespace-nowrap">
              Custom annotations:
            </h3>
            <ul className="mt-2">
              {modelData.translation.customAnnotations.map(
                (
                  customAnnotation: SketchfabCustomAnnotationDTO,
                  index: number,
                ) => {
                  return (
                    <li
                      key={customAnnotation.index}
                      className="cursor-pointer hover:bg-gray-100 px-1 py-1 rounded-lg flex justify-between items-center"
                      onClick={() =>
                        setCurrentAnnotation(customAnnotation.index)
                      }
                    >
                      {customAnnotation.label}
                      <div className="flex justify-between gap-1">
                        <EditSketchfabAnnotationPopup
                          header={customAnnotation.label}
                          description={customAnnotation.body}
                          setData={setCustomAnnotationTitleDescription}
                          index={index}
                        >
                          <AiOutlineEdit className="cursor-pointer hover:text-blue-500" />
                        </EditSketchfabAnnotationPopup>
                        <AiOutlineDelete
                          className="cursor-pointer hover:text-blue-500"
                          onClick={() => onDeleteCustomAnnotation(index)}
                        />
                      </div>
                    </li>
                  );
                },
              )}
            </ul>
            <div className="flex justify-center">
              <Button
                size="base"
                className={classNames(
                  'rounded-md mt-2 px-5 py-2 whitespace-nowrap add-annotation z-20 relative',
                  isAddCustomAnnotationMode
                    ? 'animate-pulse bg-green-500'
                    : 'bg-pink-500',
                )}
                onClick={() =>
                  setModeAddCustomAnnotation(!isAddCustomAnnotationMode)
                }
                noRadius
              >
                {!isAddCustomAnnotationMode
                  ? 'Add annotaion mode'
                  : 'Choose position'}
              </Button>
            </div>
            <AddSketchfabAnnotationPopup
              isOpen={isOpenNewCustomAnnotation}
              setIsOpen={setIsOpenNewCustomAnnotation}
              onSave={CreateCustomAnnotation}
            />
            <h3 className="text-xl mt-4">Animations:</h3>
            <ul className="mt-2">
              {modelData.animations.map(
                (animation: SketchfabModelAnimationData) => {
                  return (
                    <li
                      key={animation.animationId}
                      className="cursor-pointer hover:bg-gray-100 px-1 py-1 rounded-lg flex justify-between items-center"
                      onClick={() => {
                        setModelData((val) => ({
                          ...val,
                          defaultAnimation: animation.animationId,
                        }));
                      }}
                      aria-hidden="true"
                    >
                      {animation.translation.title}
                      <div
                        className={classNames(
                          'p-1 text-xs border cursor-pointer hover:text-blue-500',
                          animation.animationId ===
                            modelData.defaultAnimation &&
                            'bg-gray-200 font-semibold',
                        )}
                      >
                        Default
                      </div>
                    </li>
                  );
                },
              )}
            </ul>
            {isAddCustomAnnotationMode && (
              <div
                className="fixed top-0 right-0 left-0 bottom-0 bg-black opacity-80 z-10"
                onClick={() => setModeAddCustomAnnotation(false)}
              />
            )}
          </div>

          <div className="w-full relative h-fit">
            {/* {isAddCustomAnnotationMode && (
              <>
                <div className="absolute text-white z-20 -top-4 flex justify-around w-full animate-bounce">
                  <AiOutlineArrowDown />
                  <AiOutlineArrowDown />
                  <AiOutlineArrowDown />
                </div>
                <div className="absolute text-white z-20 -left-4 flex flex-col justify-around h-full animate-bounce-left">
                  <AiOutlineArrowRight />
                  <AiOutlineArrowRight />
                  <AiOutlineArrowRight />
                </div>
                <div className="absolute text-white z-20 -right-4 flex flex-col justify-around h-full animate-bounce-right">
                  <AiOutlineArrowLeft />
                  <AiOutlineArrowLeft />
                  <AiOutlineArrowLeft />
                </div>
                <div className="absolute text-white z-20 -bottom-4 flex justify-around w-full animate-bounce-up">
                  <AiOutlineArrowUp />
                  <AiOutlineArrowUp />
                  <AiOutlineArrowUp />
                </div>
              </>
            )} */}
            <SketchfabViewerEditorVersion
              ref={sketchfabViewerRef}
              autostart={modelData.autoplay}
              className={classNames(
                'w-full z-20 relative bg-white',
                isAddCustomAnnotationMode &&
                  'border-2 border-pink-500 rounded-md',
              )}
              modelId={modelData.modelId}
              gotoAnnotation={currentAnnotation}
              annotations={modelData.translation.annotations}
              customAnnotations={modelData.translation.customAnnotations}
              showOnlyAnnotations={modelData.translation.annotations
                .filter((x) => x.isShown)
                .map((x) => x.index)}
              onModelClicked={onModelClick}
              currentAnimationUID={modelData.defaultAnimation}
              isShowBackground={modelData.isShowBackground}
            />
            <div className="flex gap-2 mt-2">
              <Button
                size="base"
                className="grow rounded-md"
                onClick={() => {
                  sketchfabViewerRef.current
                    ?.getCurrentViewPoint()
                    .then((val) => {
                      setModelData((old) => ({
                        ...old,
                        cameraPosition: val,
                      }));
                    });
                }}
              >
                Set current position as base
              </Button>
              <Button
                size="base"
                className="rounded-md"
                onClick={() => {
                  setModelData((old) => ({
                    ...old,
                    cameraPosition: undefined,
                  }));
                }}
              >
                Reset position
              </Button>
            </div>
          </div>
        </div>
        <Button
          size="base"
          className="w-full mt-3"
          onClick={() => {
            setData(modelData);
            setOpenPopup(false);
          }}
        >
          Save
        </Button>
      </Popup>
    </div>
  );
};

export default SketchfabPopupEditorVersion;
