import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Input from '../../../form/input/Input';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import Toggle from '../../../form/switch/Toggle';
import HiddenSettings from '../../common/HiddenSettings';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import {ButtonEditorStyleType, EditorButtonData} from './ButtonTypes';

const buttonStyleType: ListBoxOption<ButtonEditorStyleType>[] = [
  {label: 'Filled', value: ButtonEditorStyleType.FILLED},
  {label: 'Outline', value: ButtonEditorStyleType.OUTLINE},
];

const ButtonEditorBlock: React.FC<EditorElementProps<EditorButtonData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorButtonData>();

  const {
    register,
    getValues,
    setValue,
    formState: {errors},
    watch,
  } = useForm<EditorButtonData>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  return (
    <div className="p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <div>
            <ListBox
              name="buttonStyle"
              options={buttonStyleType}
              label="Button style"
              setValue={setValue}
              getValues={getValues}
            />
          </div>
        </div>
      </HiddenSettings>

      <div className="grid gap-2 w-full mt-3">
        <Input label="Label" name="label" registration={{register, errors}} />
        <div className="flex gap-2">
          <Input
            label="Link"
            name="href"
            registration={{register, errors}}
            className="flex-grow"
          />
          <Toggle
            className="mt-2"
            name="openInNewTab"
            label="Open in new tab"
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      </div>
    </div>
  );
};

export default ButtonEditorBlock;
