import {BasicElement} from '../../../helpers/BasicElement';

export interface HowItWorksElementProps extends BasicElement {
  number: string;
  title?: string;
}

const HowItWorksElement: React.FC<HowItWorksElementProps> = ({
  number,
  title,
  children,
}) => (
  <div className="w-full pr-4 font-montserrat">
    <p className="text-3xl font-bold">{number}</p>
    {title && <h4 className="font-bold text-md lg:text-lg mb-2">{title}</h4>}
    <div className="lg:mt-4 text-md lg:text-lg">{children}</div>
  </div>
);

export default HowItWorksElement;
