export function moveItemInArray<T>(data: T[], from: number, to: number): T[] {
  const f = data.splice(from, 1)[0];
  data.splice(to, 0, f);
  return data;
}

export function swapElementsByIndex<T>(data: T[], a: number, b: number): T[] {
  if (a < 0 || a >= data.length || b < 0 || b >= data.length) {
    return data;
  }

  const temp = data[a];
  data[a] = data[b];
  data[b] = temp;
  return data;
}

export function deleteElementByIndex<T>(data: T[], index: number): T[] {
  if (index > -1 && index < data.length) {
    data.splice(index, 1);
  }

  return data;
}

export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
