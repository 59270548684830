export interface CourseDTO {
  id: string;
  section: CourseSection;
  subject: CourseSubject;
  thumbnailImageId: string;
  backgroundImageId: string;
  duration: number;
  urlName: string;

  translation: CourseTranslationDTO;
}

export interface CourseTranslationDTO {
  languageId: string;
  name: string;
  description: string;
  bodyId: string;
  body: string;
}

export enum CourseSection {
  VR = 'VR',
  AR = 'AR',
}

export enum CourseSubject {
  Biology = 'Biology',
  Chemistry = 'Chemistry',
  Physics = 'Physics',
  Math = 'Math',
}
