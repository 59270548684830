import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {queryClient} from '../../app/App';
import {UseShortMutationOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {CreatedDTO} from '../basicTypes/Created';
import {
  GetCourseKey,
  GetCoursesKey,
  GetFullCourseKey,
  GetRandomCoursesKey,
} from './Courses';
import {AddCourseDAO} from './dao/CreateCourseRequestDAO';

const AddNewCourseKey = 'course/add';
const DeleteCourseKey = 'course/delete';
const EditCourseKey = 'course/edit';

export const useAddNewCourse = (
  options?: UseShortMutationOptions<
    CreatedDTO,
    AxiosError<ApiError>,
    AddCourseDAO
  >,
) => {
  return useMutation<CreatedDTO, AxiosError<ApiError>, AddCourseDAO>(
    [AddNewCourseKey],
    async (newSimulation: AddCourseDAO): Promise<CreatedDTO> => {
      const {data} = await APIRequest.post<CreatedDTO>(
        `/Course/CreateCourse`,
        newSimulation,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GetCoursesKey]);
        queryClient.invalidateQueries([GetRandomCoursesKey]);
      },
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useDeleteCourse = (
  options?: UseShortMutationOptions<CreatedDTO, AxiosError<ApiError>, string>,
) => {
  return useMutation<CreatedDTO, AxiosError<ApiError>, string>(
    [DeleteCourseKey],
    async (id: string): Promise<CreatedDTO> => {
      const {data} = await APIRequest.post<CreatedDTO>(
        `/Course/DeleteCourse?id=${id}`,
      );
      return data;
    },
    {
      onSuccess: (response, request) => {
        queryClient.invalidateQueries([GetCoursesKey]);
        queryClient.invalidateQueries([GetRandomCoursesKey]);
        queryClient.invalidateQueries([GetCourseKey, {id: request}]);
        queryClient.invalidateQueries([GetFullCourseKey, {id: request}]);
      },
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useEditCourse = (
  id: string,
  options?: UseShortMutationOptions<
    string,
    AxiosError<ApiError>,
    AddCourseDAO,
    string
  >,
) => {
  return useMutation<string, AxiosError<ApiError>, AddCourseDAO, string>(
    [EditCourseKey],
    async (EditCourse: AddCourseDAO): Promise<string> => {
      const {data} = await APIRequest.post<string>(
        `/Course/UpdateCourse?id=${id}`,
        EditCourse,
      );
      return data;
    },
    {
      onSuccess: (response, request) => {
        queryClient.invalidateQueries([GetCoursesKey]);
        queryClient.invalidateQueries([GetRandomCoursesKey]);
        queryClient.invalidateQueries([GetCourseKey, {id: request}]);
        queryClient.invalidateQueries([GetFullCourseKey, {id: request}]);
      },
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
