import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import FooterCOM from '../../../components/footer/Footer.com';
import HeaderEducators from '../../../components/header/Header.educators';

const EducatorsPageLayout: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-between h-screen">
      <HeaderEducators />
      <div className="w-full pt-16 font-montserrat mb-auto">
        <Outlet />
      </div>
      <FooterCOM />
    </div>
  );
};

export default EducatorsPageLayout;
