import React from 'react';
import classNames from 'classnames';

import {ViewerElementProps} from '../../types/EditorBlock';
import ViewerAutoblock from '../../service/autoBlocks/ViewerAutoblock';
import {BreakOnSize, EditorGridViewerData} from './GridType';

const GridViewer: React.FC<ViewerElementProps<EditorGridViewerData>> = (
  originBlock,
) => (
  <div
    className={classNames(
      'flex',
      originBlock.data.breakOnSize == BreakOnSize.NEVER && 'flex',
      originBlock.data.breakOnSize == BreakOnSize.TABLET &&
        'flex-col lg:flex-row',
      originBlock.data.breakOnSize == BreakOnSize.MOBILE &&
        'flex-col md:flex-row',
    )}
    style={{
      padding: originBlock.data.padding,
      color: originBlock.data.theme.color,
      background: originBlock.data.theme.background,
      gap: originBlock.data.gap,
    }}
  >
    {originBlock.data.columns.map((element, index) => (
      <div
        className="flex-1 flex flex-col"
        key={index}
        style={{
          order: element.order,
          gap: originBlock.data.verticalGap || '1rem',
        }}
      >
        <ViewerAutoblock
          blocks={element.inner}
          isUseLocalVersion={originBlock.isUseSharedLiveVersion}
          isUseLiveVersion={originBlock.isUseMutableAutoblock}
        />
      </div>
    ))}
  </div>
);

export default GridViewer;
