import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {LocalizationDTO} from './dto/LocalizationDTO';

const GetAllLanguagesKey = 'localization/languages';

export function useGetAllLanguages(
  options?: UseShortQueryOptions<LocalizationDTO[], AxiosError<ApiError>>,
): UseQueryResult<LocalizationDTO[], AxiosError<ApiError>> {
  return useQuery(
    [GetAllLanguagesKey],
    async (): Promise<LocalizationDTO[]> => {
      const {data} = await APIRequest.get<LocalizationDTO[]>(
        `/Localisation/GetAllLanguages`,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
