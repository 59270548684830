import {
  US,
  AE,
  BH,
  BD,
  CN,
  HK,
  IN,
  ID,
  IL,
  JP,
  KR,
  MY,
  PK,
  PH,
  SA,
  SG,
  TW,
  TH,
  TR,
  VN,
  AT,
  BE,
  BG,
  HR,
  CY,
  CZ,
  DK,
  EE,
  FI,
  FR,
  DE,
  GR,
  HU,
  IE,
  IT,
  LV,
  LT,
  LU,
  MT,
  NL,
  NO,
  PL,
  PT,
  RO,
  SK,
  SI,
  ES,
  SE,
  CH,
  GB,
  CA,
  MX,
  AR,
  BO,
  BR,
  CL,
  CO,
  PE,
} from 'country-flag-icons/react/3x2';

export const allFlags = [
  // {
  //   region: 'Africa',
  //   countries: [
  //     {name: 'Algeria', component: AL, shortName: 'AL'},
  //     {name: 'Morocco', component: MA, shortName: 'MA'},
  //     {name: 'South Africa', component: ZA, shortName: 'ZA'},
  //   ],
  // },
  {
    region: 'Popular',
    countries: [
      {name: 'USA', component: US, shortName: 'US'},
      {name: 'United Arab Emirates', component: AE, shortName: 'AE'},
    ],
  },
  {
    region: 'Europe',
    countries: [
      {name: 'Austria', component: AT, shortName: 'AT'},
      {name: 'Belgium', component: BE, shortName: 'BE'},
      {name: 'Bulgaria', component: BG, shortName: 'BG'},
      {name: 'Croatia', component: HR, shortName: 'HR'},
      {name: 'Cyprus', component: CY, shortName: 'CY'},
      {name: 'Czechia', component: CZ, shortName: 'CZ'},
      {name: 'Denmark', component: DK, shortName: 'DK'},
      {name: 'Estonia', component: EE, shortName: 'EE'},
      {name: 'Finland', component: FI, shortName: 'FI'},
      {name: 'France', component: FR, shortName: 'FR'},
      {name: 'Germany', component: DE, shortName: 'DE'},
      {name: 'Greece', component: GR, shortName: 'GR'},
      {name: 'Hungary', component: HU, shortName: 'HU'},
      {name: 'Ireland', component: IE, shortName: 'IE'},
      {name: 'Italy', component: IT, shortName: 'IT'},
      {name: 'Latvia', component: LV, shortName: 'LV'},
      {name: 'Lithuania', component: LT, shortName: 'LT'},
      {name: 'Luxembourg', component: LU, shortName: 'LU'},
      {name: 'Malta', component: MT, shortName: 'MT'},
      {name: 'Netherlands', component: NL, shortName: 'NL'},
      {name: 'Norway', component: NO, shortName: 'NO'},
      {name: 'Poland', component: PL, shortName: 'PL'},
      {name: 'Portugal', component: PT, shortName: 'PT'},
      {name: 'Romania', component: RO, shortName: 'RO'},
      {name: 'Slovakia', component: SK, shortName: 'SK'},
      {name: 'Slovenia', component: SI, shortName: 'SI'},
      {name: 'Spain', component: ES, shortName: 'ES'},
      {name: 'Sweden', component: SE, shortName: 'SE'},
      {name: 'Switzerland', component: CH, shortName: 'CH'},
      {name: 'United Kingdom', component: GB, shortName: 'GB'},
    ],
  },
  {
    region: 'Asia',
    countries: [
      {name: 'United Arab Emirates', component: AE, shortName: 'AE'},
      {name: 'Bahrain', component: BH, shortName: 'BH'},
      {name: 'Bangladesh', component: BD, shortName: 'BD'},
      {name: 'China', component: CN, shortName: 'CN'},
      {name: 'Hong Kong, SAR', component: HK, shortName: 'HK'},
      {name: 'India', component: IN, shortName: 'IN'},
      {name: 'Indonesia', component: ID, shortName: 'ID'},
      {name: 'Israel', component: IL, shortName: 'IL'},
      {name: 'Japan', component: JP, shortName: 'JP'},
      {name: 'Korea', component: KR, shortName: 'KR'},
      {name: 'Malaysia', component: MY, shortName: 'MY'},
      {name: 'Pakistan', component: PK, shortName: 'PK'},
      {name: 'Philippines', component: PH, shortName: 'PH'},
      {name: 'Saudi Arabia', component: SA, shortName: 'SA'},
      {name: 'Singapore', component: SG, shortName: 'SG'},
      {name: 'Taiwan', component: TW, shortName: 'TW'},
      {name: 'Thailand', component: TH, shortName: 'TH'},
      {name: 'Turkey', component: TR, shortName: 'TR'},
      {name: 'Vietnam', component: VN, shortName: 'VN'},
    ],
  },
  {
    region: 'North America',
    countries: [
      {name: 'Canada', component: CA, shortName: 'CA'},
      {name: 'Mexico', component: MX, shortName: 'MX'},
      {name: 'USA', component: US, shortName: 'US'},
    ],
  },
  // {
  //   region: 'Oceania',
  //   countries: [
  //     {name: 'Australia', component: AU, shortName: 'AU'},
  //     {name: 'New Zealand', component: NZ, shortName: 'NZ'},
  //   ],
  // },
  {
    region: 'South America',
    countries: [
      {name: 'Argentina', component: AR, shortName: 'AR'},
      {name: 'Bolivia', component: BO, shortName: 'BO'},
      {name: 'Brazil', component: BR, shortName: 'BR'},
      {name: 'Chile', component: CL, shortName: 'CL'},
      {name: 'Colombia', component: CO, shortName: 'CO'},
      {name: 'Peru', component: PE, shortName: 'PE'},
    ],
  },
];
