import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import FooterCOM from '../../../components/footer/Footer.com';
import FooterRU from '../../../components/footer/Footer.ru';
import HeaderCOM from '../../../components/header/Header.com';
import HeaderRU from '../../../components/header/Header.ru';
import {useLocalization} from '../../../services/flagProvider/FlagProvider';

const MainPageLayout: React.FC = () => {
  const localization = useLocalization();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-between h-screen">
      {localization.getCurrentLocale() === 'ru' ? <HeaderRU /> : <HeaderCOM />}
      <div className="w-full pt-16 font-montserrat mb-auto">
        <Outlet />
      </div>
      {localization.getCurrentLocale() === 'ru' ? <FooterRU /> : <FooterCOM />}
    </div>
  );
};

export default MainPageLayout;
