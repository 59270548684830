import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useGetAdminCourse} from '../../../../api/courses/Courses';
import {queryClient} from '../../../../app/App';
import CourseEditor from '../../../../components/admin/adminCourses/courseEditor/CourseEditor';
import LoadingEditorBlock from '../../../../components/editor/elements/common/LoadingBlock';
import {editorBlockKey} from '../../../../components/editor/types/UseEditor';

const EditCourse: React.FC = () => {
  const {courseId} = useParams<{courseId: string}>();
  const fullBlock = useGetAdminCourse(courseId || '');

  useEffect(() => {
    queryClient.removeQueries([editorBlockKey]);
  }, []);

  if (!fullBlock.isSuccess || !courseId) return <LoadingEditorBlock />;
  return <CourseEditor courseData={fullBlock.data} courseId={courseId} />;
};

export default EditCourse;
