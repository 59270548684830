import React from 'react';
import {API_REGION} from '../../../services/flagProvider/FlagProvider';

const DnaAppPolicy: React.FC = () => (
  <>
    <strong>Privacy Policy</strong>{' '}
    <p>
      Xready Lab Corp. built the VR Biology Nucleotides Factory - virtual
      reality app as a Free app. This APPLICATION is provided by Xready Lab
      Corp. at no cost and is intended for use as is.
    </p>{' '}
    <p>
      This page is used to inform visitors regarding our policies with the
      collection, use, and disclosure of Personal Information if anyone decided
      to use our App.
    </p>{' '}
    <p>
      If you choose to use our App, then you agree to the collection and use of
      information in relation to this policy. The Personal Information that we
      collect is used for providing and improving the App. We will not use or
      share your information with anyone except as described in this Privacy
      Policy.
    </p>{' '}
    <p>
      The terms used in this Privacy Policy have the same meanings as in our
      Terms and Conditions, which is accessible at VR Biology Nucleotides
      Factory - virtual reality app unless otherwise defined in this Privacy
      Policy.
    </p>{' '}
    <p>
      While using our App, Personally Identifiable Information does not
      collected. The information that we request, for example log files when
      user run our application, will be retained by us and used as described in
      this privacy policy.
    </p>{' '}
    <p>
      The app does use third party services that may collect information used to
      identify you.
    </p>{' '}
    <p>
      <a
        href="https://www.oculus.com/legal/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500"
      >
        Oculus.com{' '}
      </a>
    </p>{' '}
    <p>
      We want to inform you that whenever you use our App, in a case of an error
      in the app we collect data and information (through third party products)
      on your device called Log Data. This Log Data may include information such
      as your device Internet Protocol (“IP”) address, device name, operating
      system version, the configuration of the app when utilizing our App, the
      time and date of your use of the App, and other statistics. For the
      purpose of our custom licensing system we collect your device unique
      identifier based on MAC-adress of your device. We don&apos;t collect
      MAC-address by itself, only hashed version to identify your device and
      licenses given to device - application pair.
    </p>{' '}
    <p>
      Cookies are files with a small amount of data that are commonly used as
      anonymous unique identifiers. These are sent to your browser from the
      websites that you visit and are stored on your device&apos;s internal
      memory.
    </p>{' '}
    <p>
      This App does not use these “cookies” explicitly. However, the app may use
      third party code and libraries that use “cookies” to collect information
      and improve their services. You have the option to either accept or refuse
      these cookies and know when a cookie is being sent to your device. If you
      choose to refuse our cookies, you may not be able to use some portions of
      this App.
    </p>{' '}
    <p>
      We may employ third-party companies and individuals due to the following
      reasons:
    </p>{' '}
    <p>
      We want to inform users of this Service that these third parties have
      access to your Personal Information. The reason is to perform the tasks
      assigned to them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
    </p>{' '}
    <p>
      We value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it. But
      remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and we cannot guarantee
      its absolute security.
    </p>{' '}
    <p>
      This Service may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by us. Therefore, we strongly advise you
      to review the Privacy Policy of these websites. We have no control over
      and assume no responsibility for the content, privacy policies, or
      practices of any third-party sites or services.
    </p>{' '}
    <p>
      These Services do not address anyone under the age of 13. We do not
      knowingly collect personally identifiable information from children under
      13. In the case we discover that a child under 13 has provided us with
      personal information, we immediately delete this from our servers. If you
      are a parent or guardian and you are aware that your child has provided us
      with personal information, please contact us so that we will be able to do
      necessary actions.
    </p>{' '}
    <p>
      We may update our Privacy Policy from time to time. Thus, you are advised
      to review this page periodically for any changes. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>{' '}
    <p>
      If you have any questions or suggestions about our Privacy Policy, do not
      hesitate to contact us at{' '}
      {API_REGION === 'RU' ? 'support@vrlessons.ru' : 'support@xreadylab.com'}.
    </p>{' '}
    <p>
      If you want request that your data that has been collected or stored can
      be deleted please contact us by{' '}
      {API_REGION === 'RU' ? 'support@vrlessons.ru' : 'support@xreadylab.com'}{' '}
      with Theme &quot;VR Biology Nucleotides Factory - Privacy Data&quot;.
    </p>
    <p>
      Xready Lab Corp. 919 North Market Street, 950 <br /> Wilmington, DE 19801
    </p>
  </>
);

export default DnaAppPolicy;
