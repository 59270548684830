/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {FocusEvent, useCallback, useState} from 'react';
import {FaEdit, FaEye, FaMinusCircle} from 'react-icons/fa';
import parse from 'html-react-parser';
import {
  CorrectAnswersDataEditorText,
  EditorTextAnswersData,
  ListTermsTextEditor,
} from './TextAnswerTypes';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import LoadingEditorBlock from '../../common/LoadingBlock';
import HiddenSettings from '../../../common/HiddenSettings';
import Button from '../../../../form/button/Button';
import FormButton from '../../../../form/button/FormButton';

const TextAnswerEditorBlock: React.FC<
  EditorElementProps<EditorTextAnswersData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<EditorTextAnswersData>();

  const [onEditMode, setOnEditMode] = useState<boolean>(false);

  const onChange = useCallback(
    (
      newContent: string,
      newListTerms?: ListTermsTextEditor[],
      newCorrectAnswers?: CorrectAnswersDataEditorText,
    ) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          content: newContent,
          listTerms: newListTerms || originBlock.data.listTerms,
          correctAnswers: newCorrectAnswers || originBlock.data.correctAnswers,
        },
      });
    },
    [setBlockMutation, originBlock],
  );

  // ф-ия вызывается когда мы меняем текст в списке терминов и понятий
  const OnTermsUpdated = useCallback(
    (e: FocusEvent<HTMLLIElement>, index: number) => {
      const newTerms = [...originBlock.data.listTerms];
      newTerms[index].term = e.target.innerText;
      onChange(originBlock.data.content, newTerms);
    },
    [onChange, originBlock.data.listTerms, originBlock.data.content],
  );

  // ф-ия вызывается когда мы меняем основной контент
  const OnContentUpdated = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      onChange(e.target.innerHTML);
    },
    [onChange],
  );

  // ф-ия вызывается когда мы удаляем термин
  const deleteTerm = useCallback(
    (index: number) => {
      if (originBlock.data.listTerms.length < 2) return;
      const newTerms = [...originBlock.data.listTerms];
      const deletedTerm = newTerms.splice(index, 1);
      // onChange(originBlock.data.content, newTerms);

      // если мы удаляем термин, то нужно и все выкидные списки, где был выбран этот удаленный термин, изменить на первый попавшийся термин из оставшихся. Только вот проблема, в originBlock.data.correctAnswers сохраняются нужные данные - это да, но визуально в селектах все по-другому
      onChange(originBlock.data.content, newTerms, {
        selects: originBlock.data.correctAnswers.selects.map((select) => {
          if (select === deletedTerm[0].term) {
            return newTerms[0].term;
          }
          return select;
        }),
        divs: originBlock.data.correctAnswers.divs,
      });
    },
    [
      originBlock.data.listTerms,
      originBlock.data.content,
      originBlock.data.correctAnswers.selects,
      originBlock.data.correctAnswers.divs,
      onChange,
    ],
  );

  // ф-ия вызывается когда мы добавляем термин
  const addTerm = useCallback(() => {
    const newTerms = [...originBlock.data.listTerms];
    newTerms.push({term: 'text', id: Date.now().toString()});
    onChange(originBlock.data.content, newTerms);
  }, [onChange, originBlock.data.listTerms, originBlock.data.content]);

  // возвращает select в виде строки
  const getSelectNodeString = useCallback(
    (left?: string, right?: string) => {
      const leftText = left || '';
      const rightText = right || '';
      const options = originBlock.data.listTerms
        .map((term) => `<option>${term.term}</option>`)
        .join(' ');
      const select = `<select className="selectNodes bg-transparent text-center border-2 border-blue-400 hover:bg-blue-100 bg-green-100">${options}</select>`;
      return `${leftText}${select}${rightText}`;
    },
    [originBlock.data.listTerms],
  );

  // возвращает div(editable) в виде строки
  const getEditableDivNodeString = useCallback(
    (left?: string, right?: string) => {
      const leftText = left || '';
      const rightText = right || '';
      const div =
        '<div className="divEditNodes bg-blue-50 w-fit px-2 py-1 mb-1 border border-solid border-blue-400 inline-block" contentEditable    suppressContentEditableWarning>Answer</div>';
      return `${leftText}${div}${rightText}`;
    },
    [],
  );

  // сохраняем в хранилище правильные ответы
  const onSaveCorrectAnswers = useCallback(() => {
    const arrSelect = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .selectNodes`,
      ),
    );
    const arrDiv = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .divEditNodes`,
      ),
    );
    onChange(originBlock.data.content, originBlock.data.listTerms, {
      selects: arrSelect.map((node: any) => node.value),
      divs: arrDiv.map((node: any) => node.innerText),
    });
  }, [
    onChange,
    originBlock.data.content,
    originBlock.data.listTerms,
    originBlock.id,
  ]);

  // ф-ия выполняется когда мы переключаемся на режим view, т.к. все редактируемые селекты и дивы у нас не контролируются реактом (они генерируются как строки, а потом уже парсером строки преобразуются в дом-элементы), то вручную выставляем в них значения, соответствующие, объекту originBlock.data.correctAnswers. Иначе каждый раз будут сбрасываться селекты и дивы при переключении режимов
  const changeSelectsAndDivs = useCallback(() => {
    const arrSelect = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .selectNodes`,
      ),
    );
    const arrDiv = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .divEditNodes`,
      ),
    );

    arrSelect.forEach((select: any, index) => {
      select.value =
        originBlock.data.correctAnswers.selects[index] ||
        originBlock.data.correctAnswers.selects[0];
    });
    arrDiv.forEach((div: any, index) => {
      div.innerText = originBlock.data.correctAnswers.divs[index] || 'Answer';
    });
  }, [
    originBlock.data.correctAnswers.divs,
    originBlock.data.correctAnswers.selects,
    originBlock.id,
  ]);

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="p-2 sm:p-5 w-full">
      <HiddenSettings>
        <FormButton
          className="w-full mb-2"
          onClick={() => {
            setBlockMutation.mutate({
              ...originBlock,
            });
          }}
        >
          Refresh live view
        </FormButton>
        <div className="flex gap-2 text-base items-center mb-1">
          <Button size="base">View</Button> - View Mode
        </div>
        <div className="flex gap-2 text-base items-center">
          <Button size="base">Edit</Button>- Editable text mode, use text flags:
        </div>
        <div className="text-base text-center">
          _DD_ - drop-down list, _ED_ - answer input
        </div>
      </HiddenSettings>
      <div className="flex gap-2 justify-center mb-2 relative">
        <Button
          size="base"
          disabled={!onEditMode}
          onClick={() => setOnEditMode(false)}
        >
          {!onEditMode && (
            <span className="animate-pulse w-3 h-3 rounded-full bg-pink-500 inline-block mr-2" />
          )}
          Edit
        </Button>
        <Button
          size="base"
          disabled={onEditMode}
          onClick={() => {
            setOnEditMode(true);
            setTimeout(() => changeSelectsAndDivs(), 0);
          }}
        >
          {onEditMode && (
            <span className="animate-pulse w-3 h-3 rounded-full bg-pink-500 inline-block mr-2" />
          )}
          View
        </Button>
        {onEditMode ? (
          <FaEye className="absolute top-0 right-0 text-pink-500" />
        ) : (
          <FaEdit className="absolute top-0 right-0 text-pink-500" />
        )}
      </div>
      {onEditMode ? (
        <>
          <div
            id={originBlock.id.split('-').join('')}
            className="w-fit px-2 py-1 mb-1"
          >
            {parse(
              originBlock.data.content
                .split(' ')
                .map((text) => {
                  // Далее проверка на любые другие символы вокруг ('_DD_')
                  if (text.includes('_DD_')) {
                    const leftRight = text.split('_DD_');
                    if (leftRight[0].length > 0 && leftRight[1].length > 0)
                      return getSelectNodeString(leftRight[0], leftRight[1]);
                    if (leftRight[0].length > 0 && leftRight[1].length === 0)
                      return getSelectNodeString(leftRight[0], '');
                    if (leftRight[0].length === 0 && leftRight[1].length > 0)
                      return getSelectNodeString('', leftRight[1]);
                    return getSelectNodeString();
                  }
                  // Далее проверка на любые другие символы вокруг ('_ED_')
                  if (text.includes('_ED_')) {
                    const leftRight = text.split('_ED_');
                    if (leftRight[0].length > 0 && leftRight[1].length > 0)
                      return getEditableDivNodeString(
                        leftRight[0],
                        leftRight[1],
                      );
                    if (leftRight[0].length > 0 && leftRight[1].length === 0)
                      return getEditableDivNodeString(leftRight[0], '');
                    if (leftRight[0].length === 0 && leftRight[1].length > 0)
                      return getEditableDivNodeString('', leftRight[1]);
                    return getEditableDivNodeString();
                  }
                  return text;
                })
                .join(' '),
            )}
          </div>
          <div className="text-center">
            <Button size="base" onClick={onSaveCorrectAnswers}>
              Save correct answers
            </Button>
          </div>
          <div className="text-base">
            Last saved correct answers:
            <ol>
              <li>
                <b>Drop-down answers in order:</b>{' '}
                {originBlock.data.correctAnswers.selects
                  .map((val, index) => `${index + 1}) ${val}`)
                  .join(', ')}
              </li>
              <li>
                <b>Text answers in order:</b>{' '}
                {originBlock.data.correctAnswers.divs
                  .map((val, index) => `${index + 1}) ${val}`)
                  .join(', ')}
              </li>
            </ol>
          </div>
        </>
      ) : (
        <div
          className="bg-blue-50 w-fit px-2 py-1 mb-1 border border-solid border-blue-400"
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => {
            OnContentUpdated(e);
            setTimeout(() => changeSelectsAndDivs(), 0);
          }}
        >
          {originBlock.data.content}
        </div>
      )}
      {!onEditMode && (
        <>
          <h2 className="my-2 text-2xl">List of terms and concepts:</h2>
          <div className="grid gap-2">
            {originBlock.data.listTerms.map((term, index) => {
              return (
                <li
                  key={term.id}
                  className="px-2 py-1 border border-solid border-blue-400 w-full flex justify-between items-center"
                  onBlur={(e) => OnTermsUpdated(e, index)}
                  contentEditable
                  suppressContentEditableWarning
                >
                  {term.term}
                  <FaMinusCircle
                    className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                    onClick={() => deleteTerm(index)}
                  />
                </li>
              );
            })}
            <FormButton className="w-full" onClick={addTerm}>
              Add term
            </FormButton>
          </div>
        </>
      )}
    </div>
  );
};

export default TextAnswerEditorBlock;
