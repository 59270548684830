import React from 'react';
import {BasicElement} from '../../../helpers/BasicElement';

export interface PaymentinfoHeaderProps extends BasicElement {
  className?: string;
}

const PaymentinfoHeader: React.FC<PaymentinfoHeaderProps> = ({
  children,
  className,
}) => {
  return (
    <h4 className={`text-2xl font-medium mb-2 ${className}`}>{children}</h4>
  );
};

export default PaymentinfoHeader;
