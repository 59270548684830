import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import BasicAdjustmentsEditor from '../../common/BasicAdjustmentsEditor';
import HiddenSettings from '../../common/HiddenSettings';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import {EditorSplitData} from './SplitTypes';

const SplitEditorBlock: React.FC<EditorElementProps<EditorSplitData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorSplitData>();

  const {
    register,
    getValues,
    setValue,
    formState: {errors},
    watch,
  } = useForm<EditorSplitData>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  return (
    <div className="p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
        </div>
      </HiddenSettings>
      <hr className="" />
    </div>
  );
};

export default SplitEditorBlock;
