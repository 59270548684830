/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {EditorBlocks} from '../../AllBlocks';
import LoadingEditorBlock from '../../elements/common/LoadingBlock';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';
import {useEditorBlock, useUpdateEditorBlock} from '../../types/UseEditor';

interface LiveViewerBlockWrapperProps {
  originBlock: EditorBlock<EditorBlockData>;
  isUseLocalVersion?: boolean;
}
const LiveViewerBlockWrapper: React.FC<LiveViewerBlockWrapperProps> = ({
  originBlock,
  isUseLocalVersion = false,
}) => {
  const block = useEditorBlock(originBlock.id);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setBlockMutation = useUpdateEditorBlock<any>();
  useEffect(() => {
    if (!isUseLocalVersion) {
      setBlockMutation.mutate(originBlock);
    }
  }, []);

  return block.isSuccess ? (
    <div
      style={{
        marginTop: block.data.blockAdditionalData?.margin?.top,
        marginBottom: block.data.blockAdditionalData?.margin?.bottom,
        marginLeft: block.data.blockAdditionalData?.margin?.left,
        marginRight: block.data.blockAdditionalData?.margin?.right,

        paddingTop: block.data.blockAdditionalData?.padding?.top,
        paddingBottom: block.data.blockAdditionalData?.padding?.bottom,
        paddingLeft: block.data.blockAdditionalData?.padding?.left,
        paddingRight: block.data.blockAdditionalData?.padding?.right,

        width: block.data.blockAdditionalData?.size?.width,
        height: block.data.blockAdditionalData?.size?.height,

        maxWidth: block.data.blockAdditionalData?.size?.maxWidth,
        maxHeight: block.data.blockAdditionalData?.size?.maxHeight,

        order: block.data.order,
      }}
      className={block.data.blockAdditionalData?.customClassName}
    >
      {EditorBlocks[block.data.type].viewer(
        block.data,
        isUseLocalVersion,
        true,
      )}
    </div>
  ) : (
    <LoadingEditorBlock />
  );
};

export default LiveViewerBlockWrapper;
