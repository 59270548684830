import {EditorBlocks} from '../../AllBlocks';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';

interface ViewerBlockWrapperProps {
  originBlock: EditorBlock<EditorBlockData>;
  isUseLocalVersion?: boolean;
}
const ViewerBlockWrapper: React.FC<ViewerBlockWrapperProps> = ({
  originBlock,
  isUseLocalVersion = false,
}) => (
  <div
    style={{
      marginTop: originBlock.blockAdditionalData?.margin?.top,
      marginBottom: originBlock.blockAdditionalData?.margin?.bottom,
      marginLeft: originBlock.blockAdditionalData?.margin?.left,
      marginRight: originBlock.blockAdditionalData?.margin?.right,

      paddingTop: originBlock.blockAdditionalData?.padding?.top,
      paddingBottom: originBlock.blockAdditionalData?.padding?.bottom,
      paddingLeft: originBlock.blockAdditionalData?.padding?.left,
      paddingRight: originBlock.blockAdditionalData?.padding?.right,

      width: originBlock.blockAdditionalData?.size?.width,
      height: originBlock.blockAdditionalData?.size?.height,

      maxWidth: originBlock.blockAdditionalData?.size?.maxWidth,
      maxHeight: originBlock.blockAdditionalData?.size?.maxHeight,

      order: originBlock.order,
    }}
    className={originBlock.blockAdditionalData?.customClassName}
  >
    {EditorBlocks[originBlock.type].viewer(originBlock, isUseLocalVersion)}
  </div>
);

export default ViewerBlockWrapper;
