import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useContactUsForm} from '../../api/contacts/ContactsMutations';
import Button from '../../components/form/button/Button';
import Input from '../../components/form/input/Input';
import RichTextInput from '../../components/form/input/RichTextInput';
import ListBox, {ListBoxOption} from '../../components/form/listbox/Listbox';
import {API_REGION} from '../../services/flagProvider/FlagProvider';
import {useRef} from 'react';
import useScript from '../../hooks/useScript';

type SingUp = {
  topic: RequestTopicCategory;
  name: string;
  email: string;
  phone: string;
  comment: string;
};

export enum RequestTopicCategory {
  REQUEST_NEW_LAB,
  SUGGEST_IDEA,
  FEEDBACK,
  QUESTION,
  BUY,
}

const ContactsPage: React.FC = () => {
  const {t} = useTranslation('contactsPage');
  const naviagator = useNavigate();

  const contactUsForm = useContactUsForm({
    onSuccess: () => naviagator('/contacts/success'),
  });

  const RequestTopic: ListBoxOption<RequestTopicCategory>[] = [
    {label: t('topic.QUESTION'), value: RequestTopicCategory.QUESTION},
    {label: t('topic.SUGGEST_IDEA'), value: RequestTopicCategory.SUGGEST_IDEA},
    {label: t('topic.FEEDBACK'), value: RequestTopicCategory.FEEDBACK},
    {label: t('topic.BUY'), value: RequestTopicCategory.BUY},
  ];

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<SingUp>({
    mode: 'onSubmit',
    defaultValues: {
      topic: RequestTopicCategory.QUESTION,
    },
  });

  const onSubmit = (data: SingUp) => {
    contactUsForm.mutate({...data, locale: API_REGION || 'unknown'});
  };

  const container = useRef<HTMLDivElement>(null);
  useScript(
    'https://cdn-ru.bitrix24.ru/b28330568/crm/form/loader_30.js' +
      '?' +
      ((Date.now() / 180000) | 0),
    container,
    {
      'data-b24-form': 'inline/30/21x05c',
      'data-skip-moving': 'true',
    },
  );

  return (
    <div className="mt-2 mx-auto mb-48 contacts-form">
      {/* <h1 className="font-bold text-xl lg:text-6xl text-center uppercase text-indigo-900 mb-2 lg:mb-14">
        {t('feedback')}
      </h1>
      <div className="mx-auto mt-0 mb-2 lg:mb-16 font-light text-sm lg:text-lg text-center text-black contacts-description">
        {t('description')}
      </div> */}
      <div ref={container}></div>
      {/* <form className="px-2 lg:px-40" onSubmit={handleSubmit(onSubmit)}>
        <ListBox
          name="topic"
          options={RequestTopic}
          label={t('form.topic')}
          setValue={setValue}
          getValues={getValues}
        />
        <Input
          label={t('form.name')}
          name="name"
          registration={{register, errors}}
          className="mt-3"
        />
        <Input
          label={t('form.email')}
          name="email"
          registration={{register, errors}}
          className="mt-3"
          options={{
            required:
              (t('form.emailError') as string | undefined) || 'Required',
          }}
          type="email"
        />
        <Input
          label={t('form.phone')}
          name="phone"
          registration={{register, errors}}
          className="mt-3"
        />
        <RichTextInput
          label={t('form.message')}
          name="comment"
          registration={{register, errors}}
          className="mt-3 h-40"
        />
        <Button type="submit" id="signUp1" className="w-full mt-4">
          {t('form.send')}
        </Button>
      </form> */}
    </div>
  );
};

export default ContactsPage;
