import {EditorBlockData} from '../../types/EditorBlock';

export enum ButtonEditorStyleType {
  FILLED,
  OUTLINE,
}

export interface EditorButtonData extends EditorBlockData {
  label: string;
  href: string;
  openInNewTab: boolean;
  buttonStyle: ButtonEditorStyleType;
}
