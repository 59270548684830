import React from 'react';
import {Link} from 'react-router-dom';
import CoursesParagraph from '../dolcevita/CoursesParagraph';
import PaymentinfoHeader from '../dolcevita/PaymentinfoHeader';

const PaymentinfoPolicy: React.FC = () => (
  <section className="mb-5 mt-2 mx-auto p-3 max-w-screen-xl">
    <h2 className="text-center mx-auto mb-6 text-4xl font-medium">
      Описание процесса оплаты
    </h2>
    <PaymentinfoHeader>Контакты:</PaymentinfoHeader>
    <CoursesParagraph>
      Фактический адрес: 121205, г.Москва Большой Бульвар д. 30 стр 1 эт 2 п
      225, рм 225-9 Электронная почта: online.mycfo@gmail.com Телефоны:
      8-909-696-88-98
    </CoursesParagraph>
    <PaymentinfoHeader>Реквизиты:</PaymentinfoHeader>
    <CoursesParagraph>
      ООО "XreadyLab", ИНН 9731053156 / ОГРН 1197746602822. Юридический адрес:
      121205, г.Москва Большой Бульвар д. 30 стр 1 эт 2 п 225, рм 225-9 Тел.: +7
      (909) 696-88-98
    </CoursesParagraph>
    <PaymentinfoHeader>Оплата банковской картой</PaymentinfoHeader>
    <CoursesParagraph>
      Для выбора оплаты товара с помощью банковской карты на соответствующей
      странице необходимо нажать кнопку Оплатить курс. Оплата происходит через
      ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:
    </CoursesParagraph>
    <ul className="pl-4 mb-4 list-disc text-base">
      <li>VISA International</li>
      <li>Mastercard Worldwide</li>
      <li>МИР</li>
    </ul>
    <CoursesParagraph>
      Возврат переведённых средств, производится на ваш банковский счёт в
      течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу
      банковскую карту).
    </CoursesParagraph>
    <PaymentinfoHeader>
      Описание процесса передачи данных/информация о SSL-шифровании
    </PaymentinfoHeader>
    <CoursesParagraph>
      Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО
      &quot;Сбербанк России&quot; для ввода реквизитов Вашей карты. Пожалуйста,
      приготовьте Вашу пластиковую карту заранее. Соединение с платежным шлюзом
      и передача информации осуществляется в защищенном режиме с использованием
      протокола шифрования SSL.
    </CoursesParagraph>
    <CoursesParagraph>
      В случае если Ваш банк поддерживает технологию безопасного проведения
      интернет-платежей Verified By Visa или MasterCard Secure Code для
      проведения платежа также может потребоваться ввод специального пароля.
      Способы и возможность получения паролей для совершения интернет-платежей
      Вы можете уточнить в банке, выпустившем карту.
    </CoursesParagraph>
    <CoursesParagraph>
      Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
      сообщаемой персональной информации обеспечивается ПАО &quot;Сбербанк
      России&quot;. Введенная информация не будет предоставлена третьим лицам за
      исключением случаев, предусмотренных законодательством РФ. Проведение
      платежей по банковским картам осуществляется в строгом соответствии с
      требованиями платежных систем Visa Int. и MasterCard Europe Sprl.
    </CoursesParagraph>
    <Link
      to="/policies/policy"
      className="text-blue-500 hover:underline hover:text-blue-800 text-lg"
    >
      Политика в отношении обработки персональных данных
    </Link>
  </section>
);

export default PaymentinfoPolicy;
