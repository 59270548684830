import {UseQueryResult, useQuery, useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  UseShortMutationOptions,
  UseShortQueryOptions,
} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {AuthRequestDAO} from './dao/AuthRequestDAO';
import {AuthDTO} from './dto/AuthDTO';

export const CheckTokenKey = 'auth/getToken';

export function useCheckToken(
  options?: UseShortQueryOptions<boolean, AxiosError<ApiError>>,
  errorHandler?: (error: AxiosError<ApiError>) => void,
): UseQueryResult<boolean, AxiosError<ApiError>> {
  return useQuery(
    [CheckTokenKey],
    async (): Promise<boolean> => {
      const {data} = await APIRequest.get<boolean>(`/Auth/checkToken`);
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export const useCheckTokenOnRequest = (
  options?: UseShortMutationOptions<boolean, AxiosError<ApiError>>,
  errorHandler?: (error: AxiosError<ApiError>) => void,
) => {
  return useMutation<boolean, AxiosError<ApiError>>(
    [CheckTokenKey],
    async (): Promise<boolean> => {
      const {data} = await APIRequest.get<boolean>(`/Auth/checkToken`);
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

// mutations

export const GetTokenKey = 'auth/getToken';

export const useAuthGetToken = (
  options?: UseShortMutationOptions<
    AuthDTO,
    AxiosError<ApiError>,
    AuthRequestDAO
  >,
  errorHandler?: (error: AxiosError<ApiError>) => void,
) => {
  return useMutation<AuthDTO, AxiosError<ApiError>, AuthRequestDAO>(
    [GetTokenKey],
    async (authData: AuthRequestDAO): Promise<AuthDTO> => {
      const {data} = await APIRequest.post<AuthDTO>(`/Auth/getToken`, authData);
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
