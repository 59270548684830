import React, {useEffect, useState} from 'react';

import Collapsible from 'react-collapsible';
import {AiOutlineSetting} from 'react-icons/ai';
import {BasicElement} from '../../../helpers/BasicElement';

interface HiddenSettingsProps extends BasicElement {
  opened?: boolean;
}

const HiddenSettings: React.FC<HiddenSettingsProps> = ({
  opened = false,
  children,
}) => {
  const [isOpened, setIsOpened] = useState(opened);

  useEffect(() => {
    setIsOpened(opened);
  }, [opened]);

  return (
    <Collapsible
      overflowWhenOpen="visible"
      open={isOpened}
      onClosing={() => setIsOpened(false)}
      onOpening={() => setIsOpened(true)}
      trigger={
        <div className="absolute top-0 left-0 p-0.5 w-5 h-5 z-30">
          <AiOutlineSetting className="w-full h-full" />
        </div>
      }
      transitionTime={100}
    >
      {children}
      <hr className="my-6" />
    </Collapsible>
  );
};

export default HiddenSettings;
