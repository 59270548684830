import React, {useCallback, useEffect, useState} from 'react';
import {AiOutlineLoading} from 'react-icons/ai';
import {Link, useNavigate} from 'react-router-dom';
import {useDeleteCustomPage} from '../../../../api/customPage/CustomPageMutations';
import {TranslationsCreateCustomPageDAO} from '../../../../api/customPage/dao/CustomPageDAO';
import {CustomPageFullDTO} from '../../../../api/customPage/dto/CustomPageDTO';
import {GenerateCustomPageLink} from '../../../../helpers/CustomPageLinkGenerator';
import {useConfirmationAlert} from '../../../../services/confirmationAlert/ConfirmationAlertService';
import FormButton from '../../../form/button/FormButton';
import ServerImage, {ImageFit} from '../../../serverImage/ServerImage';

interface AdminCustomPageCardProps {
  data: CustomPageFullDTO;
  languageId: string;
}

const AdminCustomPageCard: React.FC<AdminCustomPageCardProps> = ({
  data,
  languageId,
}) => {
  const navigation = useNavigate();

  const deleteCustomPage = useDeleteCustomPage();
  const confirmationAlert = useConfirmationAlert();

  const onDeleteCustomPage = useCallback(
    (id: string) => {
      confirmationAlert.showAlert({
        message: `Are you sure you want to delete this custom page ${id}?`,
        onCancel: () => {},
        onConfirm: () => {
          deleteCustomPage.mutate(id);
        },
      });
    },
    [confirmationAlert, deleteCustomPage],
  );

  const [currentTranslation, setCurrectTranslation] =
    useState<TranslationsCreateCustomPageDAO>();
  useEffect(() => {
    setCurrectTranslation(
      data.translations.find((x) => x.languageId == languageId),
    );
  }, [data, languageId]);

  return (
    <div>
      <div className="flex">
        <FormButton
          onClick={() => onDeleteCustomPage(data.id)}
          className="bg-red-500 text-white hover:bg-red-400"
        >
          Delete
        </FormButton>
        <FormButton
          onClick={() => navigation(`/admin/custom-pages/${data.id}`)}
          className="grow"
        >
          Tech preview
        </FormButton>
        <FormButton
          onClick={() => navigation(`/p/${GenerateCustomPageLink(data)}`)}
        >
          Open
        </FormButton>
      </div>
      <div className="relative border-x border-b shadow-x shadow-t">
        <Link
          to={`/admin/custom-pages/edit/${data.id}`}
          className="w-full relative"
        >
          <div className="h-20 w-full relative bg-indigo-900 hover:bg-indigo-800 text-white flex justify-center items-center ">
            {deleteCustomPage.isLoading ? (
              <div className="flex w-full justify-center items-center">
                <AiOutlineLoading
                  className="animate-spin font-semibold"
                  size={24}
                />
              </div>
            ) : (
              <div className="flex flex-col text-center">
                <span className="text-xl font-semibold">
                  {currentTranslation?.title || '-'}
                </span>
                <span>{GenerateCustomPageLink(data)}</span>
              </div>
            )}
          </div>
          {currentTranslation && currentTranslation.thumbnailId && (
            <ServerImage
              className="aspect-square"
              imageId={currentTranslation.thumbnailId}
              imageFit={ImageFit.Cover}
              alt="Custom page thumbnail"
            />
          )}
        </Link>
      </div>
    </div>
  );
};

export default AdminCustomPageCard;
