import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Button from '../../components/form/button/Button';

const ContactsSuccess: React.FC = () => {
  const {t} = useTranslation('contactsPage');

  return (
    <div className="mt-2 lg:mt-24 mx-auto mb-48 contacts-form">
      <h1 className="font-bold mb-6 text-2xl lg:text-6xl text-center uppercase text-indigo-900">
        {t('thanks')}
      </h1>
      <div className="mx-auto mt-0 m-6 text-lg text-center text-black">
        {t('answer_submitted')}
      </div>
      <Link to="/">
        <Button className="block mx-auto">{t('ok')}</Button>
      </Link>
    </div>
  );
};

export default ContactsSuccess;
