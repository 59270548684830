import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {DashboardDTO} from './dto/DashboardDTO';

export const GetDashboard = 'library/dashboard';

export function useGetDashboard(
  languageCode: string,
  options?: UseShortQueryOptions<DashboardDTO, AxiosError<ApiError>>,
): UseQueryResult<DashboardDTO, AxiosError<ApiError>> {
  return useQuery(
    [GetDashboard, {languageCode}],
    async (): Promise<DashboardDTO> => {
      const {data} = await APIRequest.get<DashboardDTO>(
        `/Library/GetDashboard`,
        {
          params: {
            languageCode,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
