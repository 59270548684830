import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {CourseTranslationDTO} from '../../../../api/courses/dto/CourseDTO';
import {LocalizationDTO} from '../../../../api/localization/dto/LocalizationDTO';
import {GetGridTemplate} from '../../../editor/BlocksTemplates';
import {EditorGridViewerData} from '../../../editor/elements/grid/GridType';
import ContentEditor from '../../../editor/service/ContentEditor';
import {
  CopyEditorBlocks,
  PasteEditorBlocks,
} from '../../../editor/service/copyPaste/CopyEditorBlocks';
import {EditorBlock} from '../../../editor/types/EditorBlock';
import FormButton from '../../../form/button/FormButton';
import Input from '../../../form/input/Input';
import RichTextInput from '../../../form/input/RichTextInput';

export type HollowCourseTranslationDTO = Omit<CourseTranslationDTO, 'body'>;

interface TranslationEditorProps extends React.HTMLProps<HTMLDivElement> {
  language: LocalizationDTO;
  onFormUpdated: (data: HollowCourseTranslationDTO) => void;
  originalTranslation?: CourseTranslationDTO;
}

const TranslationEditor: React.FC<TranslationEditorProps> = ({
  language,
  className,
  onFormUpdated,
  originalTranslation,
  ...rest
}) => {
  const {
    register,
    formState: {errors},
    getValues,
    watch,
  } = useForm<HollowCourseTranslationDTO>({
    defaultValues: {
      ...originalTranslation,
    },
  });

  const [baseBlock, setBaseBlock] = useState<EditorBlock<EditorGridViewerData>>(
    originalTranslation
      ? JSON.parse(originalTranslation.body)
      : GetGridTemplate(),
  );

  const SendUpdate = useCallback(
    (data: HollowCourseTranslationDTO) => {
      onFormUpdated({...data});
    },
    [onFormUpdated],
  );

  useEffect(() => {
    const subscription = watch((data) => {
      SendUpdate({
        languageId: language.id,
        name: data.name || '',
        description: data.description || '',
        bodyId: baseBlock.id,
      });
    });
    return () => subscription.unsubscribe();
  }, [SendUpdate, baseBlock.id, language.id, onFormUpdated, watch]);

  useEffect(() => {
    if (originalTranslation) {
      SendUpdate({...originalTranslation, bodyId: baseBlock.id});
    } else {
      SendUpdate({
        languageId: language.id,
        name: '',
        description: '',
        bodyId: baseBlock.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className} {...rest}>
      <div className="grid gap-2">
        <Input
          label="Course title"
          name="name"
          registration={{register, errors}}
          options={{required: 'Required'}}
        />
        <RichTextInput
          label="Course description"
          name="description"
          registration={{register, errors}}
          options={{required: 'Required'}}
        />
        <hr className="my-2" />
        <div className="flex gap-2">
          <FormButton onClick={() => CopyEditorBlocks(baseBlock.id)}>
            Copy
          </FormButton>
          <FormButton
            onClick={() => {
              PasteEditorBlocks((data: EditorBlock<EditorGridViewerData>) => {
                setBaseBlock(data);
                const vals = getValues();
                SendUpdate({
                  languageId: language.id,
                  name: vals.name || '',
                  description: vals.description || '',
                  bodyId: data.id,
                });
              });
            }}
          >
            Paste
          </FormButton>
        </div>
        <ContentEditor coreBlock={baseBlock} />
      </div>
    </div>
  );
};

export default TranslationEditor;
