import React, {createRef, useEffect, useState} from 'react';
import _uniqueId from 'lodash/uniqueId';
import './Fileinput.scoped.css';
import Dropzone, {DropzoneRef} from 'react-dropzone';
import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import classNames from 'classnames';
import {HiOutlineTrash} from 'react-icons/hi';
import SimpleButton from '../simpleButton/SimpleButton';

type InputParameters<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label?: string;

  onChange: (name: TFieldName, value: File[]) => void;

  maxFiles?: number;
  maxSize?: number;

  name: TFieldName;
  registration: {
    register: UseFormRegister<TFieldValues>;
    errors: DeepMap<DeepPartial<FieldValues>, FieldError>;
  };
  options?: RegisterOptions<TFieldValues, TFieldName>;
} & Omit<React.HTMLAttributes<HTMLElement>, 'onChange'>;

type FileWithUid = {
  uid: string;
  file: File;
};

const FileInput: <T extends FieldValues = FieldValues>(
  p: InputParameters<T>,
) => React.ReactElement<InputParameters<T>> = ({
  label,
  maxFiles = 16,
  maxSize,
  onChange,
  name,
  registration,
  options,
  ...rest
}) => {
  const [id] = useState(_uniqueId('fileinput-'));
  const [files, setFiles] = useState<FileWithUid[]>();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isLocked, setisLocked] = useState<boolean>(false);

  useEffect(() => {
    if (files) {
      if (files.length > maxFiles) {
        setFiles(files.slice(0, maxFiles));
        return;
      }

      onChange(name, files?.map((el) => el.file) || []);
      setisLocked(files.length >= maxFiles);
    }
  }, [onChange, files, name, maxFiles]);

  const dropzoneRef = createRef<DropzoneRef>();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  registration.register(name, options);

  // const test = files?.map((el) => el.file) || [];

  return (
    <div {...rest}>
      <div className="relative input-component cover">
        {label && (
          <label
            htmlFor={id}
            className="absolute left-2 transition-all bg-white px-1"
          >
            {label}
            {options?.required && <span className="pl-1 text-pink">*</span>}
          </label>
        )}
        <div className="p-2 w-100">
          <Dropzone
            ref={dropzoneRef}
            onDropAccepted={(acceptedFiles: File[]) => {
              setIsHovering(false);
              const accepted: FileWithUid[] = acceptedFiles.map((el) => ({
                uid: _uniqueId(),
                file: el,
              }));

              if (files) {
                setFiles(files.concat(accepted));
              } else {
                setFiles(accepted);
              }
            }}
            onDragEnter={() => setIsHovering(true)}
            onDropRejected={() => setIsHovering(false)}
            onDragLeave={() => setIsHovering(false)}
            noClick
            noKeyboard
            maxFiles={maxFiles}
            maxSize={maxSize}
            multiple={maxFiles > 1}
          >
            {({getRootProps, getInputProps}) => (
              <div
                {...getRootProps({className: 'dropzone'})}
                className={classNames(
                  'border-2 h-40 border-dashed mt-3 transition duration-300 ease-in-out',
                  isHovering ? 'border-green-300' : 'border-gray-300',
                  isLocked ? 'hidden' : '',
                )}
              >
                <input {...getInputProps()} />
                <div className="flex h-40">
                  <div className="m-auto">
                    <p
                      className={classNames(
                        'relative w-100 text-center transition duration-500 ease-in-out mb-4',
                        isHovering ? 'text-lg' : '',
                      )}
                    >
                      Drag &#39;n&#39; drop some files here
                    </p>
                    <SimpleButton
                      onClick={openDialog}
                      className={classNames(
                        'block m-auto',
                        isHovering ? 'hidden' : '',
                      )}
                    >
                      Open File Dialog
                    </SimpleButton>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>

          {files && (
            <div className="grid grid-cols-4 gap-2 mt-3">
              {files.map((file, index) => (
                <div
                  key={file.uid}
                  className="border border-gray-300 rounded-l-sm"
                >
                  <img
                    className="thumbnail h-16 w-4/6 inline-block"
                    src={URL.createObjectURL(file.file)}
                    alt="thumbnail"
                  />
                  <SimpleButton
                    className="w-2/6 h-full inline-block align-middle pl-3 border-l border-gray-300 relative"
                    disableBorders
                    onClick={() =>
                      setFiles(files.filter((val, i) => index !== i))
                    }
                  >
                    <HiOutlineTrash className="text-xl absolute top-1/3 left-1/4" />
                  </SimpleButton>
                </div>
              ))}
            </div>
          )}
        </div>
        {registration.errors[name] && (
          <span className="text-error-600 text-xs">
            {(registration.errors[name] as FieldError | undefined)?.message}
          </span>
        )}
      </div>
    </div>
  );
};

export default FileInput;
