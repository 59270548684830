import classNames from 'classnames';
import React from 'react';

import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  A11y,
  EffectCoverflow,
} from 'swiper';
import {Swiper} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs';
import './Carousel.scoped.css';

// TODO: implement rerender function
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow]);

export interface CarouselData {
  isArrow?: boolean;
  isPagination?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Carousel: React.FC<CarouselData> = ({
  className,
  children,
  isArrow = false,
  isPagination = false,
  ...others
}) => {
  return (
    <div
      className={classNames(
        className,
        'w-full max-w-screen-md mx-auto relative',
      )}
      {...others}
    >
      <Swiper
        effect="coverflow"
        grabCursor
        loop
        spaceBetween={50}
        speed={300}
        autoplay
        centeredSlides
        slidesPerView={1}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: true,
        }}
        className="w-full"
        data-swiper-parallax-opacity="0.5"
        pagination={{clickable: true, enabled: isPagination}}
        modules={[Pagination]}
        navigation={{nextEl: '.arrow-right', prevEl: '.arrow-left'}}
      >
        <div className="w-full">{children}</div>
      </Swiper>
      {isArrow && (
        <div
          className="w-11 h-11 rounded-full bg-gray-200 hover:bg-gray-300 p-2 z-10 flex-none my-auto arrow-left absolute top-0 bottom-0 border border-solid border-gray-400 hidden md:block"
          tabIndex={0}
          role="button"
          aria-label="Previous slide"
        >
          <BsArrowLeft className="text-pink-600" size={28} />
        </div>
      )}
      {isArrow && (
        <div
          className="w-11 h-11 rounded-full bg-gray-200 hover:bg-gray-300 p-2 z-10 flex-none my-auto arrow-right absolute top-0 bottom-0 border border-solid border-gray-400 hidden md:block"
          tabIndex={0}
          role="button"
          aria-label="Next slide"
        >
          <BsArrowRight className="text-pink-600" size={28} />
        </div>
      )}
    </div>
  );
};

export default Carousel;
