import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {BsFullscreen, BsFullscreenExit} from 'react-icons/bs';
import {ViewerElementProps} from '../../types/EditorBlock';
import {EditorGameData} from './GameTypes';

const GameViewerBlock: React.FC<ViewerElementProps<EditorGameData>> = (
  originBlock,
) => {
  const [isOpenedFullScreen, setIsOpenedFullScreen] = useState(false);
  useEffect(() => {
    document.documentElement.onfullscreenchange = () => {
      setIsOpenedFullScreen(!!document.fullscreenElement);
    };
  }, []);

  return (
    <div className="aspect-video relative w-full lg:w-5/6 mx-auto">
      <iframe
        src={originBlock.data.url}
        title="Video"
        className={classNames(
          isOpenedFullScreen
            ? 'w-screen h-screen fixed top-0 left-0 z-40'
            : 'w-full h-full absolute',
        )}
      />
      {originBlock.data.isShowFullscreenButton && (
        <div
          className={classNames(
            'bg-gray-300 rounded-lg p-2 aspect-square  bottom-3 right-3 cursor-pointer z-50',
            isOpenedFullScreen ? 'fixed' : 'absolute',
          )}
          style={{maxWidth: '50px', width: '10%'}}
          onClick={() => {
            if (!isOpenedFullScreen) {
              document.documentElement.requestFullscreen();
              screen.orientation.lock('landscape');
            } else {
              document.exitFullscreen();
            }
          }}
        >
          {!isOpenedFullScreen ? (
            <BsFullscreen className="w-full h-full" />
          ) : (
            <BsFullscreenExit className="w-full h-full" />
          )}
        </div>
      )}
    </div>
  );
};

export default GameViewerBlock;
