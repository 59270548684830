import React, {useCallback} from 'react';
import {SketchfabAnnotationDTO} from '../../../../api/sketchfab/dto/SketchfabModelDTO';
import SketchfabViewerEditorVersion from '../../../sketchfabViewer/SketchfabViewerEditorVersion';
import {ViewerElementProps} from '../../types/EditorBlock';
import LoadingEditorBlock from '../common/LoadingBlock';
import {EditorSketchfabData} from './SketchfabType';

const SketchfabViewerEditor: React.FC<
  ViewerElementProps<EditorSketchfabData>
> = (originBlock) => {
  // генерируем массив разрешенных к показу стандартных аннотаций, т.е. только те, у которых параметр isShown = true
  const arrayAnnotationForShow = useCallback(() => {
    const array: number[] = [];
    originBlock.data.sketchfabData.translation.annotations.forEach(
      (annotation: SketchfabAnnotationDTO, index: number) => {
        if (annotation.isShown) {
          array.push(index);
        }
      },
    );
    return array;
  }, [originBlock.data.sketchfabData.translation.annotations]);

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="flex justify-center mb-3">
      <div className="simulation-video relative w-full">
        {originBlock.data.sketchfabData.modelId ? (
          <SketchfabViewerEditorVersion
            modelId={originBlock.data.sketchfabData.modelId}
            className="w-full h-full"
            autostart={originBlock.data.sketchfabData.autoplay}
            annotations={originBlock.data.sketchfabData.translation.annotations}
            customAnnotations={
              originBlock.data.sketchfabData.translation.customAnnotations
            }
            showOnlyAnnotations={arrayAnnotationForShow()}
            currentAnimationUID={
              originBlock.data.sketchfabData.defaultAnimation
            }
            isShowBackground={originBlock.data.sketchfabData.isShowBackground}
            currentCameraPosition={
              originBlock.data.sketchfabData.cameraPosition
            }
            selectedAnnotation={
              originBlock.data.sketchfabData.slectedAnnotation
            }
          />
        ) : (
          <LoadingEditorBlock />
        )}
      </div>
    </div>
  );
};

export default SketchfabViewerEditor;
