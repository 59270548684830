import classNames from 'classnames';
import React, {useCallback} from 'react';
import {SwiperSlide} from 'swiper/react';
import Carousel from '../../../carousel/Carousel';
import ServerImage from '../../../serverImage/ServerImage';
import {ViewerElementProps} from '../../types/EditorBlock';
import {CarouselImageType, EditorCarouselData} from './CarouselTypes';

const CarouselViewerBlock: React.FC<ViewerElementProps<EditorCarouselData>> = ({
  data,
}) => {
  const getImageFormatClass = useCallback((): string => {
    switch (data.sizeType) {
      case CarouselImageType.Format169:
        return 'aspect-video';

      case CarouselImageType.Square:
        return 'aspect-square';

      default:
        return 'w-full';
    }
  }, [data]);

  return (
    <div
      className="simulation-screenshots mx-auto mt-0 mb-2 lg:mb-2"
      style={{maxWidth: data.maxWidth}}
    >
      {data.images.length > 0 && (
        <Carousel isPagination isArrow>
          {data.images.map((imageId) => (
            <SwiperSlide
              key={imageId}
              className={classNames(getImageFormatClass())}
            >
              <div className="w-full h-full">
                <div className="h-full w-full mx-auto">
                  <ServerImage
                    imageId={imageId}
                    alt={`Carousel image ${imageId}`}
                    className="w-full h-full"
                    imageFit={data.imageFit}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default CarouselViewerBlock;
