import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {queryClient} from '../../app/App';
import {UseShortMutationOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {CreatedDTO} from '../basicTypes/Created';
import {GetAllCustomPagesKey} from './CustomPage';
import {CreateCustomPageDAO} from './dao/CustomPageDAO';

const CreateCustomPageKey = 'customPage/create';
const DeleteCustomPageKey = 'customPage/delete';
const UpdateCustomPageKey = 'customPage/update';

export const useCreateCustomPage = (
  options?: UseShortMutationOptions<
    CreatedDTO,
    AxiosError<ApiError>,
    CreateCustomPageDAO
  >,
) => {
  return useMutation<CreatedDTO, AxiosError<ApiError>, CreateCustomPageDAO>(
    [CreateCustomPageKey],
    async (newCustomPage: CreateCustomPageDAO): Promise<CreatedDTO> => {
      const {data} = await APIRequest.post<CreatedDTO>(
        `/CustomPage/Create`,
        newCustomPage,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GetAllCustomPagesKey]);
      },
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useDeleteCustomPage = (
  options?: UseShortMutationOptions<CreatedDTO, AxiosError<ApiError>, string>,
) => {
  return useMutation<CreatedDTO, AxiosError<ApiError>, string>(
    [DeleteCustomPageKey],
    async (id: string): Promise<CreatedDTO> => {
      const {data} = await APIRequest.get<CreatedDTO>(
        `/CustomPage/Delete?id=${id}`,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GetAllCustomPagesKey]);
      },
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useUpdateCustomPage = (
  id: string,
  options?: UseShortMutationOptions<
    string,
    AxiosError<ApiError>,
    CreateCustomPageDAO
  >,
) => {
  return useMutation<string, AxiosError<ApiError>, CreateCustomPageDAO>(
    [UpdateCustomPageKey],
    async (UpdateCustomPage: CreateCustomPageDAO): Promise<string> => {
      const {data} = await APIRequest.post<string>(
        `/CustomPage/Update?id=${id}`,
        UpdateCustomPage,
      );
      return data;
    },
    {
      onSuccess: (response, request) => {
        queryClient.invalidateQueries([GetAllCustomPagesKey]);
        console.log('useUpdateCustomPage.response', response);
        console.log('useUpdateCustomPage.response', request);

        // queryClient.invalidateQueries([GetCourseKey, {id: request}]);
        // queryClient.invalidateQueries([GetFullCourseKey, {id: request}]);
      },
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
