import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {AddCourseDAO} from './dao/CreateCourseRequestDAO';
import {CourseDTO} from './dto/CourseDTO';

export const GetCoursesKey = 'course/getall';
export const GetCourseKey = 'course/get';
export const GetRandomCoursesKey = 'course/getrnd';
export const GetFullCourseKey = 'course/get/full';
export const GetFullCoursesKey = 'course/getall';

export function useGetCourses(
  languageCode: string,
  options?: UseShortQueryOptions<CourseDTO[], AxiosError<ApiError>>,
): UseQueryResult<CourseDTO[], AxiosError<ApiError>> {
  return useQuery(
    [GetCoursesKey, {languageCode}],
    async (): Promise<CourseDTO[]> => {
      const {data} = await APIRequest.get<CourseDTO[]>(
        `/Course/GetAllCourses`,
        {
          params: {
            languageCode,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetRandomCourses(
  languageCode: string,
  amount: number,
  options?: UseShortQueryOptions<CourseDTO[], AxiosError<ApiError>>,
): UseQueryResult<CourseDTO[], AxiosError<ApiError>> {
  return useQuery(
    [GetRandomCoursesKey, {languageCode}],
    async (): Promise<CourseDTO[]> => {
      const {data} = await APIRequest.get<CourseDTO[]>(
        `/Course/GetRandomCourses`,
        {
          params: {
            languageCode,
            amount,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetCourse(
  id: string,
  languageCode: string,
  options?: UseShortQueryOptions<CourseDTO, AxiosError<ApiError>>,
): UseQueryResult<CourseDTO, AxiosError<ApiError>> {
  return useQuery(
    [GetCourseKey, {id, languageCode}],
    async (): Promise<CourseDTO> => {
      const {data} = await APIRequest.get<CourseDTO>(`/Course/GetCourse`, {
        params: {
          courseShortNameOrId: id,
          languageCode,
        },
      });
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      retry: 1,
      ...options,
    },
  );
}

export function useGetAdminCourses(
  options?: UseShortQueryOptions<AddCourseDAO[], AxiosError<ApiError>>,
): UseQueryResult<AddCourseDAO[], AxiosError<ApiError>> {
  return useQuery(
    [GetFullCoursesKey],
    async (): Promise<AddCourseDAO[]> => {
      const {data} = await APIRequest.get<AddCourseDAO[]>(
        `/Course/GetAllAdminCourses`,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetAdminCourse(
  id: string,
  options?: UseShortQueryOptions<AddCourseDAO, AxiosError<ApiError>>,
): UseQueryResult<AddCourseDAO, AxiosError<ApiError>> {
  return useQuery(
    [GetFullCourseKey, {id}],
    async (): Promise<AddCourseDAO> => {
      const {data} = await APIRequest.get<AddCourseDAO>(
        `/Course/GetFullCourse`,
        {
          params: {
            courseId: id,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
