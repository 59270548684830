/* eslint-disable @typescript-eslint/no-explicit-any */
export const initLocalSave = (data: any, name: string) => {
  const url = window.URL.createObjectURL(
    new Blob([JSON.stringify(data)], {
      type: 'application/octet-stream',
    }),
  );
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = `${name}.json`;
  a.click();
  window.URL.revokeObjectURL(url);
};
